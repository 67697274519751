import React, { useEffect, useState, useRef } from "react"
import { parsedDate } from "utils/dateTimeHelper"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { isPast } from "date-fns"
import classNames from "classnames"

import NavLink from "components/NavLink"

const EditTicket = observer(({ history }) => {
  const { userStore, ownTicketStore } = useStore()
  const { circleProfiles } = userStore

  const {
    newTicket,
    setTitle,
    setDescription,
    setDeadline,
    setTicketCircle,
    setTicketSkills,
    createTicket,
    updateTicket,
    resetTicketForm,
  } = ownTicketStore

  const dateInputRef = useRef(null)
  const timeInputRef = useRef(null)
  const ticketFormRef = useRef(null)

  // const [isNewTicket, setIsNewTicket] = useState(true)
  const [isDeadlineInTheFuture, setIsDeadlineInTheFuture] = useState(true)

  // Is member of exactly one circle?
  // Set that circle as default when adding new ticket.
  useEffect(() => {
    if (Object.keys(circleProfiles).length === 1) {
      const defaultCircleKeyID = parseInt(Object.keys(circleProfiles)[0])
      setTicketCircle(defaultCircleKeyID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circleProfiles])

  useEffect(() => {
    if (newTicket.id || newTicket.deadline) {
      // setIsNewTicket(false)
      dateInputRef.current.value = parsedDate(
        newTicket.deadline
      ).inputFields.date
      timeInputRef.current.value = parsedDate(
        newTicket.deadline
      ).inputFields.time
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmitForm = async () => {
    if (validateTicketForm()) {
      let ticket = {
        title: newTicket.title,
        description: newTicket.description,
        deadline: newTicket.deadline,
        skills: newTicket.skills,
        circleID: newTicket.circleID,
      }

      if (!newTicket.id) {
        await createTicket(ticket)
      } else {
        ticket = { ...ticket, id: newTicket.id }
        await updateTicket(ticket)
      }

      resetTicketForm()

      history.push({
        pathname: "/need_help/opened",
        state: {
          navDirection: "forward",
          animAxis: "horizontal",
          animContainer: "body",
        },
      })
    }
  }

  // Ad hoc validation functionality for ticket
  const validateTicketForm = () => {
    const { title, description, skills, deadline, circleID } = newTicket
    return (
      isDeadlineInTheFuture &&
      title.length > 0 &&
      description.length > 0 &&
      skills.length > 0 &&
      deadline &&
      circleID
    )
  }

  // NOTE: Time zone is hard-coded :/
  // TODO: Find smallest timezone handling package or write a custom solution
  const handleDeadlineChange = event => {
    const date = dateInputRef.current.value // '2019-12-12'
    const time = timeInputRef.current.value // '12:12'
    if (date && time) {
      setDeadline(`${date}T${time}+0200`)
      setIsDeadlineInTheFuture(!isPast(newTicket.deadline))
    }
  }

  const removeSkill = id => {
    const remainingSkills = newTicket.skills.filter(skill => skill.id !== id)
    setTicketSkills([...remainingSkills])
  }

  const removePlaceholder = e => (e.target.placeholder = "")

  return (
    <div className="edit-ticket">
      <form
        id="edit-ticket-form"
        ref={ticketFormRef}
        onSubmit={handleSubmitForm}
      >
        <div className="uppercase-label-bold field-label">
          write a short title{" "}
          <span
            className={classNames({
              remaining: true,
              warning: 64 - newTicket.title.length < 1,
            })}
          >
            {64 - newTicket.title.length} char.
          </span>
        </div>
        <textarea
          className="input-field-title"
          type="text"
          value={newTicket.title}
          placeholder="Title"
          onChange={e => setTitle(e.target.value)}
          onFocus={removePlaceholder}
        />
        <div className="uppercase-label-bold field-label">
          write a short description of your problem{" "}
          <span
            className={classNames({
              remaining: true,
              warning: 128 - newTicket.description.length < 1,
            })}
          >
            {128 - newTicket.description.length} char.
          </span>
        </div>
        <textarea
          className="input-field-description"
          type="text"
          value={newTicket.description}
          placeholder="Description"
          onChange={e => setDescription(e.target.value)}
          onFocus={removePlaceholder}
        />
        <div className="uppercase-label-bold field-label">
          what kind of skills are you looking for?
        </div>
        <div className="tags-container">
          <NavLink
            pathname={"/need_help/add_skills"}
            navDirection={"forward"}
            animAxis={"vertical"}
            animContainer={"screen"}
          >
            <span className="tag-active">+ add skills</span>
          </NavLink>

          {newTicket.skills.map((skill, i) => (
            <span
              key={i}
              className="tag-inactive"
              onClick={() => removeSkill(skill.id)}
            >
              {skill.name}
            </span>
          ))}
        </div>
        <div
          className={classNames({
            deadline: true,
            "uppercase-label-bold": true,
            "field-label": true,
            error: !isDeadlineInTheFuture,
          })}
        >
          deadline
          {!isDeadlineInTheFuture && (
            <span className="error-msg">Please select a future date.</span>
          )}
        </div>
        <div className="deadline-inputs">
          <input
            type="date"
            className="input-field-date"
            name="ticket-deadline-date"
            ref={dateInputRef}
            onChange={handleDeadlineChange}
            min={parsedDate(Date.now()).inputFields.date}
            // min={"2019-12-31"}
            // max={"2020-01-01"}
          />
          <input
            type="time"
            className="input-field-time"
            name="ticket-deadline-time"
            ref={timeInputRef}
            onChange={handleDeadlineChange}
          />
        </div>
        <div className="uppercase-label-bold field-label">
          send to connections
        </div>
        <div className="circles">
          {Object.values(circleProfiles).map(profile => (
            <div
              className={classNames({
                "input-field-connection": true,
                active: profile.circle.id === newTicket.circleID,
              })}
              key={profile.circle.id}
              onClick={() => setTicketCircle(profile.circle.id)}
            >
              <div className="name">{profile.circle.name}</div>
              <div className="logo">
                <img src={profile.circle.logo} alt="" />
              </div>
            </div>
          ))}
        </div>
      </form>
      <div
        className={classNames({
          cta: true,
          "cta-primary": true,
          disabled: !validateTicketForm(),
        })}
        onClick={() => {
          validateTicketForm() && handleSubmitForm()
        }}
      >
        {newTicket.id ? "Resend" : "Post your ticket"}
      </div>
    </div>
  )
})

export default EditTicket
