import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { Route, Switch } from "react-router-dom"
import { TransitionGroup, Transition } from "react-transition-group"

import classNames from "classnames"

import BodyContainer from "components/BodyContainer"

import Opened from "./OpenedTickets"
import Solved from "./SolvedTickets"

import NavLink from "components/NavLink"

const OfferHelp = observer(props => {
  // const screenName = "Dashboard"

  const { routerStore, matchedTicketStore } = useStore()
  const { location, currentPath, lastParamFromURL } = routerStore
  const {
    openedMatchedTicketsCount,
    fetchMatchedTickets,
    fetchDeclineReasons,
  } = matchedTicketStore

  const [activeTab, setActiveTab] = useState("opened")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setActiveTab(lastParamFromURL), [currentPath])

  useEffect(() => {
    fetchMatchedTickets()
    fetchDeclineReasons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="offer-help container-offer-help">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            pathname={"/dashboard"}
            navDirection={"back"}
            animAxis={"vertical"}
            animContainer={"screen"}
          >
            close
          </NavLink>
        </div>
        <div className="navigation">
          <NavLink
            className={classNames({
              "link-opened": true,
              tab: true,
              active: activeTab === "opened" || activeTab === "offer_help",
            })}
            pathname={"/offer_help"}
            navDirection={"forward"}
            animAxis={"horizontal"}
            animContainer={"body"}
          >
            <span className="opacity-handle">Help a friend</span>
            {openedMatchedTicketsCount > 0 && (
              <div className="notification-open-ticket">
                <span>{openedMatchedTicketsCount + " "}</span>
              </div>
            )}
          </NavLink>
          <NavLink
            className={classNames({
              "link-solved": true,
              tab: true,
              active: activeTab === "solved",
            })}
            pathname={"/offer_help/solved"}
            navDirection={"forward"}
            animAxis={"horizontal"}
            animContainer={"body"}
          >
            <span className="opacity-handle">Solved</span>
          </NavLink>
        </div>
      </div>
      <TransitionGroup className="transition-group">
        <Transition
          key={location.key}
          appear={true}
          timeout={{
            appear: 0,
            enter: 0,
            exit: 600,
          }}
        >
          {state => (
            <Switch location={location}>
              <Route
                path={"/offer_help/solved"}
                render={props => (
                  <BodyContainer transitionState={state}>
                    <Solved {...props} />
                  </BodyContainer>
                )}
              />
              <Route
                path={"/offer_help"}
                render={props => (
                  <BodyContainer transitionState={state}>
                    <Opened {...props} />
                  </BodyContainer>
                )}
              />
            </Switch>
          )}
        </Transition>
      </TransitionGroup>
    </div>
  )
})

export default React.memo(OfferHelp)
