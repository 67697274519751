import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import NavLink from "components/NavLink"
import NavBar from "components/NavBar"

import bgOver600_1 from "static/images/onboarding/help/skila-bg_img-02-1-nad_600px_width-0_1.png"
import bg414x300_1 from "static/images/onboarding/help/skila-bg_img-02-1-pod_600px_width-0_1-@1x.png"
import bg414x300x2_1 from "static/images/onboarding/help/skila-bg_img-02-1-pod_600px_width-0_1-@2x.png"
import bg414x300x3_1 from "static/images/onboarding/help/skila-bg_img-02-1-pod_600px_width-0_1-@3x.png"

import bgOver600_2 from "static/images/onboarding/help/skila-bg_img-02-2-nad_600px_width-0_1.png"
import bg414x300_2 from "static/images/onboarding/help/skila-bg_img-02-2-pod_600px_width-0_1-@1x.png"
import bg414x300x2_2 from "static/images/onboarding/help/skila-bg_img-02-2-pod_600px_width-0_1-@2x.png"
import bg414x300x3_2 from "static/images/onboarding/help/skila-bg_img-02-2-pod_600px_width-0_1-@3x.png"

import bgOver600_3 from "static/images/onboarding/help/skila-bg_img-02-3-nad_600px_width-0_1.png"
import bg414x300_3 from "static/images/onboarding/help/skila-bg_img-02-3-pod_600px_width-0_1-@1x.png"
import bg414x300x2_3 from "static/images/onboarding/help/skila-bg_img-02-3-pod_600px_width-0_1-@2x.png"
import bg414x300x3_3 from "static/images/onboarding/help/skila-bg_img-02-3-pod_600px_width-0_1-@3x.png"

import bgOver600_4 from "static/images/onboarding/help/skila-bg_img-02-4-nad_600px_width-0_1.png"
import bg414x300_4 from "static/images/onboarding/help/skila-bg_img-02-4-pod_600px_width-0_1-@1x.png"
import bg414x300x2_4 from "static/images/onboarding/help/skila-bg_img-02-4-pod_600px_width-0_1-@2x.png"
import bg414x300x3_4 from "static/images/onboarding/help/skila-bg_img-02-4-pod_600px_width-0_1-@3x.png"



const steps = {
  1: {
    header: "Find the right help.",
    content:
      "Need a proper help with your assignments? Sign in or register first and then proceed with creating your first ticket. With the ticket, you will be able to apply for the right assistance from the right expert within your organization.",
    adjPaths: {
      more: { pathname: "/onboard/help_more/1", animAxis: "vertical" },
      forward: { pathname: "/onboard/help/2", animAxis: "horizontal" },
    },
  },
  2: {
    header: "Offer your assistance.",
    content: "Help others in their professional endeavors by accepting their tickets. Sign in or Register and start receiving the tickets.",
    adjPaths: {
      back: { pathname: "/onboard/help/1", animAxis: "horizontal" },
      more: { pathname: "/onboard/help_more/2", animAxis: "vertical" },
      forward: { pathname: "/onboard/help/3", animAxis: "horizontal" },
    },
  },
  3: {
    header: "Build your profile.",
    content:
      "By sharing your expertise and increasing your competence value you will automatically receive peer-reviewed skills and badges. The more you share, the more you shine!",
    adjPaths: {
      back: { pathname: "/onboard/help/2", animAxis: "horizontal" },
      more: { pathname: "/onboard/help_more/3", animAxis: "vertical" },
      forward: { pathname: "/onboard/help/4", animAxis: "horizontal" },
    },
  },
  4: {
    header: "",
    content: "You're all set!",
    adjPaths: {
      back: { pathname: "/onboard/help/3", animAxis: "horizontal" },
      more: { pathname: "/onboard/help_more/4", animAxis: "vertical" },
      forward: { pathname: "/onboard/add_basic_info", animAxis: "horizontal" },
    },
  },
}

const bgImages = {
  1:{
    nonRetina: {
      600: bgOver600_1,
      414: bg414x300_1,
      320: bg414x300_1,
    },
    retinaX2: {
      600: bgOver600_1,
      414: bg414x300x2_1,
      320: bg414x300x2_1,
    },
    retinaX3: {
      600: bgOver600_1,
      414: bg414x300x3_1,
      320: bg414x300x3_1,
    }
  },
  2:{
    nonRetina: {
      600: bgOver600_2,
      414: bg414x300_2,
      320: bg414x300_2,
    },
    retinaX2: {
      600: bgOver600_2,
      414: bg414x300x2_2,
      320: bg414x300x2_2,
    },
    retinaX3: {
      600: bgOver600_2,
      414: bg414x300x3_2,
      320: bg414x300x3_2,
    }
  },
  3:{
    nonRetina: {
      600: bgOver600_3,
      414: bg414x300_3,
      320: bg414x300_3,
    },
    retinaX2: {
      600: bgOver600_3,
      414: bg414x300x2_3,
      320: bg414x300x2_3,
    },
    retinaX3: {
      600: bgOver600_3,
      414: bg414x300x3_3,
      320: bg414x300x3_3,
    }
  },
  4:{
    nonRetina: {
      600: bgOver600_4,
      414: bg414x300_4,
      320: bg414x300_4,
    },
    retinaX2: {
      600: bgOver600_4,
      414: bg414x300x2_4,
      320: bg414x300x2_4,
    },
    retinaX3: {
      600: bgOver600_4,
      414: bg414x300x3_4,
      320: bg414x300x3_4,
    }
  }
}

const Help = observer(props => {
  // const screenName = "Help"
  const { userStore } = useStore()
  const { retina, screenWidth } = userStore

  const stepKey = Number(props.match.params.step)
  const step = steps[stepKey]

  const [bgImage, setBgImage] = useState(null)

  useEffect(() => {
    let width = 320
    let dpi = "retinaX2"
    if (screenWidth > 320) width = 414
    if (screenWidth > 600) width = 600
    if (!retina) dpi = "nonRetina"
    setBgImage(bgImages[stepKey][dpi][width])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth, retina])

  return (
    <div className="help container-onboard">
      <div className="header">
        {stepKey !== 4 && (
          <div className="nav-link-intro link-text-two">
            <NavLink
              navDirection={"forward"}
              pathname={"/onboard/add_basic_info"}
              animAxis={"horizontal"}
              animContainer={"screen"}
            >
              close
            </NavLink>
          </div>
        )}
        <img src={bgImage} className="intro-bg-image" alt="" />
        <NavBar back={step.adjPaths.back} forward={step.adjPaths.forward} />
      </div>
      <div className={`body body-help step-${stepKey}`}>
        <div className="heading heading-two">{step.header}</div>
        <div className="paragraph paragraph-two">{step.content}</div>
        {parseInt(stepKey) === 4 && (
          <div className="cta-primary">
            <NavLink
              navDirection={"forward"}
              pathname={step.adjPaths.forward.pathname}
              animAxis={step.adjPaths.forward.animAxis}
            >
              Start
            </NavLink>
          </div>
        )}
      </div>
    </div>
  )
})

export default React.memo(Help)
