import React from "react"

const ThankYou = ({ history }) => {
  const handleRedirect = () => {
    history.push({
      pathname: "/dashboard",
      state: {
        navDirection: "forward",
        animAxis: "horizontal",
        animContainer: "screen",
      },
    })
  }

  return (
    <div className="thank-you" onClick={handleRedirect}>
      <div className="body">
        <div className="heading heading-four">
          Your feedback means a lot. <br /> Thank you.
        </div>
      </div>
    </div>
  )
}

export default React.memo(ThankYou)
