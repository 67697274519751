import React from "react"

import NavLink from "components/NavLink"

const Final = props => {
  // const screenName = "Final"

  return (
    <div className="final container-onboard">
      <div className="body">
        <div className="heading heading-four">You're ready to start!</div>
        <div className="cta-primary-light">
          <NavLink
            pathname={"/dashboard/"}
            navDirection={"forward"}
            animAxis={"vertical"}
            animContainer={"screen"}
          >
            Take me to my dashboard.
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Final)
