import { useEffect } from "react"
import { withRouter } from "react-router-dom"

const RouterStoreConnector = ({
  store,
  location,
  match,
  history,
  children,
}) => {
  useEffect(() => {
    store.setRoute(location, match, history)
  }, [history, location, match, store])

  return children
}

export default withRouter(RouterStoreConnector)
