import React, { useState, useRef } from "react"
import { observer } from "mobx-react-lite"
import { debounce } from "lodash"

import { searchSkills, fetchRelatedSkills } from "data/api"

import { useStore } from "data/store/RootStore"

import classNames from "classnames"

import NavLink from "components/NavLink"

const AddSkills = observer(({ history }) => {
  // const screenName = "AddSkills"

  const { ownTicketStore, userStore } = useStore()

  const { setTicketSkills, newTicket } = ownTicketStore
  const { skills } = userStore

  const [matchingSkills, setMatchingSkills] = useState([])
  const [recommendedSkills, setRecommendedSkills] = useState([])

  const [selectedSkills, setSelectedSkills] = useState([])

  const scrollableContainerRef = useRef(null)
  const searchInputRef = useRef(null)

  const handleSubmitForm = () => {
    setTicketSkills([...selectedSkills, ...newTicket.skills])
    history.push({
      pathname: "/need_help",
      state: {
        navDirection: "back",
        animAxis: "vertical",
        animContainer: "screen",
      },
    })
  }

  const handleSkillSearchUpdate = debounce(async searchString => {
    if (searchString.length >= 3) {
      try {
        const res = await searchSkills(searchString)
        setMatchingSkills(res.data)
      } catch (error) {
        console.error(error)
      }
    } else {
      setMatchingSkills([])
    }
  }, 300)

  // header shrinks when user scrolls (down or in general)?
  const handleScroll = e => {
    // console.log(e)
  }

  const addSkill = async id => {
    const skill = [...matchingSkills, ...recommendedSkills].filter(
      skill => skill.id === id
    )[0]
    // const scrollableContainer = scrollableContainerRef.current
    // setSelectedSkills([...selectedSkills, { ...skill, isVisible: true }])
    // setMatchingSkills(matchingSkills.filter(skill => skill.id !== id))
    // scrollableContainer.scrollTo(0, scrollableContainer.scrollHeight)

    const isDuplicate = selectedSkills.filter(skill => skill.id === id)[0]
    if (!isDuplicate && skill) {
      setSelectedSkills([...selectedSkills, { ...skill, isVisible: true }])
      setMatchingSkills(matchingSkills.filter(skill => skill.id !== id))
    }
    const relatedRes = await fetchRelatedSkills(id)
    setRecommendedSkills(relatedRes.data)
  }

  const removeSkill = id => {
    const remainingSkills = selectedSkills.filter(skill => skill.id !== id)
    setSelectedSkills([...remainingSkills])
  }

  return (
    <div className="add-skills-dashboard container-onboard">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            navDirection={"back"}
            pathname={"/need_help"}
            animAxis={"vertical"}
          >
            cancel
          </NavLink>
        </div>
        <div className="heading heading-four">Add skills</div>
        <div className="section-search-input">
          <input
            className="input-field"
            type="text"
            placeholder=""
            onChange={e => handleSkillSearchUpdate(e.target.value)}
            ref={searchInputRef}
            required
          />
        </div>
      </div>
      <div
        className="body scrollable"
        ref={scrollableContainerRef}
        onScroll={e => handleScroll(e)}
      >
        <div className="section-search-results">
          <div className="uppercase-label-bold field-label">
            search results:
          </div>
          <div className="tags-container">
            {matchingSkills.map((skill, i) => (
              <span
                key={i}
                className="tag-regular"
                onClick={() => addSkill(skill.id)}
              >
                {skill.name}
              </span>
            ))}
          </div>
        </div>
        {recommendedSkills.length !== 0 && (
          <div className="section-search-recommended">
            <form onSubmit={handleSubmitForm}>
              <div className="uppercase-label-bold field-label">
                related skills
              </div>
              <div className="tags-container">
                {recommendedSkills.map(skill => {
                  let isSelected = Object.values(selectedSkills).filter(
                    ({ id }) => id === skill.id
                  )[0]

                  let isOwn = skills.filter(({ id }) => id === skill.id)[0]
                  const unselectable = isSelected || isOwn

                  return (
                    <span
                      key={skill.id}
                      className={unselectable ? `tag-active` : `tag-regular`}
                      onClick={() =>
                        unselectable ? () => {} : addSkill(skill.id)
                      }
                    >
                      {skill.name}
                    </span>
                  )
                })}
              </div>
            </form>
          </div>
        )}
        <div className="section-search-selected">
          <form onSubmit={handleSubmitForm}>
            <div className="uppercase-label-bold field-label">
              your selected skills
            </div>
            <div className="tags-container">
              {selectedSkills.map((skill, i) => (
                <span
                  key={i}
                  className="tag-inactive"
                  onClick={() => removeSkill(skill.id)}
                >
                  <span>{skill.name}</span>
                </span>
              ))}
            </div>
          </form>
        </div>
        <div className="footer">
          <div
            className={classNames({
              cta: true,
              "cta-secondary-dark": true,
              disabled: !selectedSkills.length,
            })}
            onClick={selectedSkills.length ? handleSubmitForm : () => {}}
          >
            Add
          </div>
        </div>
      </div>
    </div>
  )
})

export default React.memo(AddSkills)
