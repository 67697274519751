import React, { useState, useRef } from "react"

import { useStore } from "data/store/RootStore"
import { observer } from "mobx-react-lite"

import classNames from "classnames"

import OwnTicket from "components/OwnTicket"
import RangeSlider from "components/RangeSlider"

const TicketRating = observer(({ ticket, history }) => {
  const { matchedTicketStore } = useStore()

  const { evaluateAssignedTicket } = matchedTicketStore

  const [sliderPosition, setSliderPosition] = useState(0)
  const sliderContainerRef = useRef()

  const [timeSpent, setTimeSpent] = useState(null)
  const [displayValue, setDisplayValue] = useState("slide")
  const [ticketDifficulty, setTicketDifficulty] = useState(null)

  // Input slider divisor (60 for minutes, 24 for hours and 7 for days)
  const [timeUnitDivisor, setTimeUnitDivisor] = useState(60)

  const isFormValid = () => timeSpent && ticketDifficulty

  const handleSubmitForm = async () => {
    if (!isFormValid()) return

    let difficultyString = "junior"
    if (ticketDifficulty === 2) difficultyString = "solvable without me"
    if (ticketDifficulty === 3) difficultyString = "senior"

    const reqData = [ticket.id, timeSpent, difficultyString]
    await evaluateAssignedTicket(...reqData)

    history.push({
      pathname: "/dashboard",
      state: {
        navDirection: "forward",
        animAxis: "horizontal",
        animContainer: "screen",
      },
    })
  }

  const handleSliderEvent = sliderPosition => {
    if (!sliderPosition) {
      setDisplayValue("slide")
      setSliderPosition(0)
      setTimeSpent(null)
      return
    }

    setSliderPosition(sliderPosition)
    const value = Math.round((timeUnitDivisor / 100) * sliderPosition)

    let minutesSpent = value
    let timeUnits = "min"

    if (timeUnitDivisor === 24) {
      minutesSpent = value * 60
      timeUnits = "h"
    }
    if (timeUnitDivisor === 7) {
      minutesSpent = value * 480
      timeUnits = "days"
    }

    setTimeSpent(minutesSpent)
    setDisplayValue(isNaN(value) ? "slide" : `${value} ${timeUnits}`)
  }

  return (
    <div className="ticket-rating scrollable">
      <div className="ticket-wrapper">
        <OwnTicket ticket={ticket} history={history} forEvaluation={true} />
      </div>
      <div className="uppercase-label-bold field-label">
        how much time did you spend helping your friend?
      </div>
      <div className="time-spent-new">
        <div className="units">
          <div
            className={classNames({ active: timeUnitDivisor === 60 })}
            onClick={() => {
              setTimeUnitDivisor(60)
              handleSliderEvent(0)
            }}
          >
            minute
          </div>
          <div
            className={classNames({ active: timeUnitDivisor === 24 })}
            onClick={() => {
              setTimeUnitDivisor(24)
              handleSliderEvent(0)
            }}
          >
            hour
          </div>
          <div
            className={classNames({ active: timeUnitDivisor === 7 })}
            onClick={() => {
              setTimeUnitDivisor(7)
              handleSliderEvent(0)
            }}
          >
            day
          </div>
        </div>
        <div ref={sliderContainerRef}>
          <RangeSlider
            axis="x"
            x={sliderPosition}
            displayValue={displayValue}
            onChange={({ x }) => handleSliderEvent(x)}
          />
        </div>
      </div>
      <div className="uppercase-label-bold field-label">
        please define the level of task difficulty:
      </div>
      <div className="ticket-difficulty">
        <div
          onClick={() => setTicketDifficulty(1)}
          className={classNames({ active: ticketDifficulty === 1 })}
        >
          junior
        </div>
        <div
          onClick={() => setTicketDifficulty(2)}
          className={classNames({ active: ticketDifficulty === 2 })}
        >
          solvable without my help
        </div>
        <div
          onClick={() => setTicketDifficulty(3)}
          className={classNames({ active: ticketDifficulty === 3 })}
        >
          senior
        </div>
      </div>
      <div className="footer">
        <div
          className={classNames({
            cta: true,
            "cta-secondary-dark": true,
            disabled: !isFormValid(),
          })}
          onClick={handleSubmitForm}
        >
          Done
        </div>
      </div>
    </div>
  )
})

export default React.memo(TicketRating)
