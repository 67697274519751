import React, { useState, useRef } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import validator from "validator"
import classNames from "classnames"

import NavLink from "components/NavLink"

const Login = observer(({ history }) => {
  // const screenName = "Login"

  const { authStore } = useStore()
  const {
    fetchAuthCode,
    phoneNumber,
    setPhoneNumber,
    circleInviteToken,
  } = authStore

  const [displayErrors, setDisplayErrors] = useState(false)
  const [authError, setAuthError] = useState(false)
  const submitEl = useRef(null)

  // onFocus onBlur
  const removePlaceholder = e => (e.target.placeholder = "")

  const validatePhoneNumber = () =>
    validator.isMobilePhone(`+${phoneNumber}`, ["sl-SI"], {
      strictMode: true,
    })

  const handlePhoneInputChange = e => {
    const number = e.target.value
    setPhoneNumber(number)
    phoneNumber && number.length < phoneNumber.length && setDisplayErrors(false)
    validatePhoneNumber() && submitEl.current.focus()
  }

  const handleSubmitForm = async e => {
    e.preventDefault()

    setDisplayErrors(true)

    if (validatePhoneNumber()) {
      const getCodeResponse = await fetchAuthCode(
        phoneNumber,
        circleInviteToken
      )

      if (getCodeResponse.error) {
        setDisplayErrors(true)
        setAuthError(true)
        return
      }

      history.push({
        pathname: "/onboard/code_confirmation",
        state: {
          navDirection: "forward",
          animAxis: "horizontal",
          animContainer: "screen",
        },
      })
    }
  }

  return (
    <div className="registration container-onboard">
      <div className="header header-onboard-two">
        <div className="heading heading-three">Sign in</div>
        <div className="paragraph paragraph-two">
          After entering your phone number, you will receive a passcode via text
          message.
        </div>
      </div>
      <div className="body">
        <form onSubmit={handleSubmitForm}>
          <div className="input-field-phone">
            <div className="label-aux">
              Example of correct format: +38640123456
            </div>

            <div className="input-container">
              <span
                className={classNames({
                  label: true,
                  error: displayErrors && (!validatePhoneNumber() || authError),
                })}
              >
                {(displayErrors &&
                  !validatePhoneNumber() &&
                  "user with this phone number doesn't exist") ||
                  "enter your phone number"}
              </span>

              <div className="input-wrapper">
                <div className="prefix">+</div>
                <input
                  type="number"
                  className={classNames({
                    error:
                      displayErrors && (!validatePhoneNumber() || authError),
                  })}
                  value={phoneNumber ? phoneNumber : ""}
                  placeholder="38640123456"
                  onChange={handlePhoneInputChange}
                  onFocus={removePlaceholder}
                  required
                />
              </div>
            </div>
          </div>
          <input
            className={classNames({
              "cta-primary": true,
              disabled: !validatePhoneNumber(),
            })}
            type="submit"
            value="Send me a passcode"
            ref={submitEl}
          />
        </form>
      </div>
      <div className="footer footer-onboard-two">
        <NavLink
          pathname={"/onboard/support"}
          navDirection={"forward"}
          animAxis={"vertical"}
        >
          <span className="link-text-one">Report problem</span>
        </NavLink>
      </div>
    </div>
  )
})

export default React.memo(Login)
