import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { orderBy } from "lodash"

import OwnTicket from "components/OwnTicket"

const SolvedTickets = observer(({ history }) => {
  const { ownTicketStore } = useStore()
  const { myTickets } = ownTicketStore

  const [activeTicketID, setActiveTicketID] = useState(null)
  const [orderedTickets, setOrderedTickets] = useState(myTickets)

  useEffect(() => {
    const solvedTickets = myTickets.filter(({ status }) => status === "done")
    setOrderedTickets(orderBy(solvedTickets, "deadline", "asc"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTickets])

  return (
    <div className="solved-tickets">
      <div className="my-tickets-list">
        {orderedTickets.map(ticket => (
          <span
            className="activator"
            key={ticket.id}
            onClick={() => setActiveTicketID(ticket.id)}
          >
            <OwnTicket
              ticket={ticket}
              history={history}
              isActive={ticket.id === activeTicketID}
            />
          </span>
        ))}
      </div>
    </div>
  )
})

export default SolvedTickets
