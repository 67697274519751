import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { Route, Switch } from "react-router-dom"
import { TransitionGroup, Transition } from "react-transition-group"

import classNames from "classnames"

import BodyContainer from "components/BodyContainer"
import TicketRating from "./TicketRating"

import NavLink from "components/NavLink"

const EvaluateTicket = observer(({ match, history }) => {
  // const screenName = "EvaluateUser"

  const { routerStore, matchedTicketStore } = useStore()
  const { location } = routerStore
  const { ticketsPendingEvaluation } = matchedTicketStore

  const [activeTicketID, setActiveTicketID] = useState(null)
  const [
    activeTicketSequentialNumber,
    setActiveTicketSequentialNumber,
  ] = useState(1)

  useEffect(() => {
    const ticketID = Number(match.params.ticket_id)
    ticketID && setActiveTicketID(ticketID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match])

  useEffect(() => {
    for (let x in ticketsPendingEvaluation) {
      const id = ticketsPendingEvaluation[x].ticket.id
      if (id === activeTicketID) {
        setActiveTicketSequentialNumber(parseInt(x) + 1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTicketID])

  const handleSlide = e => {
    const tab = e.currentTarget
    const tabsContainer = tab.parentNode
    const tabOffset = tab.offsetLeft
    const screenWidth = window.innerWidth
    const tabWidth = tab.offsetWidth
    const positionPadding = (screenWidth - tabWidth) / 2
    const scrollOptions = {
      left: tabOffset - positionPadding,
      top: 0,
      behavior: "smooth",
    }
    tabsContainer.scrollTo(scrollOptions)
  }

  return (
    <div className="evaluate-ticket">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            pathname={`/dashboard`}
            navDirection={"back"}
            animAxis={"vertical"}
            animContainer={"screen"}
          >
            leave
          </NavLink>
        </div>
        <div className="connections-list-header">
          {ticketsPendingEvaluation.map(({ ticket }, i) => (
            <div key={ticket.id} onClick={handleSlide}>
              <NavLink
                pathname={`/offer_help/evaluate/${ticket.id}`}
                navDirection={"forward"}
                animAxis={"horizontal"}
                animContainer={"body"}
              >
                <div
                  className={classNames({
                    connection: true,
                    active: ticket.id === Number(activeTicketID),
                  })}
                >
                  <div className="avatar-wrapper">
                    <div className="avatar-image-container">
                      <img
                        className="avatar-image"
                        src={ticket.owner.avatar}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="info-container">
                    <div className="name">{ticket.owner.name}</div>
                    <div className="circle">@ {ticket.owner.circle}</div>
                  </div>
                </div>
              </NavLink>
            </div>
          ))}
        </div>
        <div className="current-connection">
          {activeTicketSequentialNumber} / {ticketsPendingEvaluation.length}
        </div>
      </div>
      <TransitionGroup className="transition-group">
        <Transition
          key={match.params.ticket_id}
          appear={true}
          timeout={{
            appear: 0,
            enter: 0,
            exit: 600,
          }}
        >
          {state => (
            <Switch location={location}>
              {ticketsPendingEvaluation.map(({ ticket }) => (
                <Route
                  path={`/offer_help/evaluate/${ticket.id}`}
                  key={ticket.id}
                  render={() => (
                    <BodyContainer transitionState={state}>
                      <TicketRating history={history} ticket={ticket} />
                    </BodyContainer>
                  )}
                />
              ))}
            </Switch>
          )}
        </Transition>
      </TransitionGroup>
    </div>
  )
})

export default React.memo(EvaluateTicket)
