import React from "react"

import { Route, Switch, Router } from "react-router-dom"
import { createBrowserHistory } from "history"

import { Provider } from "mobx-react"
import { useStore } from "./data/store/RootStore"
import RouterStoreConnector from "./components/RouterStoreConnector"
// import DevTools from "mobx-react-devtools"

import "./styles/main.scss"

import { TransitionGroup, Transition } from "react-transition-group"

import Layout from "./components/Layout"
import ScreenContainer from "components/ScreenContainer"
import LoadingScreen from "components/LoadingScreen"
import UpdateNotification from "components/UpdateNotification"

import onboardRoutes from "./screens/onboard"
import dashboardRoutes from "./screens/dashboard"
import profileRoutes from "./screens/profile"
import needHelpRoutes from "./screens/needHelp"
import offerHelpRoutes from "./screens/offerHelp"

const AppContainer = props => {
  const history = createBrowserHistory()
  const rootStore = useStore()

  const appRoutes = [
    ...dashboardRoutes,
    ...onboardRoutes,
    ...profileRoutes,
    ...needHelpRoutes,
    ...offerHelpRoutes,
  ]

  return (
    <Provider store={rootStore}>
      <div className="App">
        {/* <DevTools /> */}
        <Router history={history}>
          <Route
            render={({ location }) => (
              <RouterStoreConnector store={rootStore.routerStore}>
                <Layout>
                  <TransitionGroup component={null}>
                    <Transition
                      key={rootStore.routerStore.location.key}
                      appear={true}
                      timeout={{
                        appear: 0,
                        enter: 0,
                        exit: 600,
                      }}
                    >
                      {state => (
                        <Switch location={location}>
                          {appRoutes.map(({ path, exact, Component }, i) => (
                            <Route
                              key={i}
                              path={path}
                              exact={exact}
                              render={props => (
                                <ScreenContainer
                                  location={location}
                                  transitionState={state}
                                  key={i}
                                >
                                  <Component {...props} />
                                </ScreenContainer>
                              )}
                            />
                          ))}
                        </Switch>
                      )}
                    </Transition>
                  </TransitionGroup>
                </Layout>
                <LoadingScreen />
                <UpdateNotification />
              </RouterStoreConnector>
            )}
          />
        </Router>
      </div>
    </Provider>
  )
}

export default React.memo(AppContainer)
