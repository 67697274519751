import React from "react"

import createTickets1 from "static/svgs/badges/APP_OfficeWorm_152x174_1.png"
import createTickets3 from "static/svgs/badges/APP_OfficeWorm_152x174_3.png"
import createTickets4 from "static/svgs/badges/APP_OfficeWorm_152x174_4.png"
import createTickets5 from "static/svgs/badges/APP_OfficeWorm_152x174_5.png"
import createTickets6 from "static/svgs/badges/APP_OfficeWorm_152x174_6.png"

import ticketSkills1 from "static/svgs/badges/APP_Scientist_152x174_1.png"
import ticketSkills3 from "static/svgs/badges/APP_Scientist_152x174_3.png"
import ticketSkills4 from "static/svgs/badges/APP_Scientist_152x174_4.png"
import ticketSkills5 from "static/svgs/badges/APP_Scientist_152x174_5.png"
import ticketSkills6 from "static/svgs/badges/APP_Scientist_152x174_6.png"

import profileComplete1 from "static/svgs/badges/APP_Ranger_152x174_1-01.png"
import profileComplete3 from "static/svgs/badges/APP_Ranger_152x174_1-03.png"
import profileComplete4 from "static/svgs/badges/APP_Ranger_152x174_1-04.png"
import profileComplete5 from "static/svgs/badges/APP_Ranger_152x174_1-05.png"
import profileComplete6 from "static/svgs/badges/APP_Ranger_152x174_1-06.png"

import acceptedTickets1 from "static/svgs/badges/APP_LifeSaver_152x174_1.png"
import acceptedTickets3 from "static/svgs/badges/APP_LifeSaver_152x174_3.png"
import acceptedTickets4 from "static/svgs/badges/APP_LifeSaver_152x174_4.png"
import acceptedTickets5 from "static/svgs/badges/APP_LifeSaver_152x174_5.png"
import acceptedTickets6 from "static/svgs/badges/APP_LifeSaver_152x174_6.png"

import confirmedSkills1 from "static/svgs/badges/APP_RisingStar_152x174_1.png"
import confirmedSkills3 from "static/svgs/badges/APP_RisingStar_152x174_3.png"
import confirmedSkills4 from "static/svgs/badges/APP_RisingStar_152x174_4.png"
import confirmedSkills5 from "static/svgs/badges/APP_RisingStar_152x174_5.png"
import confirmedSkills6 from "static/svgs/badges/APP_RisingStar_152x174_6.png"

import spendTime1 from "static/svgs/badges/APP_TimeMagician_152x174_1.png"
import spendTime3 from "static/svgs/badges/APP_TimeMagician_152x174_3.png"
import spendTime4 from "static/svgs/badges/APP_TimeMagician_152x174_4.png"
import spendTime5 from "static/svgs/badges/APP_TimeMagician_152x174_5.png"
import spendTime6 from "static/svgs/badges/APP_TimeMagician_152x174_6.png"

import createTicketsBefore1 from "static/svgs/badges/APP_EarlyRiser_152x174_1.png"
import createTicketsBefore3 from "static/svgs/badges/APP_EarlyRiser_152x174_3.png"
import createTicketsBefore4 from "static/svgs/badges/APP_EarlyRiser_152x174_4.png"
import createTicketsBefore5 from "static/svgs/badges/APP_EarlyRiser_152x174_5.png"
import createTicketsBefore6 from "static/svgs/badges/APP_EarlyRiser_152x174_6.png"

import reviews1 from "static/svgs/badges/APP_OfficeChamp_152x174_1.png"
import reviews3 from "static/svgs/badges/APP_OfficeChamp_152x174_3.png"
import reviews4 from "static/svgs/badges/APP_OfficeChamp_152x174_4.png"
import reviews5 from "static/svgs/badges/APP_OfficeChamp_152x174_5.png"
import reviews6 from "static/svgs/badges/APP_OfficeChamp_152x174_6.png"

import differentCollegues1 from "static/svgs/badges/APP_MotherTeresa_152x174_1.png"
import differentCollegues3 from "static/svgs/badges/APP_MotherTeresa_152x174_3.png"
import differentCollegues4 from "static/svgs/badges/APP_MotherTeresa_152x174_4.png"
import differentCollegues5 from "static/svgs/badges/APP_MotherTeresa_152x174_5.png"
import differentCollegues6 from "static/svgs/badges/APP_MotherTeresa_152x174_6.png"

import acceptedTicketsAfter1 from "static/svgs/badges/APP_NightWatch_152x174_1.png"
import acceptedTicketsAfter3 from "static/svgs/badges/APP_NightWatch_152x174_3.png"
import acceptedTicketsAfter4 from "static/svgs/badges/APP_NightWatch_152x174_4.png"
import acceptedTicketsAfter5 from "static/svgs/badges/APP_NightWatch_152x174_5.png"
import acceptedTicketsAfter6 from "static/svgs/badges/APP_NightWatch_152x174_6.png"

const badges = {
  create_tickets: [
    createTickets1,
    createTickets3,
    createTickets4,
    createTickets5,
    createTickets6,
  ],
  ticket_skills: [
    ticketSkills1,
    ticketSkills3,
    ticketSkills4,
    ticketSkills5,
    ticketSkills6,
  ],
  profile_complete: [
    profileComplete1,
    profileComplete3,
    profileComplete4,
    profileComplete5,
    profileComplete6,
  ],
  accepted_tickets: [
    acceptedTickets1,
    acceptedTickets3,
    acceptedTickets4,
    acceptedTickets5,
    acceptedTickets6,
  ],
  confirmed_skills: [
    confirmedSkills1,
    confirmedSkills3,
    confirmedSkills4,
    confirmedSkills5,
    confirmedSkills6,
  ],
  spend_time: [spendTime1, spendTime3, spendTime4, spendTime5, spendTime6],
  create_tickets_before: [
    createTicketsBefore1,
    createTicketsBefore3,
    createTicketsBefore4,
    createTicketsBefore5,
    createTicketsBefore6,
  ],
  reviews: [reviews1, reviews3, reviews4, reviews5, reviews6],
  different_collegues: [
    differentCollegues1,
    differentCollegues3,
    differentCollegues4,
    differentCollegues5,
    differentCollegues6,
  ],
  accepted_tickets_after: [
    acceptedTicketsAfter1,
    acceptedTicketsAfter3,
    acceptedTicketsAfter4,
    acceptedTicketsAfter5,
    acceptedTicketsAfter6,
  ],
}

const Badge = ({ id, level, width }) => {
  return (
    <img
      src={badges[id][level]}
      style={{ width: width }}
      alt=""
      id={id}
      className="badge-png"
    />
  )
}

export default React.memo(Badge)
