import React, { useState, useRef } from "react"

import { useStore } from "data/store/RootStore"
import { parsedDate } from "utils/dateTimeHelper"

import { useOnClickOutside } from "hooks/useOnClickOutside"
import classNames from "classnames"

import NavLink from "../components/NavLink"

import { ReactComponent as UsersIcon } from "static/svgs/icon-users.svg"
import { ReactComponent as FinishIcon } from "static/svgs/icon-finish.svg"
import { ReactComponent as PenIcon } from "static/svgs/icon-pen.svg"
import { ReactComponent as PauseIcon } from "static/svgs/icon-pause.svg"
import { ReactComponent as PlayIcon } from "static/svgs/icon-play.svg"
import { ReactComponent as RecycleIcon } from "static/svgs/icon-recycle.svg"

const Ticket = ({ history, ticket, forEvaluation }) => {
  const { ownTicketStore } = useStore()
  const {
    updateTicketStatus,
    setTitle,
    setDescription,
    setDeadline,
    setTicketSkills,
    setTicketCircle,
    setTicketID,
    setEvaluatingConnectionIDs,
  } = ownTicketStore

  const ticketRef = useRef()
  const [finishSection, setFinishSection] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  useOnClickOutside(ticketRef, () => setIsOpen(false))

  const toggle = () => setIsOpen(!isOpen)

  const onTicketEdit = ticket => {
    setTicketID(ticket.id)
    setTitle(ticket.title)
    setDescription(ticket.short_description)
    setDeadline(ticket.deadline)
    setTicketSkills(ticket.skills)
    setTicketCircle(ticket.circle_id)

    history.push({
      pathname: "/need_help/edit",
      state: {
        navDirection: "forward",
        animAxis: "vertical",
        animContainer: "body",
      },
    })
  }

  const onTicketDuplicate = ticket => {
    setTitle(ticket.title)
    setDescription(ticket.short_description)
    setDeadline(ticket.deadline)
    setTicketSkills(ticket.skills)
    setTicketCircle(ticket.circle_id)

    history.push({
      pathname: "/need_help/edit",
      state: {
        navDirection: "forward",
        animAxis: "vertical",
        animContainer: "body",
      },
    })
  }

  const onTicketEvaluate = () => {
    setEvaluatingConnectionIDs([])
    history.push({
      pathname: `/need_help/evaluate/${ticket.id}`,
      state: {
        navDirection: "forward",
        animAxis: "vertical",
        animContainer: "screen",
      },
    })
  }

  const wasAccepted = ticket =>
    ticket.connections.filter(({ status }) => status === "accepted")[0]

  const date = parsedDate(ticket.deadline)
  const overDeadline = date.ticket.isOverDeadline
  const acceptedConnectionsCount = ticket.connections.filter(
    ({ status }) => status === "accepted"
  ).length

  const deadlineColor = differenceInDays => {
    if (differenceInDays === 0) return "#FF6600"
    if (differenceInDays === 1) return "#FFA162"
    if (differenceInDays === 2) return "#FFB934"
    return "#B6C3D4"
  }

  const userIconColor = connectionCount => {
    if (connectionCount === 0) return "#FF6600"
    if (connectionCount === 1) return "#FFA162"
    if (connectionCount === 2) return "#FFB934"
    return "#B6C3D4"
  }

  return (
    <div
      className={classNames({
        "own-ticket": !forEvaluation,
        [ticket.status]: true,
        "evaluation-ticket": forEvaluation,
      })}
      key={ticket.id}
      ref={ticketRef}
    >
      <div className="ticket-status-icon">
        {ticket.status === "paused" && <PauseIcon />}
      </div>
      {/* #4859EE */}
      <div
        className={classNames({
          "left-border": true,
          "is-open": isOpen,
        })}
      />
      <div className="section-face" onClick={toggle}>
        <div className="main">
          <div className="title">{ticket.title}</div>
          <div className="skills tags-container-ticket">
            {ticket.skills.map(skill => (
              <div key={skill.id} className="tag-small-regular">
                {skill.name}
              </div>
            ))}
          </div>
        </div>
        <div className="side">
          <div className="deadline">
            <div
              className={classNames({ "day-with-word": true })}
              style={{ color: deadlineColor(date.ticket.differenceInDays) }}
            >
              {date.ticket.day()}
            </div>
            {overDeadline && date.ticket.differenceInMinutes < 2280 ? (
              <div className="time-over">
                <div>{date.ticket.timePassed()}</div>
                <span>over</span>
              </div>
            ) : (
              <>
                <div className="day-and-month">
                  {date.simpleFormat.dayOfMonth} {date.simpleFormat.month}
                </div>
                <div className="year">{date.simpleFormat.year}</div>
                <div className="time">{date.simpleFormat.time}</div>
              </>
            )}
          </div>

          <div className="ticket-connections">
            <UsersIcon
              width={12}
              height={8.26}
              className={classNames({
                "svg-icon": true,
                "icon-users": true,
              })}
              style={{ fill: userIconColor(acceptedConnectionsCount) }}
            />
            <div className="connection-count">{acceptedConnectionsCount}</div>
          </div>
        </div>
      </div>

      <div
        className={classNames({
          "section-more": true,
          visible: isOpen,
        })}
      >
        <div className="description">{ticket.short_description}</div>
        <div className="actions">
          {ticket.status === "done" && (
            <span
              className="duplicate"
              onClick={() => onTicketDuplicate(ticket)}
            >
              <RecycleIcon height={25} />
              <span className="text">duplicate ticket</span>
            </span>
          )}
          {ticket.status === "paused" && (
            <span
              className="resume"
              onClick={() => {
                updateTicketStatus(ticket.id, "open")
                setIsOpen(false)
              }}
            >
              <PlayIcon width={38} height={26} />
              <span className="text">resume</span>
            </span>
          )}
          {ticket.status === "open" && (
            <>
              <NavLink
                className={classNames({
                  connect: true,
                  disabled: !wasAccepted(ticket),
                })}
                pathname={`/need_help/connect/${ticket.id}`}
                navDirection={"forward"}
                animAxis={"vertical"}
                animContainer={"screen"}
                disabled={!wasAccepted(ticket)}
              >
                <UsersIcon width={38} height={26} />
                <span className="text">connect</span>
              </NavLink>
              <div
                className={classNames({
                  finish: true,
                  active: finishSection,
                })}
                onClick={() => setFinishSection(!finishSection)}
              >
                <FinishIcon />
                <span className="text">finish</span>
              </div>

              <div className="edit-pause">
                <div className="edit" onClick={() => onTicketEdit(ticket)}>
                  <PenIcon />
                </div>
                <div
                  className="pause"
                  onClick={() => {
                    updateTicketStatus(ticket.id, "paused")
                    setIsOpen(false)
                  }}
                >
                  <PauseIcon />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {finishSection && isOpen && (
        <div className="section-finish">
          <div className="actions">
            <div
              className={classNames({
                "evaluate-btn": true,
                disabled: !wasAccepted(ticket),
              })}
              disabled={!wasAccepted(ticket)}
              onClick={onTicketEvaluate}
            >
              <span>Evaluate help</span>
            </div>
            <span
              className="delete"
              onClick={() => updateTicketStatus(ticket.id, "deleted")}
            >
              Delete ticket
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(Ticket)
