import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { orderBy } from "lodash"

import classNames from "classnames"

import { ReactComponent as CheckIcon } from "static/svgs/icon-check.svg"

import OwnTicket from "components/OwnTicket"

const OpenedTickets = observer(({ history }) => {
  const { ownTicketStore } = useStore()

  const { myTickets } = ownTicketStore

  const [order, setOrder] = useState("deadline")
  const [paused, setPaused] = useState(false)

  const [orderedTickets, setOrderedTickets] = useState(myTickets)

  useEffect(() => {
    const activeTickets = myTickets.filter(
      ({ status }) => status !== "done" && status !== "deleted"
    )
    setOrderedTickets(
      orderBy(
        activeTickets,
        [paused ? null : ticket => ticket.status === "paused", order],
        ["asc", "asc"]
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTickets, order, paused])

  const acceptedConnectionCount = () => ticket =>
    ticket.connections.filter(({ status }) => status === "accepted").length

  return (
    <div className="opened-tickets">
      <div className="sort">
        <div
          className={classNames({
            "deadline-people": true,
            "sort-by-people": order !== "deadline",
            "sort-by-deadline": order === "deadline",
          })}
        >
          <div className="deadline-text" onClick={() => setOrder("deadline")}>
            deadline
          </div>
          <div className="toggle-btn-container">
            <div className="toggle-btn" />
          </div>
          <div
            className="people-text"
            onClick={() => setOrder(acceptedConnectionCount)}
          >
            people
          </div>
        </div>
        <div
          // className="pause"
          className={classNames({ pause: true, active: paused })}
          onClick={() => setPaused(!paused)}
        >
          <div>paused</div>
          <div
            className={classNames({ "icon-container": true, active: paused })}
          >
            <CheckIcon />
          </div>
        </div>
      </div>

      <div className="my-tickets-list">
        {orderedTickets.map(ticket => (
          <span className="activator" key={ticket.id}>
            <OwnTicket ticket={ticket} history={history} />
          </span>
        ))}
      </div>
    </div>
  )
})

export default OpenedTickets
