import React from "react"
import NavLink from "components/NavLink"

const steps = {
  1: {
    header: "Find help - More",
    content:
      "Got stuck? Need help with your assignments? Just create a ticket!",
    adjPaths: {
      back: { pathname: "/onboard/help/1" },
    },
  },
  2: {
    header: "Help 2 - More",
    content:
      "Do eiusmod tempor incididunt ut labore et dolore magna, eiusmod tempor incididunt ut labore et dolore magna.",
    adjPaths: {
      back: { pathname: "/onboard/help/2" },
    },
  },
  3: {
    header: "Help 3 - More",
    content:
      "Do eiusmod tempor incididunt ut labore et dolore magna, eiusmod tempor incididunt ut labore et dolore magna.",
    adjPaths: {
      back: { pathname: "/onboard/help/3" },
    },
  },
  4: {
    header: "Help 4 - More",
    content:
      "Do eiusmod tempor incididunt ut labore et dolore magna, eiusmod tempor incididunt ut labore et dolore magna.",
    adjPaths: {
      back: { pathname: "/onboard/help/4" },
    },
  },
}

const HelpMore = props => {
  // const screenName = "HelpMore"

  const stepKey = Number(props.match.params.step)
  const step = steps[stepKey]

  return (
    <div className="help-more container-onboard">
      <div className="header">
        <div className="link link-text-two">
          <NavLink
            navDirection={"back"}
            pathname={step.adjPaths.back.pathname}
            animAxis={"vertical"}
          >
            CLOSE
          </NavLink>
        </div>
      </div>
      <div className="body">
        <div className="heading heading-onboard-two">{step.header}</div>
        <div className="paragraph paragraph-onboard-two">{step.content}</div>
      </div>
    </div>
  )
}

export default React.memo(HelpMore)
