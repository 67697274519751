import React from "react"

import NavLink from "../components/NavLink"

import { ReactComponent as ArrowLeft } from "static/svgs/icon-arrow-left.svg"
import { ReactComponent as ArrowRight } from "static/svgs/icon-arrow-right.svg"

const NavBar = ({ back, forward }) => {
  return (
    <div className={"nav-bar"}>
      {back && (
        <NavLink
          className={"back"}
          navDirection={"back"}
          pathname={back.pathname}
          animAxis={back.animAxis}
          animContainer={back.animContainer}
        >
          <ArrowLeft />
        </NavLink>
      )}

      {forward && (
        <NavLink
          className={"forward"}
          navDirection={"forward"}
          pathname={forward.pathname}
          animAxis={forward.animAxis}
          animContainer={forward.animContainer}
        >
          <ArrowRight />
        </NavLink>
      )}
    </div>
  )
}

export default NavBar
