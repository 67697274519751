import Profile from "./Profile"
import Edit from "./EditProfile"
import Badges from "./Badges"
import Report from "./Report"
// import ThankYou from "./ThankYou"
import ThankYou from "../needHelp/ThankYou"

const profileRoutes = [
  { Component: ThankYou, path: "/profile/thank_you" },
  { Component: Report, path: "/profile/report" },
  { Component: Edit, path: "/profile/edit" },
  { Component: Badges, path: "/profile/badges" },
  { Component: Profile, path: "/profile" },
]

export default profileRoutes
