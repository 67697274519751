import React from "react"
import { useStore } from "../data/store/RootStore"
import { observer } from "mobx-react-lite"

import classNames from "classnames"

const BodyContainer = observer(props => {
  const { transitionState: transition, children, className } = props
  const { routerStore } = useStore()
  const {
    navDirection: direction,
    animAxis: axis,
    animContainer: container,
  } = routerStore

  const transitionContainerClasses = classNames({
    "body-transition-container": true,
    [`${transition}-${direction}-${axis}`]: container === "body",
    [`${direction}-${axis}`]: container === "body",
    [`${className}`]: className,
  })

  return <div className={transitionContainerClasses}>{children}</div>
})

export default React.memo(BodyContainer)
