import React, { useState, useEffect } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import classNames from "classnames"

import { ReactComponent as HiddenIcon } from "static/svgs/icon-hidden.svg"
import { ReactComponent as VisibleIcon } from "static/svgs/icon-visible.svg"

const CircleSkills = observer(({ selectedCircle }) => {
  // const screenName = "CircleSkills"

  const { userStore } = useStore()
  const { circleProfiles, updateCircleSkills } = userStore

  const [circleSkills, setCircleSkills] = useState([])
  const [circle, setCircle] = useState({ name: "", logo: "" })

  useEffect(() => {
    setCircle(circleProfiles[selectedCircle].circle)
    setCircleSkills(circleProfiles[selectedCircle].skills)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCircle])

  const toggleCircleSkillVisibility = id => {
    const newSkillState = circleSkills.map(skill => ({
      ...skill,
      is_visible: skill.id === id ? !skill.is_visible : skill.is_visible,
    }))
    setCircleSkills(newSkillState)
    updateCircleSkills(newSkillState, selectedCircle)
  }

  return (
    <div className="my-skills">
      <div className="uppercase-label-bold">my skills @{circle.name}</div>
      <div className="tags-list">
        {circleSkills.map(skill => (
          <div
            key={skill.id}
            className="tags-list-row"
            onClick={() => toggleCircleSkillVisibility(skill.id)}
          >
            <div className="icon-container">
              {skill.is_visible ? (
                <VisibleIcon className="icon icon-visible" />
              ) : (
                <HiddenIcon className="icon icon-hidden" />
              )}
            </div>
            <span
              className={classNames({
                "tag-regular": skill.is_confirmed,
                "tag-unconfirmed": !skill.is_confirmed,
                hidden: !skill.is_visible,
              })}
            >
              <span>{skill.name}</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  )
})

export default React.memo(CircleSkills)
