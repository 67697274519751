import React, { useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { createIssueReport } from "data/api"

import NavLink from "components/NavLink"

const Report = observer(props => {
  const { routerStore } = useStore()
  const { prevPath } = routerStore

  const [issueText, setIssueText] = useState(false)

  const handleSubmitForm = async e => {
    e.preventDefault()
    await createIssueReport(issueText)
    props.history.push({
      pathname: "/profile/thank_you",
      state: {
        navDirection: "forward",
        animAxis: "horizontal",
        animContainer: "screen",
      },
    })
  }

  return (
    <div className="report container-onboard">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            pathname={prevPath}
            navDirection={"back"}
            animAxis={"horizontal"}
          >
            back
          </NavLink>
        </div>
        <div className="heading heading-four">Something went wrong?</div>
        <div className="paragraph paragraph-four">
          Report issue and help us get better.
        </div>
      </div>
      <div className="body">
        <textarea
          className="input-field-issue"
          type="text"
          placeholder="Description"
          onFocus={e => (e.target.placeholder = "")}
          onChange={e => setIssueText(e.target.value)}
        />
        <input
          className="cta cta-secondary-light"
          type="submit"
          value="Send"
          onClick={handleSubmitForm}
        />
      </div>
    </div>
  )
})

export default React.memo(Report)
