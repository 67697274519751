import { observable, decorate, action, computed, autorun } from "mobx"
import localStorage from "mobx-localstorage"
import jwtDecode from "jwt-decode"

class RouterStore {
  location = {}
  match = {}
  history = {}
  isUpdatePending = null

  currentPath = {}
  prevPath = {}

  // React router and transition group skews component mounting
  // this flags tells if there was an actual change in route
  newRoute = false

  navDirection = {}
  animAxis = {}
  animContainer = {}

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  init = () => {
    this.authStore = this.rootStore.authStore
  }

  /* 2. use routerStore autorun on changes to 'update_pending' */
  localStorageDispose = autorun(() => {
    this.isUpdatePending = localStorage.getItem("update_pending")
  })

  /* Is used on close update notification modal action */
  setIsUpdatePending = isUpdatePending => {
    this.isUpdatePending = isUpdatePending
    if (isUpdatePending) localStorage.setItem("update_pending", true)
    else localStorage.removeItem("update_pending")
  }

  modifyScreenTransitionUniqueKey = (
    prevKey,
    newKey,
    prevPathname,
    newPathname
  ) =>
    (prevPathname && newPathname.includes("/offer_help/evaluate/")) ||
    (prevPathname && newPathname.match(/evaluate\/.+\/connections/gm))
      ? prevKey
      : newKey

  setRoute = async (location, match, history) => {
    // possible scenarios:
    // first check if public or private route
    // 1. user has no token
    //    - redirect to login screen
    // 2. user has expired token (how do i know that on client side?)
    //    - POST `jwt_refresh` to /login/refresh and get a new token
    // NOTE: make sure i'm not checking for jwt on public routes!!

    // Set previous path for easier reference when using animated transitions
    this.prevPath = this.currentPath
    this.currentPath = location.pathname

    const currentPath = location.pathname
    const publicLocations = ["onboard", "invitation"]
    // includes 'onboard' || includes 'invitation' || isEqualTo '/'
    const isPublicPath =
      publicLocations.some(publicLocation =>
        currentPath.includes(publicLocation)
      ) || currentPath === "/"

    const accessJWT = localStorage.getItem("jwt_access")
    // const refreshJWT = localStorage.getItem("jwt_refresh")

    // Is auth'ed path
    if (!isPublicPath) {
      // No token
      if (!accessJWT || accessJWT === "undefined") {
        history.push({
          pathname: "/onboard",
          state: {
            navDirection: "forward",
            animAxis: "vertical",
            animContainer: "screen",
          },
        })
      } else {
        // Token present
        const decodedAccessJWT = jwtDecode(accessJWT)
        // const decodedRefreshJWT = jwtDecode(refreshJWT)

        const currentTime = new Date().getTime() / 1000
        const isJwtExpired = currentTime > decodedAccessJWT.exp

        // const isRefreshJwtExpired = currentTime > decodedRefreshJWT.exp

        if (isJwtExpired) {
          await this.timeout(100)
          await this.authStore.refreshExpiredJwt()

          history.push({
            pathname: "/",
            state: {
              navDirection: "forward",
              animAxis: "vertical",
              animContainer: "screen",
            },
          })
        }
      }
    }

    // public path
    if (isPublicPath) {
      if (currentPath === "/") {
        if (accessJWT && accessJWT !== "undefined") {
          const decodedAccessJWT = jwtDecode(accessJWT)
          const currentTime = new Date().getTime() / 1000
          const isJwtExpired = currentTime > decodedAccessJWT.exp
          if (isJwtExpired) {
            await this.timeout(100)
            await this.authStore.refreshExpiredJwt()
            history.push({
              pathname: "/",
              state: {
                navDirection: "forward",
                animAxis: "vertical",
                animContainer: "screen",
              },
            })
          } else {
            history.push({
              pathname: "/dashboard",
              state: {
                navDirection: "forward",
                animAxis: "vertical",
                animContainer: "screen",
              },
            })
          }
        } else {
          history.push({
            pathname: "/onboard",
            state: {
              navDirection: "forward",
              animAxis: "horizontal",
              animContainer: "none",
            },
          })
        }
      }
    }

    // -------------

    this.newRoute = location.pathname !== this.location.pathname

    this.location = {
      ...location,
      key: this.modifyScreenTransitionUniqueKey(
        this.location.key,
        location.key,
        this.location.pathname,
        location.pathname
      ),
    }
    this.match = match
    this.history = history

    // Set transition animation parameters for Screen and Body containers
    this.navDirection = location.state ? location.state.navDirection : "forward"
    this.animAxis = location.state ? location.state.animAxis : "horizontal"
    this.animContainer = location.state
      ? location.state.animContainer
      : "screen"
  }

  get lastParamFromURL() {
    return typeof this.currentPath === "string"
      ? this.currentPath.split(`/`).pop()
      : ""
  }

  get isPrivateRoute() {
    const currentPath = this.location.pathname
    const publicLocations = ["onboard", "invitation"]
    // does path include 'onboard' || 'invitation'
    const isPublicPath = publicLocations.some(publicLocation =>
      currentPath.includes(publicLocation)
    )
    return !isPublicPath
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}

decorate(RouterStore, {
  location: observable,
  match: observable,
  history: observable,

  isUpdatePending: observable,

  navDirection: observable,
  animAxis: observable,
  animContainer: observable,

  currentPath: observable,
  prevPath: observable,

  newRoute: observable,

  setRoute: action,
  init: action,
  setIsUpdatePending: action,

  lastParamFromURL: computed,
  isPrivateRoute: computed,
})

export default RouterStore
