import { observable, decorate, action, flow } from "mobx"
import { debounce } from "lodash"
import * as api from "data/api"

export default class UserStore {
  /**
   * Observables 👁
   */
  firstName = ""
  lastName = ""
  avatarPreviewUrl = ""
  skills = []
  primaryCircleID = null
  circleProfiles = {}
  jobs = []
  badges = []
  onboardCircleID = null
  retina = true
  screenWidth = null

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  init = async () => {
    const token = localStorage.getItem("jwt_access")
    if (token) {
      try {
        const [skillsResponse, userResponse] = await Promise.all([
          api.fetchUserSkills(),
          api.fetchUser(),
        ])
        this.setUser(userResponse.data)
        this.skill(skillsResponse.data)
      } catch (err) {
        this.errors = err
      }
    }
    // Check if users device is older and does not support retina display
    const retinaQuery =
      "(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
    if (!matchMedia(retinaQuery).matches) this.retina = false
    // Set screen width
    this.setScreenWidth(window.innerWidth)
  }

  /**
   * Actions 🚀
   */
  setFirstName(firstName) {
    localStorage.setItem("first_name", firstName)
    this.firstName = firstName
  }

  // @action.bound
  setLastName(lastName) {
    localStorage.setItem("last_name", lastName)
    this.lastName = lastName
  }

  setAvatarPreviewUrl(url) {
    localStorage.setItem("avatar", url)
    this.avatarPreviewUrl = url
  }

  setPrimaryCircleID(id) {
    this.primaryCircleID = id
  }

  setUser(user) {
    this.firstName = user.first_name
    this.lastName = user.last_name
    this.avatarPreviewUrl = user.avatar
    this.primaryCircleID = user.primary_circle_id
    this.circleProfiles = user.info.reduce(
      (profiles, profile) => ({ ...profiles, [profile.circle.id]: profile }),
      {}
    )
    this.jobs = user.info.reduce(
      (allJobs, circleUser) => [
        ...allJobs,
        ...circleUser.jobs.map(job => ({ ...job, circle: circleUser.circle })),
      ],
      []
    )
    this.badges = user.badges
  }

  fetchUser = flow(function*() {
    try {
      this.rootStore.inProgress = true
      const fetchUserResponse = yield api.fetchUser()
      this.setUser(fetchUserResponse.data)
    } catch (err) {
      this.errors = err
    } finally {
      this.rootStore.inProgress = false
    }
  })

  updateUser = flow(function*(firstName, lastName) {
    try {
      this.rootStore.inProgress = true
      const updateUserResponse = yield api.updateUser(firstName, lastName)
      this.setUser(updateUserResponse.data)
    } catch (err) {
      this.errors = err
    } finally {
      this.rootStore.inProgress = false
    }
  })

  updatePrimaryCircleID = flow(function*(circleID) {
    try {
      this.rootStore.inProgress = true
      yield api.updateUsersPrimaryCircle(circleID)
      yield this.fetchUser()
    } catch (err) {
      this.errors = err
    } finally {
      this.rootStore.inProgress = false
    }
  })

  fetchUserSkills = flow(function*() {
    try {
      this.rootStore.inProgress = true
      const fetchUserSkillsResponse = yield api.fetchUserSkills()
      this.skills = fetchUserSkillsResponse.data
      return fetchUserSkillsResponse.data
    } catch (err) {
      this.errors = err
    } finally {
      this.rootStore.inProgress = false
    }
  })

  updateUserSkills = flow(function*(skills) {
    try {
      // this.rootStore.inProgress = true
      this.rootStore.inProgress = true

      const updateUserSkillsResponse = yield api.updateUserSkills(skills)
      this.skills = updateUserSkillsResponse.data
      this.fetchUser()
    } catch (err) {
      this.errors = err
    } finally {
      // this.rootStore.inProgress = false
      this.rootStore.inProgress = false
    }
  })

  deleteUserSkills = flow(function*(skills) {
    try {
      this.rootStore.inProgress = true
      const deleteUserSkillsResponse = yield api.deleteUserSkills(skills)
      this.skills = deleteUserSkillsResponse.data
      this.fetchUser()
    } catch (err) {
      this.errors = err
    } finally {
      this.rootStore.inProgress = false
    }
  })

  setOnboardCircleID = id => (this.onboardCircleID = id)

  fetchCircleProfile = flow(function*(circleID) {
    try {
      this.rootStore.inProgress = true
      return yield api.fetchCircleProfile(circleID)
    } catch (err) {
      this.errors = err
    } finally {
      this.rootStore.inProgress = false
    }
  })

  updateCircleProfile = flow(function*(circleID, profile) {
    try {
      this.rootStore.inProgress = true
      yield api.updateCircleProfile(circleID, profile)
      this.fetchUser()
    } catch (err) {
      this.errors = err
    } finally {
      this.rootStore.inProgress = false
    }
  })

  fetchCircleSkills = flow(function*(circleID) {
    try {
      this.rootStore.inProgress = true
      const fetchCircleSkillsResponse = yield api.fetchCircleSkills(circleID)
      return fetchCircleSkillsResponse.data
    } catch (err) {
      this.errors = err
    } finally {
      this.rootStore.inProgress = false
    }
  })

  fetchCircleSkillsDebounced = debounce(this.fetchCircleSkills, 300, {
    leading: true,
  })

  fetchAllCirclesSkills = flow(function*() {
    const circlesIDs = Object.values(this.circleProfiles).map(
      ({ circle }) => circle.id
    )
    let allCirclesSkills = {}
    try {
      this.rootStore.inProgress = true
      for (let circleID of circlesIDs) {
        const res = yield this.fetchCircleSkills(circleID)
        allCirclesSkills[circleID] = res
      }
    } catch (e) {
      console.error(e)
      return e
    } finally {
      this.rootStore.inProgress = false
      return allCirclesSkills
    }
  })

  updateCircleSkills = flow(function*(skills, circleID) {
    try {
      this.rootStore.inProgress = true
      const updateCircleSkillsResponse = yield api.updateCircleSkills(
        skills,
        circleID
      )
      this.circleProfiles[circleID].skills = updateCircleSkillsResponse.data
    } catch (err) {
      this.errors = err
    } finally {
      this.rootStore.inProgress = false
    }
  })

  updateCircleSkillsDebounced = debounce(this.updateCircleSkills, 300, {
    leading: true,
  })

  createCircleJobTitle = flow(function*(jobTitle, jobID, circleID) {
    try {
      this.rootStore.inProgress = true
      const createCircleJobTitleResponse = yield api.createCircleJobTitle(
        jobTitle,
        jobID,
        circleID
      )
      yield this.fetchUser()
      return createCircleJobTitleResponse.data
    } catch (err) {
      this.errors = err
    } finally {
      this.rootStore.inProgress = false
    }
  })

  updateCircleJobTitle = flow(function*(job, circleID) {
    try {
      this.rootStore.inProgress = true
      yield api.updateCircleJobTitle(job, circleID)
      yield this.fetchUser()
    } catch (err) {
      this.errors = err
    } finally {
      this.rootStore.inProgress = false
    }
  })

  setScreenWidth() {
    this.screenWidth = window.innerWidth
  }

  reset = () => {
    this.firstName = ""
    this.lastName = ""
    this.avatarPreviewUrl = ""
    this.skills = []
    this.primaryCircleID = null
    this.circleProfiles = {}
    this.jobs = []
    this.badges = []
    this.onboardCircleID = null
  }
}

decorate(UserStore, {
  firstName: observable,
  lastName: observable,
  avatarPreviewUrl: observable,
  skills: observable,
  primaryCircleID: observable,
  circleProfiles: observable,
  jobs: observable,
  badges: observable,
  onboardCircleID: observable,
  retina: observable,
  screenWidth: observable,

  setUser: action.bound,
  setFirstName: action.bound,
  setLastName: action.bound,
  setAvatarPreviewUrl: action.bound,
  setOnboardCircleID: action.bound,
  setPrimaryCircleID: action.bound,

  fetchUser: action.bound,
  updateUser: action.bound,
  updatePrimaryCircleID: action.bound,

  fetchUserSkills: action.bound,
  updateUserSkills: action.bound,
  deleteUserSkills: action.bound,

  fetchCircleProfile: action.bound,
  updateCircleProfile: action.bound,

  fetchCircleSkills: action.bound,
  fetchCircleSkillsDebounced: action.bound,
  fetchAllCirclesSkills: action.bound,
  updateCircleSkills: action.bound,

  createCircleJobTitle: action.bound,
  updateCircleJobTitle: action.bound,

  setScreenWidth: action.bound,
  reset: action.bound,
})
