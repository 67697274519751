import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import classNames from "classnames"

import NavLink from "components/NavLink"
// import helpNotificationIcon from "static/images/icon-help-notification.png"

const Skills = observer(({ toggleHeader }) => {
  // const screenName = "MySkills"

  const {
    routerStore,
    userStore,
    ownTicketStore,
    matchedTicketStore,
  } = useStore()

  const { history } = routerStore
  const { skills } = userStore
  const { openedOwnTicketsCount, resetTicketForm } = ownTicketStore
  const {
    ticketsPendingEvaluation,
    openedMatchedTicketsCount,
  } = matchedTicketStore

  const [newSkills, setNewSkills] = useState([])
  const [confirmedSkills, setConfirmedSkills] = useState([])
  const [unconfirmedSkills, setUnconfirmedSkills] = useState([])

  useEffect(() => {
    setNewSkills(skills.filter(({ is_new }) => is_new))
    setConfirmedSkills(skills.filter(({ is_confirmed }) => is_confirmed))
    setUnconfirmedSkills(skills.filter(({ is_confirmed }) => !is_confirmed))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skills])

  const handleNewTicketForm = () => {
    resetTicketForm()
    history.push({
      pathname: "/need_help",
      state: {
        navDirection: "forward",
        animAxis: "vertical",
        animContainer: "screen",
      },
    })
  }

  return (
    <>
      <div
        className="skills-dashboard scrollable"
        onScroll={e => toggleHeader(e)}
      >
        {newSkills.length > 0 && (
          <>
            <div className="uppercase-label-bold field-label">
              you have a new skill on your list.
            </div>
            <div className="skills-container new-skills">
              <div className="tags-container">
                {newSkills.map((skill, i) => (
                  <span key={i} className="tag-regular">
                    {skill.name}
                  </span>
                ))}
              </div>
            </div>
          </>
        )}

        {confirmedSkills.length > 0 && (
          <>
            <div className="uppercase-label-bold field-label">i rock @</div>
            <div className="skills-container confirmed-skills">
              <div className="tags-container">
                {confirmedSkills.map((skill, i) => (
                  <span key={i} className="tag-regular">
                    {skill.name}
                  </span>
                ))}
              </div>
            </div>
          </>
        )}
        <div className="uppercase-label-bold field-label">my super powers</div>
        <div className="skills-container favorite-skills">
          <div className="tags-container">
            {unconfirmedSkills.map((skill, i) => (
              <span key={i} className="tag-inactive">
                {skill.name}
              </span>
            ))}
          </div>
        </div>
        <NavLink
          pathname={"/dashboard/edit_skills"}
          navDirection={"forward"}
          animAxis={"vertical"}
          animContainer={"screen"}
          className="link-text-two edit-skills-link"
        >
          edit
          <br />
          skills
        </NavLink>
      </div>

      <div className="footer-skills">
        <div onClick={handleNewTicketForm} className="need-help-cta">
          <div className="top">{openedOwnTicketsCount} open</div>
          <div className="mid">
            <div className="value">
              FIND
              <br />
              AN EXPERT
            </div>
          </div>
          <div className="bot" />
        </div>

        <div
          className={classNames({
            "offer-help-cta": true,
            "pending-tickets": ticketsPendingEvaluation.length !== 0,
          })}
        >
          {ticketsPendingEvaluation.length !== 0 && (
            <div className="aux">
              <NavLink
                pathname={`/offer_help/evaluate/${
                  ticketsPendingEvaluation[0].ticket.id
                }`}
                navDirection={"forward"}
                animAxis={"vertical"}
              >
                <div className="notification-resolved">
                  <div className="count">{ticketsPendingEvaluation.length}</div>
                  newly resolved tickets
                </div>
              </NavLink>
            </div>
          )}

          <NavLink
            className="main"
            pathname={"/offer_help"}
            navDirection={"forward"}
            animAxis={"vertical"}
          >
            <div className="top">{openedMatchedTicketsCount} open</div>
            <div className="mid">
              <div className="value">
                BE
                <br />
                THE EXPERT
              </div>
            </div>
            <div className="bot" />
          </NavLink>
        </div>
      </div>
    </>
  )
})

export default React.memo(Skills)
