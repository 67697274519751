import React from "react"
import { useStore } from "data/store/RootStore"

import { ReactComponent as SkilaTitle } from "static/svgs/title-skila.svg"

const Splash = () => {
  // const screenName = "Splash"

  const { routerStore } = useStore()
  const { history } = routerStore

  const redirect = () => {
    history.push({
      pathname: "/onboard/intro/1",
      state: {
        navDirection: "forward",
        animAxis: "vertical",
        animContainer: "screen",
      },
    })
  }

  return (
    <div className="splash container-onboard">
      <div className="header">
        <div className="top-circle" />
      </div>
      <div className="body">
        <div className="heading heading-one">welcome to</div>
        <SkilaTitle className="logo-title" />
      </div>
      <div className="footer">
        <div className="cta-primary" onClick={redirect}>
          let's start
        </div>
      </div>
    </div>
  )
}

export default React.memo(Splash)
