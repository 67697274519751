import { useContext, createContext } from "react"
import { observable, decorate } from "mobx"

import RouterStore from "./RouterStore"
import AuthStore from "./AuthStore"
import UserStore from "./UserStore"
import OwnTicketStore from "./OwnTicketStore"
import MatchedTicketStore from "./MatchedTicketStore"

class RootStore {
  inProgress = false

  constructor() {
    this.authStore = new AuthStore(this)
    this.userStore = new UserStore(this)
    this.ownTicketStore = new OwnTicketStore(this)
    this.matchedTicketStore = new MatchedTicketStore(this)
    this.routerStore = new RouterStore(this)

    this.authStore.init().then(() => {
      this.routerStore.init()
      this.userStore.init()
      this.ownTicketStore.init()
      this.matchedTicketStore.init()
    })
  }

  resetStores = () => {
    this.authStore.reset()
    this.userStore.reset()
    this.ownTicketStore.reset()
    this.matchedTicketStore.reset()
  }
}

decorate(RootStore, {
  inProgress: observable,
})

const store = new RootStore()
const StoreContext = createContext(store)
export const useStore = () => useContext(StoreContext)

// Debugging store:
// window.store = store
