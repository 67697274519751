import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import classNames from "classnames"

import NavLink from "components/NavLink"

const Evaluate = observer(({ history, match }) => {
  // const screenName = "Evaluate"
  const ticketID = Number(match.params.ticket_id)

  const { ownTicketStore } = useStore()
  const {
    myTickets,
    evaluatingConnectionIDs,
    setEvaluatingConnectionIDs,
    setConnectionsSkillScore,
  } = ownTicketStore

  const [ticket, setTicket] = useState({ connections: [] })

  useEffect(() => {
    const activeTicket = myTickets.filter(({ id }) => id === ticketID)[0]
    if (activeTicket) {
      setTicket(activeTicket)
      let skills = activeTicket.skills.reduce(
        (skills, skill) => ({
          ...skills,
          [skill.id]: { ...skill, score: 0 },
        }),
        {}
      )
      let scores = activeTicket.connections
        .filter(connection => connection.status === "accepted")
        .reduce(
          (connections, connection) => ({
            ...connections,
            [connection.id]: { ...connection, skills: skills },
          }),
          {}
        )
      setConnectionsSkillScore(scores)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTickets])

  const handleEvaluationToggle = connectionID => {
    evaluatingConnectionIDs.includes(connectionID)
      ? setEvaluatingConnectionIDs(
          evaluatingConnectionIDs.filter(id => id !== connectionID)
        )
      : setEvaluatingConnectionIDs([...evaluatingConnectionIDs, connectionID])
  }

  const handleSubmitForm = () => {
    const defaultConnection = evaluatingConnectionIDs[0]
    history.push({
      pathname: `/need_help/evaluate/${ticketID}/connections/${defaultConnection}`,
      state: {
        navDirection: "forward",
        animAxis: "horizontal",
        animContainer: "screen",
      },
    })
  }

  return (
    <div className="evaluate">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            pathname={"/need_help/opened"}
            navDirection={"back"}
            animAxis={"horizontal"}
            animContainer={"screen"}
          >
            leave
          </NavLink>
        </div>
        <div className="heading heading-four">Evaluate help</div>
      </div>
      <div className="body">
        <div className="connections-list">
          <div className="uppercase-label-bold field-label">
            who helped you?
          </div>
          {ticket &&
            ticket.connections
              .filter(({ status }) => status === "accepted")
              .map(connection => (
                <div
                  className={classNames({
                    connection: true,
                    active: evaluatingConnectionIDs.includes(connection.id),
                  })}
                  key={connection.id}
                  onClick={() => {
                    handleEvaluationToggle(connection.id)
                  }}
                >
                  <div className="avatar-wrapper">
                    <div className="avatar-image-container">
                      <img
                        className="avatar-image"
                        src={connection.avatar}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="info-container">
                    <div className="name">{connection.name}</div>
                    <div className="circle">@ {connection.circle}</div>
                  </div>
                </div>
              ))}
        </div>
        <div className="footer">
          <div className="cta-primary" onClick={handleSubmitForm}>
            Evaluate
          </div>
        </div>
      </div>
    </div>
  )
})

export default React.memo(Evaluate)
