import React from "react"
import { format } from "date-fns"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import NavLink from "components/NavLink"

const JobTitles = observer(props => {
  // const screenName = "JobTitles"

  const { userStore } = useStore()
  const { jobs } = userStore

  const dateToYear = date => format(date, "YYYY")

  return (
    <div className="job-titles scrollable">
      <div className="current-job-titles">
        <div className="uppercase-label-bold field-label">
          current job titles
        </div>
        <div className="jobs">
          {jobs
            .filter(job => job.is_finished === false && job.is_visible === true)
            .map((job, i) => (
              <div className="job" key={i}>
                <div className="logo-container-small">
                  <img src={job.circle.logo} alt="" className="logo" />
                </div>
                <div className="tag-job-title">
                  <div className="title">{job.name}</div>
                  <div className="year">{dateToYear(job.date_created)}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="past-job-titles">
        <div className="uppercase-label-bold field-label">
          previous job titles
        </div>
        <div className="jobs">
          {jobs
            .filter(job => job.is_finished === true && job.is_visible === true)
            .map((job, i) => (
              <div className="job" key={i}>
                <div className="logo-container-small">
                  <img src={job.circle.logo} alt="" className="logo" />
                </div>
                <div className="tag-job-title finished">
                  <div className="title">{job.name}</div>
                  <div className="year">{dateToYear(job.date_created)}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <NavLink
        pathname={"/dashboard/edit_job_titles"}
        navDirection={"forward"}
        animAxis={"vertical"}
        animContainer={"screen"}
        className="link-text-two edit-skills-link"
      >
        edit
        <br />
        job titles
      </NavLink>
    </div>
  )
})

export default React.memo(JobTitles)
