import React, { useRef, useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import classNames from "classnames"

import NavLink from "components/NavLink"

const content = {
  default: {
    paragraph:
      "You will now receive a four digit confirmation code. It will expire quickly, so make sure you enter it asap.",
  },
  resend: {
    paragraph:
      "Your confirmation code was resent. You should be getting it anytime now.",
  },
}

const CodeConfirmation = observer(({ match, history }) => {
  // const screenName = "CodeConfirmation"

  const { authStore } = useStore()
  const {
    phoneNumber,
    circleInviteToken,
    fetchAuthCode,
    setAuthCode,
    authCode,
    tmpAuthCode,
    login,
  } = authStore

  const [inputValid, setInputValid] = useState(false)
  const [authError, setAuthError] = useState(false)

  // Did user navigate to default or resend version of confirmation screen?
  const resend = match.params.resend

  // Mock received SMS with confirmation pin
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "dev") {
      const smsMock = setTimeout(() => {
        alert(`Validation PIN: ${tmpAuthCode}`)
      }, 1800)
      return () => clearTimeout(smsMock)
    }
  }, [tmpAuthCode])

  // Resend UX flow? Try to fetch auth code again:
  useEffect(() => {
    const fetch = async () => {
      const getCodeResponse = await fetchAuthCode(
        phoneNumber,
        circleInviteToken
      )
      if (getCodeResponse) {
        if (getCodeResponse.error) {
          setAuthError(true)
          return
        }
      }
    }
    resend && fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resend])

  // Used for form input focus control
  const inputEl1 = useRef(null)
  const inputEl2 = useRef(null)
  const inputEl3 = useRef(null)
  const inputEl4 = useRef(null)
  const submitEl = useRef(null)

  const handleSubmitForm = async e => {
    e.preventDefault()
    try {
      // Grab all the inputs and update authStore/authCode field
      const inputElements = e.currentTarget.elements
      setAuthCode(
        Object.values(inputElements)
          .filter(input => input.type === "number")
          .map(input => input.value)
          .join("")
      )

      const loginRes = await login(phoneNumber, authCode, circleInviteToken)

      if (loginRes.errors || loginRes.error) {
        setAuthError(true)
        return
      }
      const userNameSet = loginRes.user.first_name.length > 0

      let nextPath = "/dashboard"

      if (!userNameSet) nextPath = "/onboard/confirmation_ok"
      if (userNameSet && circleInviteToken) nextPath = "/onboard/share_skills"
      if (userNameSet && !circleInviteToken) nextPath = "/dashboard"

      // Mrs. Debuggins, remove her
      // nextPath = "/onboard/add_basic_info"

      history.push({
        pathname: nextPath,
        state: {
          navDirection: "forward",
          animAxis: "horizontal",
          animContainer: "screen",
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onInput = (e, next) => {
    if (e.target.value.length > 0) {
      if (next) next.current.focus()
      else {
        submitEl.current.focus()
        setInputValid(true)
      }
    }
  }

  return (
    <div className="code-confirmation container-onboard">
      <div className="header header-two">
        <div className="heading heading-three">Confirmation code</div>
        <div className="paragraph paragraph-two">
          {resend ? content.resend.paragraph : content.default.paragraph}
        </div>
      </div>
      <div className="body">
        <form onSubmit={handleSubmitForm}>
          <div className="input-field-pin">
            <div className="error-field">
              {authError && (
                <div className="error-message">
                  the entered code is incorrect
                </div>
              )}
            </div>
            <div className="input-wrapper">
              <input
                ref={inputEl1}
                type="number"
                onChange={e => onInput(e, inputEl2)}
              />
              <input
                ref={inputEl2}
                type="number"
                onChange={e => onInput(e, inputEl3)}
              />
              <input
                ref={inputEl3}
                type="number"
                onChange={e => onInput(e, inputEl4)}
              />
              <input
                ref={inputEl4}
                type="number"
                onChange={e => onInput(e, null)}
              />
            </div>
          </div>
          <input
            className={classNames({
              "cta-primary": true,
              disabled: !inputValid,
            })}
            type="submit"
            value="Confirm"
            ref={submitEl}
          />
        </form>
      </div>
      {resend ? (
        <div className="footer footer-onboard-two">
          <div className="paragraph-three">
            You still haven't received passcode?
          </div>
          <NavLink
            pathname={"/onboard/support"}
            navDirection={"forward"}
            animAxis={"vertical"}
          >
            <span className="link-text-one">Report</span>
          </NavLink>
        </div>
      ) : (
        <div className="footer footer-onboard-two">
          <div className="paragraph-three">Haven't received the code yet?</div>
          <NavLink
            pathname={"/onboard/code_confirmation/resend"}
            navDirection={"forward"}
            animAxis={"vertical"}
          >
            <span className="link-text-one">Resend to +{phoneNumber}</span>
          </NavLink>
        </div>
      )}
    </div>
  )
})

export default React.memo(CodeConfirmation)
