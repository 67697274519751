import React from "react"
import { Link } from "react-router-dom"

const NavLink = props => {
  const {
    pathname,
    navDirection,
    animAxis,
    animContainer,
    children,
    disabled,
  } = props

  return (
    <Link
      ref={props.ref}
      className={`nav-link ${props.className || ""}`}
      onClick={e => disabled && e.preventDefault()}
      to={{
        pathname,
        state: {
          navDirection,
          animAxis,
          animContainer: animContainer ? animContainer : "screen",
        },
      }}
    >
      {children}
    </Link>
  )
}

export default NavLink
