import { observable, decorate, action, flow, computed } from "mobx"
import * as api from "data/api"

export default class OwnTicketStore {
  /**
   * Observables 👁
   */
  inProgress = false
  editingInProgress = false
  errors = []
  newTicket = {
    id: null,
    title: "",
    description: "",
    skills: [],
    deadline: null,
    circleID: null,
  }
  myTickets = []
  evaluatingConnectionIDs = []
  connectionsSkillScore = {}
  connectionsHelpRating = {}

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  init = async () => {
    const token = localStorage.getItem("jwt_access")
    if (token) {
      try {
        this.fetchOwnTickets()
      } catch (err) {
        this.errors = err
      }
    }
  }

  /**
   * Actions 🚀 NOTE: @action.bound
   */
  setTicketID = id => (this.newTicket.id = id)
  setTitle = title => {
    this.newTicket.title = title.length < 65 ? title : this.newTicket.title
  }
  setDescription = description => {
    this.newTicket.description =
      description.length < 129 ? description : this.newTicket.description
  }
  setDeadline = deadline => (this.newTicket.deadline = deadline)
  setTicketSkills = skills => (this.newTicket.skills = skills)
  setTicketCircle = circleID => (this.newTicket.circleID = circleID)

  setEditingInProgress = inProgress => (this.editingInProgress = inProgress)

  setEvaluatingConnectionIDs = userIDs =>
    (this.evaluatingConnectionIDs = userIDs)

  setConnectionsSkillScore = connections =>
    (this.connectionsSkillScore = connections)

  setConnectionsHelpRating = connections =>
    (this.connectionsHelpRating = connections)

  createTicket = flow(function*(ticket) {
    try {
      this.inProgress = true
      const createTicketResponse = yield api.createTicket(
        ticket.title,
        ticket.description,
        ticket.deadline,
        ticket.skills,
        ticket.circleID
      )
      this.myTickets = createTicketResponse.data
    } catch (err) {
      this.errors = err
    } finally {
      this.inProgress = false
    }
  })

  updateTicket = flow(function*(ticket) {
    try {
      this.inProgress = true
      const updateTicketResponse = yield api.updateTicket(
        ticket.id,
        ticket.title,
        ticket.description,
        ticket.deadline,
        ticket.skills,
        ticket.circleID
      )
      this.myTickets = updateTicketResponse.data
      // TODO: check response and set to new if legit
      // else: update whole store
    } catch (err) {
      this.errors = err
    } finally {
      this.inProgress = false
    }
  })

  fetchOwnTickets = flow(function*() {
    try {
      this.inProgress = true
      const fetchTicketsResponse = yield api.fetchOwnTickets()
      this.myTickets = fetchTicketsResponse.data
    } catch (err) {
      this.errors = err
    } finally {
      this.inProgress = false
    }
  })

  updateTicketStatus = flow(function*(ticketID, status) {
    try {
      this.inProgress = true
      const updateTicketStatusResponse = yield api.updateTicketStatus(
        ticketID,
        status
      )
      this.myTickets = updateTicketStatusResponse.data
    } catch (err) {
      this.errors = err
    } finally {
      this.inProgress = false
    }
  })

  evaluateConnectionSkills = flow(function*(
    ticketID,
    circleUserId,
    skillScores
  ) {
    try {
      this.inProgress = true
      yield api.postEvaluationConnectionSkills(
        ticketID,
        circleUserId,
        skillScores
      )
    } catch (err) {
      this.errors = err
    } finally {
      this.inProgress = false
    }
  })

  evaluateConnectionHelp = flow(function*(
    ticketID,
    circleUserId,
    rating
  ) {
    try {
      this.inProgress = true
      yield api.postEvaluationConnectionRating(ticketID, circleUserId, rating)      
      delete this.connectionsHelpRating[circleUserId];
    } catch (err) {
      this.errors = err
    } finally {
      this.inProgress = false
    }
  })

  reset = () => {
    this.inProgress = false
    this.errors = []
    this.newTicket = {
      title: "",
      description: "",
      skills: [],
      deadline: null,
      circleID: null,
    }
    this.myTickets = []
    this.evaluatingConnectionIDs = []
    this.connectionsSkillScore = {}
    this.connectionsHelpRating = {}
  }

  resetTicketForm = () => {
    this.newTicket = {
      id: null,
      title: "",
      description: "",
      skills: [],
      deadline: null,
      circleID: null,
    }
  }

  /**
   * Computed
   */
  get openedOwnTickets() {
    return this.myTickets.filter(({ status }) => status === "open")
  }

  get openedOwnTicketsCount() {
    return this.openedOwnTickets.length
  }

  get isUsersFirstEvaluation() {
    return this.myTickets.filter(({ status }) => status === "done")[0]
      ? false
      : true
  }
}

decorate(OwnTicketStore, {
  inProgress: observable,
  errors: observable,
  editingInProgress: observable,

  newTicket: observable,
  myTickets: observable,

  evaluatingConnectionIDs: observable,
  connectionsSkillScore: observable,
  connectionsHelpRating: observable,

  setTicketID: action.bound,
  setTitle: action.bound,
  setDescription: action.bound,
  setDeadline: action.bound,
  setTicketSkills: action.bound,
  setTicketCircle: action.bound,

  setEditingInProgress: action.bound,
  setEvaluatingConnectionIDs: action.bound,

  createTicket: action.bound,
  updateTicket: action.bound,
  fetchOwnTickets: action.bound,
  updateTicketStatus: action.bound,
  evaluateConnectionSkills: action.bound,
  evaluateConnectionHelp: action.bound,

  setConnectionsSkillScore: action.bound,
  setConnectionsHelpRating: action.bound,

  reset: action.bound,
  resetTicketForm: action.bound,

  openedOwnTickets: computed,
  openedOwnTicketsCount: computed,
  isUsersFirstEvaluation: computed,
})
