import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { Route, Switch } from "react-router-dom"
import { TransitionGroup, Transition } from "react-transition-group"
import classNames from "classnames"

import BodyContainer from "components/BodyContainer"

import MySkills from "./MySkills"
import CircleSkills from "./CircleSkills"

import NavLink from "components/NavLink"

import avatarPlaceholderImage from "static/images/avatar-placeholder.png"

const EditSkills = observer(props => {
  // const screenName = "EditSkills"

  const { routerStore, userStore } = useStore()
  // NOTE: router.location is used for transition group's switch prop
  const { location, currentPath } = routerStore
  const { avatarPreviewUrl, circleProfiles } = userStore
  const [activeTab, setActiveTab] = useState("dashboard")

  useEffect(() => {
    if (typeof currentPath === "string") {
      const tab = currentPath.split(`/`).pop()
      setActiveTab(tab)
    }
  }, [currentPath])

  return (
    <div className="edit-skills container-dashboard">
      <div className="header">
        <div className="top">
          <div className="close-link link-text-two">
            <NavLink
              pathname={"/dashboard"}
              navDirection={"back"}
              animAxis={"vertical"}
            >
              close
            </NavLink>
          </div>
          <div className="heading heading-four">Edit or update my skills</div>
        </div>
        <div className="bottom">
          <div className="skill-tabs">
            <NavLink
              className="tab"
              pathname={"/dashboard/edit_skills"}
              navDirection={"forward"}
              animAxis={"horizontal"}
              animContainer={"body"}
            >
              <div
                className={classNames({
                  "avatar-wrapper": true,
                  disabled: activeTab !== "edit_skills",
                })}
              >
                <div
                  className={classNames({
                    "avatar-image-container": true,
                    disabled: activeTab !== "edit_skills",
                  })}
                >
                  <img
                    className="avatar-image"
                    src={
                      avatarPreviewUrl
                        ? avatarPreviewUrl
                        : avatarPlaceholderImage
                    }
                    alt=""
                  />
                </div>
              </div>
            </NavLink>
            {Object.values(circleProfiles).map(profile => (
              <NavLink
                className="tab"
                pathname={`/dashboard/edit_skills/${profile.circle.id}`}
                navDirection={"back"}
                animAxis={"horizontal"}
                animContainer={"body"}
                key={profile.circle.id}
              >
                <div
                  className={`logo-container ${
                    parseInt(activeTab) !== profile.circle.id ? "disabled" : ""
                  }`}
                >
                  <img src={profile.circle.logo} alt="" />
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
      <TransitionGroup className="transition-group">
        <Transition
          key={location.key}
          appear={true}
          timeout={{
            appear: 0,
            enter: 0,
            exit: 600,
          }}
        >
          {state => (
            <Switch location={location}>
              {Object.values(circleProfiles).map(profile => (
                <Route
                  path={`/dashboard/edit_skills/${profile.circle.id}`}
                  key={profile.circle.id}
                  render={() => (
                    <BodyContainer transitionState={state}>
                      <CircleSkills selectedCircle={profile.circle.id} />
                    </BodyContainer>
                  )}
                />
              ))}
              <Route
                path={"/dashboard/edit_skills"}
                render={props => (
                  <BodyContainer transitionState={state}>
                    <MySkills {...props} />
                  </BodyContainer>
                )}
              />
            </Switch>
          )}
        </Transition>
      </TransitionGroup>
    </div>
  )
})

export default React.memo(EditSkills)
