import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import NavLink from "components/NavLink"

import classNames from "classnames"

import { ReactComponent as SlackIcon } from "static/svgs/icon-slack.svg"
import { ReactComponent as MailIcon } from "static/svgs/icon-mail.svg"

const Connect = observer(props => {
  // const screenName = "Connect"

  const ticketID = Number(props.match.params.ticket_id)

  const { userStore, ownTicketStore } = useStore()

  const { myTickets } = ownTicketStore
  const { fetchCircleProfile } = userStore

  const [ticket, setTicket] = useState({ connections: [] })

  const [activeConnectionID, setActiveConnectionID] = useState(null)
  const [circleSlackID, setCircleSlackID] = useState(null)

  useEffect(() => {
    setTicket(myTickets.filter(({ id }) => id === ticketID)[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTickets])

  useEffect(() => {
    const fetch = async () => {
      const circle = await fetchCircleProfile(ticket.circle_id)
      setCircleSlackID(circle.data.circle.slack_team_id)
    }
    if (ticket && ticket.hasOwnProperty("id")) fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket])

  const handleDeepLinking = (channel, connectionID) => {
    if (connectionID === activeConnectionID) {
      if (channel.hasOwnProperty("slack")) {
        const slackConnectionID = channel.slack_id
        // let link = `slack://channel?team=${circleSlackID}&id=${slackConnectionID}`
        let link = `slack://user?team=${circleSlackID}&id=${slackConnectionID}`
        window.location = link
      }
      if (channel.hasOwnProperty("email")) {
        const connectionMail = channel.email
        window.location = `mailto:${connectionMail}`
      }
    }
  }

  return (
    <div className="connect">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            pathname={"/need_help/opened"}
            navDirection={"back"}
            animAxis={"horizontal"}
            animContainer={"screen"}
          >
            back
          </NavLink>
        </div>
        <div className="heading heading-four">Available connections</div>
      </div>
      <div className="body">
        <div className="connections-list">
          <div className="uppercase-label-bold field-label">
            who would you like to be connected with?
          </div>
          {ticket &&
            ticket.connections
              .filter(({ status }) => status === "accepted")
              .map(connection => (
                <div
                  className={classNames({
                    connection: true,
                    active: connection.id === activeConnectionID,
                  })}
                  onClick={() => setActiveConnectionID(connection.id)}
                  key={connection.id}
                >
                  <div className="avatar-wrapper">
                    <div className="avatar-image-container">
                      <img
                        className="avatar-image"
                        src={connection.avatar}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="info-container">
                    <div className="name">{connection.name}</div>
                    <div className="circle">@ {connection.circle}</div>
                  </div>
                  <div className="channels">
                    {connection.communication.map((channel, i) => (
                      <div
                        key={i}
                        className="channel"
                        onClick={() =>
                          handleDeepLinking(channel, connection.id)
                        }
                      >
                        {channel.slack_id && (
                          <>
                            <div className="handle">{channel.slack}</div>
                            <div className="channel-logo">
                              <SlackIcon />
                            </div>
                          </>
                        )}
                        {channel.email && (
                          <>
                            <div className="handle">{channel.email}</div>
                            <div className="channel-logo">
                              <MailIcon />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  )
})

export default React.memo(Connect)
