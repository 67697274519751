import React from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { format } from "date-fns"

import NavLink from "components/NavLink"

import { ReactComponent as HiddenIcon } from "static/svgs/icon-hidden.svg"
import { ReactComponent as VisibleIcon } from "static/svgs/icon-visible.svg"

import { ReactComponent as PlusIcon } from "static/svgs/icon-plus.svg"

const EditJobTitle = observer(props => {
  // const screenName = "EditJobTitle"

  const { userStore } = useStore()
  const { jobs, updateCircleJobTitle } = userStore

  const toggleJobTitleVisibility = async job => {
    await updateCircleJobTitle(
      { ...job, is_visible: !job.is_visible },
      job.circle.id
    )
  }

  const dateToYear = date => format(date, "YYYY")

  return (
    <div className="edit-job-titles container-dashboard">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            navDirection={"back"}
            pathname={"/dashboard/job_titles"}
            animAxis={"horizontal"}
            animContainer={"screen"}
          >
            done
          </NavLink>
        </div>

        <div className="heading heading-four">Edit or update my job titles</div>
      </div>
      <div className="body scrollable">
        <div className="current-job-titles">
          <div className="uppercase-label-bold field-label">
            current job titles
          </div>
          <div className="jobs">
            {jobs
              .filter(job => job.is_finished === false)
              .map(job => (
                <div className="job" key={job.id}>
                  <div
                    className="visibility-toggle"
                    onClick={() => {
                      toggleJobTitleVisibility(job)
                    }}
                  >
                    {job.is_visible ? <VisibleIcon /> : <HiddenIcon />}
                  </div>
                  <div className="logo-container-small">
                    <img src={job.circle.logo} alt="" className="logo" />
                  </div>
                  <div className="tag-job-title">
                    <div className="title">{job.name}</div>
                    <div className="year">{dateToYear(job.date_created)}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="past-job-titles">
          <div className="uppercase-label-bold field-label">
            previous job titles
          </div>
          <div className="jobs">
            {jobs
              .filter(job => job.is_finished === true)
              .map(job => (
                <div className="job" key={job.id}>
                  <div
                    className="visibility-toggle"
                    onClick={() => {
                      toggleJobTitleVisibility(job)
                    }}
                  >
                    {job.is_visible ? <VisibleIcon /> : <HiddenIcon />}
                  </div>
                  <div className="logo-container-small">
                    <img src={job.circle.logo} alt="" className="logo" />
                  </div>
                  <div className="tag-job-title finished">
                    <div className="title">{job.name}</div>
                    <div className="year">{dateToYear(job.date_created)}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="footer my-skills-footer">
        <NavLink
          navDirection={"forward"}
          pathname={`/dashboard/add_job_titles`}
          animAxis={"vertical"}
        >
          <div className="action-item add">
            <div className="action-label">add a new job title</div>
            <div className="icon-container">
              <PlusIcon className="icon icon-plus" />
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  )
})

export default EditJobTitle
