import React from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { format } from "date-fns"
import classNames from "classnames"

import { ReactComponent as UsersIcon } from "static/svgs/icon-users.svg"
import { ReactComponent as MailIcon } from "static/svgs/icon-mail.svg"
import { ReactComponent as SlackIcon } from "static/svgs/icon-slack.svg"

const Connections = observer(() => {
  // const screenName = "Connections"

  const { userStore } = useStore()
  const {
    circleProfiles,
    updateCircleProfile,
    primaryCircleID,
    updatePrimaryCircleID,
  } = userStore

  const dateToYear = date => format(date, "YYYY")

  const handleNotificationToggle = async (circleID, channel, active) => {
    let requestData = {}
    requestData[`${channel}_notifications`] = !active
    await updateCircleProfile(circleID, requestData)
  }

  return (
    <div className="connections">
      <div className="uppercase-label-bold field-label">
        my primary connection
      </div>
      <div className="circle-tabs">
        {Object.values(circleProfiles).map(profile => (
          <div
            className={classNames({
              tab: true,
              "input-field-connection": true,
              active: parseInt(primaryCircleID) === profile.circle.id,
            })}
            key={profile.circle.id}
            onClick={() =>
              profile.circle.id !== primaryCircleID &&
              updatePrimaryCircleID(profile.circle.id)
            }
          >
            <div className="name">{profile.circle.name}</div>
            <div className="logo">
              <img src={profile.circle.logo} alt="" />
            </div>
          </div>
        ))}
      </div>

      <div className="uppercase-label-bold field-label">
        my connections by organization
      </div>
      <div className="circles">
        {Object.values(circleProfiles).map(profile => (
          <div className="circle" key={profile.circle.id}>
            <div className="logo-container-big">
              <img src={profile.circle.logo} alt="" className="logo" />
            </div>
            <div className="circle-info">
              <div className="tag-circle-connection">
                <div className="title">{profile.circle.name}</div>
                <div className="year">
                  {dateToYear(profile.circle.date_created)}
                </div>
              </div>
              <div className="circle-members">
                <UsersIcon />
                <div className="count">{profile.circle.members_count}</div>
              </div>
              <div className="circle-notifications">
                <div className="uppercase-label-bold field-label">
                  push notifications:
                </div>
                {profile.circle.communication.includes("slack") && (
                  <div
                    className={classNames({
                      "notification-icon-container": true,
                      active: profile.slack_notifications,
                    })}
                    onClick={e => {
                      handleNotificationToggle(
                        profile.circle.id,
                        "slack",
                        profile.slack_notifications
                      )
                    }}
                  >
                    <SlackIcon width={16} />
                  </div>
                )}
                {profile.circle.communication.includes("email") && (
                  <div
                    className={classNames({
                      "notification-icon-container": true,
                      active: profile.email_notifications,
                    })}
                    onClick={e => {
                      handleNotificationToggle(
                        profile.circle.id,
                        "email",
                        profile.email_notifications
                      )
                    }}
                  >
                    <MailIcon width={16} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
})

export default React.memo(Connections)
