import Dashboard from "./Dashboard"
import EditSkills from "./EditSkills"
import AddSkills from "./AddSkills"
import AddJobTitles from "./AddJobTitles"
import EditJobTitles from "./EditJobTitles"

const dashboardRoutes = [
  { Component: AddJobTitles, path: "/dashboard/add_job_titles" },
  { Component: EditJobTitles, path: "/dashboard/edit_job_titles" },
  { Component: AddSkills, path: "/dashboard/add_skills" },
  { Component: EditSkills, path: "/dashboard/edit_skills" },
  { Component: Dashboard, path: "/dashboard" },
]

export default dashboardRoutes
