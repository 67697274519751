// helper for parsing and formatting date time values

// NOTE: we are getting following date/time format from backend:
// YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z] / 2019-06-10T21:36:26.438651Z

// we need:
//   - relative day (`today`, `tomorrow`, 'sunday' ...)
//   - kind date format (`1` : `Jun` : `2019` : `11:15`)
//   - format for date and time input fields (edit, reopen or duplicate ticket)
//   - minutes until or past ticket deadline (5 min ago, 10 min left)

import {
  format,
  differenceInMinutes,
  differenceInDays,
  isToday,
  isPast,
} from "date-fns"

export const parsedDate = date => {
  return {
    relative: {
      day: format(date, "ddd"), // Sun
      differenceInMinutes: differenceInMinutes(date, Date.now()),
    },
    simpleFormat: {
      dayOfMonth: format(date, "D"), // '1'
      month: format(date, "MMM"), // 'Jun'
      year: format(date, "YYYY"), // '2019'
      time: format(date, "HH:mm"), // '11:22'
    },
    inputFields: {
      date: format(date, "YYYY-MM-DD"),
      time: format(date, "HH:mm"),
    },
    ticket: {
      day: () => {
        if (isToday(date)) return "today"
        else return format(date, "ddd")
      },
      isOverDeadline: isPast(date),
      timePassed: () => {
        const minutesPassed = differenceInMinutes(Date.now(), date)
        if (minutesPassed > 1440) return "1 day"
        if (minutesPassed > 60) {
          const hours = Math.round(minutesPassed / 60)
          return `${hours} ${hours.length === 1 ? "hour" : "hours"}`
        }
        return `${minutesPassed} min`
      },
      differenceInMinutes: differenceInMinutes(Date.now(), date),
      differenceInDays: differenceInDays(date, Date.now()),
    },
  }
}
