import React, { useState, useRef, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { debounce } from "lodash"

import { searchSkills, fetchRelatedSkills } from "data/api"

import { useStore } from "data/store/RootStore"

import classNames from "classnames"

import NavLink from "components/NavLink"

const AddSkills = observer(({ history }) => {
  // const screenName = "AddSkills"

  const { userStore } = useStore()
  const { updateUserSkills, fetchUserSkills } = userStore

  const [userSkills, setUserSkills] = useState([])

  const [matchingSkills, setMatchingSkills] = useState([])
  const [recommendedSkills, setRecommendedSkills] = useState([])

  const [selectedSkills, setSelectedSkills] = useState([])

  // if user clicks back button, let her proceed, she already added some skills
  const [userHasSkills, setUserHasSkills] = useState(false)
  useEffect(() => {
    const fetch = async () => {
      const userSkills = await fetchUserSkills()
      userSkills.length ? setUserHasSkills(true) : setUserHasSkills(false)
      setUserSkills(userSkills)
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const scrollableContainerRef = useRef(null)
  const searchInputRef = useRef(null)

  const handleSubmitForm = async () => {
    await updateUserSkills(selectedSkills)
    history.push({
      pathname: "/onboard/share_skills",
      state: {
        navDirection: "forward",
        animAxis: "horizontal",
        animContainer: "screen",
      },
    })
  }

  const handleSkillSearchUpdate = debounce(async searchString => {
    if (searchString.length >= 3) {
      try {
        const res = await searchSkills(searchString)
        setMatchingSkills(res.data)
      } catch (error) {
        console.error(error)
      }
    } else {
      setMatchingSkills([])
    }
  }, 300)

  // header shrinks when user scrolls (down or in general)?
  const handleScroll = e => {
    // console.log(e)
  }

  const addSkill = async id => {
    const skill = [...matchingSkills, ...recommendedSkills].filter(
      skill => skill.id === id
    )[0]
    const isDuplicate = selectedSkills.filter(skill => skill.id === id)[0]
    if (!isDuplicate && skill) {
      setSelectedSkills([...selectedSkills, { ...skill, isVisible: true }])
      setMatchingSkills(matchingSkills.filter(skill => skill.id !== id))
    }
    const relatedRes = await fetchRelatedSkills(id)
    const filteredRecommendedSkills = relatedRes.data.filter(
      relatedSkill =>
        !selectedSkills.filter(
          selectedSkill => relatedSkill.id === selectedSkill.id
        )[0]
    )
    setRecommendedSkills(filteredRecommendedSkills)
  }

  const removeSkill = id => {
    const remainingSkills = selectedSkills.filter(skill => skill.id !== id)
    setSelectedSkills([...remainingSkills])
  }

  return (
    <div className="add-skills container-onboard">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            pathname={"/onboard/add_basic_info"}
            navDirection={"back"}
            animAxis={"horizontal"}
            animContainer={"screen"}
          >
            back
          </NavLink>
        </div>
        <div className="steps-container">
          <div className="step-marker" />
          <div className="step-marker active" />
          <div className="step-marker" />
          <div className="step-marker" />
        </div>
        <div className="heading heading-four">
          A thing or two about your core skills will come in handy!
        </div>
      </div>
      <div
        className="body scrollable"
        ref={scrollableContainerRef}
        onScroll={e => handleScroll(e)}
      >
        <div className="section-search-input">
          <div className="paragraph paragraph-two">
            Please, list your core skills here.
          </div>
          <input
            className="input-field"
            type="text"
            placeholder=""
            onChange={e => handleSkillSearchUpdate(e.target.value)}
            ref={searchInputRef}
          />
        </div>
        <div className="section-search-results">
          <div className="uppercase-label-bold field-label">
            search results:
          </div>
          {matchingSkills.length > 0 && (
            <div className="tags-container">
              {matchingSkills.map((skill, i) => {
                let isSelected = Object.values(selectedSkills).filter(
                  ({ id }) => id === skill.id
                )[0]

                let isOwn = userSkills.filter(({ id }) => id === skill.id)[0]
                const unselectable = isSelected || isOwn

                return (
                  <span
                    key={i}
                    className={unselectable ? `tag-active` : `tag-regular`}
                    onClick={() =>
                      unselectable ? () => {} : addSkill(skill.id)
                    }
                  >
                    {skill.name}
                  </span>
                )
              })}
            </div>
          )}
        </div>

        {recommendedSkills.length !== 0 && (
          <div className="section-search-recommended">
            <form onSubmit={handleSubmitForm}>
              <div className="uppercase-label-bold field-label">
                related skills
              </div>
              <div className="tags-container">
                {recommendedSkills.map(skill => {
                  return (
                    <span
                      key={skill.id}
                      className={`tag-regular`}
                      onClick={() => addSkill(skill.id)}
                    >
                      {skill.name}
                    </span>
                  )
                })}
              </div>
            </form>
          </div>
        )}

        <div className="section-search-selected">
          <div className="uppercase-label-bold field-label">
            your selected skills
          </div>
          <form onSubmit={handleSubmitForm}>
            {selectedSkills.length > 0 && (
              <div className="tags-container">
                {selectedSkills.map((skill, i) => (
                  <span
                    key={i}
                    className="tag-inactive"
                    onClick={() => removeSkill(skill.id)}
                  >
                    {skill.name}
                  </span>
                ))}
              </div>
            )}
          </form>
        </div>
        <div className="footer">
          <div
            className={classNames({
              "cta-primary": true,
              disabled: !selectedSkills.length && !userHasSkills,
            })}
            onClick={
              selectedSkills.length || userHasSkills
                ? handleSubmitForm
                : () => {}
            }
          >
            Next
          </div>
        </div>
      </div>
    </div>
  )
})

export default React.memo(AddSkills)
