import React, { useState, useEffect, useRef } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"
import { parsedDate } from "utils/dateTimeHelper"

import { useOnClickOutside } from "hooks/useOnClickOutside"
import classNames from "classnames"

import { ReactComponent as UsersIcon } from "static/svgs/icon-users.svg"
import { ReactComponent as CrossIcon } from "static/svgs/icon-cross.svg"
import { ReactComponent as CheckIcon } from "static/svgs/icon-check.svg"
import { ReactComponent as UserIcon } from "static/svgs/icon-user.svg"
import { ReactComponent as PauseIcon } from "static/svgs/icon-pause.svg"
import { ReactComponent as SlackIcon } from "static/svgs/icon-slack.svg"
import { ReactComponent as MailIcon } from "static/svgs/icon-mail.svg"

// const CodeConfirmation = observer(({ match, history }) => {

const MatchedTicket = observer(({ ticketMatch }) => {
  const status_opt = ["accepted", "solved"]
  const { matchedTicketStore, userStore } = useStore()
  const { createMatchedTicketResponse, declineReasons } = matchedTicketStore

  const { skills: ownSkills } = userStore

  const isOwnSkill = ticketSkill => {
    return ownSkills.filter(ownSkill => ownSkill.id === ticketSkill.id).length
  }

  const ticketRef = useRef()

  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ticketRef, () => setIsOpen(false))

  const toggle = () => {
    const ticketStatus = ticketMatch.ticket.status
    if (ticketStatus !== "deleted" && ticketStatus !== "paused") {
      setIsOpen(!isOpen)
    }
  }

  const [acceptSection, setAcceptSection] = useState(false)
  const [declineSection, setDeclineSection] = useState(false)
  const [submitSection, setSubmitSection] = useState(false)

  const [
    activeCommunicationChannels,
    setActiveCommunicationChannels,
  ] = useState({ slack: false, email: false })

  useEffect(() => {
    const { slack, email } = activeCommunicationChannels
    if ((slack || email) && !declineSection) setSubmitSection(true)
    else setSubmitSection(false)
  }, [activeCommunicationChannels, declineSection])

  const foldTicket = () => {
    setAcceptSection(false)
    setDeclineSection(false)
    setSubmitSection(false)
  }

  const submitTicketAcceptResponse = ticketID => {
    let channelsArray = []
    activeCommunicationChannels.slack && channelsArray.push("slack")
    activeCommunicationChannels.email && channelsArray.push("email")
    createMatchedTicketResponse(ticketID, "accepted", 0, channelsArray)
    foldTicket()
  }

  const submitTicketDeclineResponse = (ticketID, declineID) => {
    // declined ticket
    const ticket = ticketRef.current

    // get ticket height
    const ticketHeight = ticket.offsetHeight
    const screenWidth = window.innerWidth

    // set previous height
    ticket.style.height = `${ticketHeight}px`
    ticket.style.width = `${screenWidth}px`

    // widen the ticket
    const padding = ticket.getBoundingClientRect().x
    ticket.style.left = `-${padding}px`

    // add new class
    ticket.className += " feedback-ticket"

    createMatchedTicketResponse(ticketID, "declined", declineID, [])
    // wait few seconds and close the feedback popup
    setTimeout(() => {
      ticket.style.display = `none`
    }, 2000)
  }

  const date = parsedDate(ticketMatch.ticket.deadline)
  const ticket = ticketMatch.ticket
  const overDeadline = date.ticket.isOverDeadline
  const acceptedConnectionsCount = ticket.connections.filter(
    ({ status }) => status === "accepted"
  ).length

  const deadlineColor = differenceInDays => {
    if (differenceInDays <= 0) return "#FF6600"
    if (differenceInDays === 1) return "#FFA162"
    if (differenceInDays === 2) return "#FFB934"
    return "#B6C3D4"
  }

  const userIconColor = connectionCount => {
    if (connectionCount === 0) return "#FF6600"
    if (connectionCount === 1) return "#FFA162"
    if (connectionCount === 2) return "#FFB934"
    return "#B6C3D4"
  }

  return (
    <div
      className={classNames({
        "matched-ticket": true,
        [ticket.status]: true,
      })}
      key={ticket.id}
      ref={ticketRef}
    >
      <div className="ticket-status-icon">
        {ticketMatch.ticket.status === "paused" && <PauseIcon />}
        {ticketMatch.status === "accepted" && <UserIcon />}
      </div>
      <div
        className={classNames({
          "left-border": true,
          "is-open": isOpen,
        })}
      />
      <div className="section-face" onClick={toggle}>
        <div className="main">
          <div className="title">{ticket.title}</div>
          <div className="skills tags-container-ticket">
            {ticket.skills.map(skill => (
              <div
                key={skill.id}
                className={classNames({
                  "tag-small-regular": !isOwnSkill(skill),
                  "tag-small-active": isOwnSkill(skill),
                })}
              >
                {skill.name}
              </div>
            ))}
          </div>
        </div>
        <div className="side">
          <div className="deadline">
            <div
              className={classNames({ "day-with-word": true })}
              style={{ color: deadlineColor(date.ticket.differenceInDays) }}
            >
              {date.ticket.day()}
            </div>
            {overDeadline && date.ticket.differenceInMinutes < 2280 ? (
              <div className="time-over">
                <div>{date.ticket.timePassed()}</div>
                <span>over</span>
              </div>
            ) : (
              <>
                <div className="day-and-month">
                  {date.simpleFormat.dayOfMonth} {date.simpleFormat.month}
                </div>
                <div className="year">{date.simpleFormat.year}</div>
                <div className="time">{date.simpleFormat.time}</div>
              </>
            )}
          </div>

          <div className="ticket-connections">
            <UsersIcon
              width={12}
              height={8.26}
              style={{ fill: userIconColor(acceptedConnectionsCount) }}
            />
            <div className="connection-count">{acceptedConnectionsCount}</div>
          </div>
        </div>
      </div>

      <div
        className={classNames({
          "section-more": true,
          visible: isOpen,
        })}
      >
        <div className="description">{ticket.short_description}</div>
        {!status_opt.includes(ticketMatch.status)  && (
          <div className="actions">
            <div
              className={classNames({
                accept: true,
                active: acceptSection,
              })}
              onClick={() => {
                setAcceptSection(!acceptSection)
                setDeclineSection(false)
              }}
            >
              <span className="text">accept</span>
              <CheckIcon />
            </div>
            <div
              className={classNames({
                decline: true,
                active: declineSection,
              })}
              onClick={() => {
                setDeclineSection(!declineSection)
                setAcceptSection(false)
              }}
            >
              <span className="text">decline</span>
              <CrossIcon />
            </div>
          </div>
        )}
      </div>
      {acceptSection && isOpen && (
        <div className="section-accept">
          <div className="uppercase-label-bold field-label">i am available</div>
          <div className="actions">
            <div
              className={classNames({
                slack: true,
                active: activeCommunicationChannels.slack,
              })}
              onClick={() => {
                setActiveCommunicationChannels({
                  ...activeCommunicationChannels,
                  slack: !activeCommunicationChannels.slack,
                })
              }}
            >
              <span className="text">via slack</span>
              <div className="communication-icon">
                <SlackIcon />
              </div>
            </div>
            <div
              className={classNames({
                email: true,
                active: activeCommunicationChannels.email,
              })}
              onClick={() => {
                setActiveCommunicationChannels({
                  ...activeCommunicationChannels,
                  email: !activeCommunicationChannels.email,
                })
              }}
            >
              <span className="text">via email</span>
              <div className="communication-icon">
                <MailIcon />
              </div>
            </div>
          </div>
        </div>
      )}
      {submitSection && isOpen && (
        <div className="section-submit">
          <div
            className="cta cta-secondary-dark"
            onClick={() => submitTicketAcceptResponse(ticket.id)}
          >
            Send
          </div>
        </div>
      )}
      {declineSection && isOpen && (
        <div className="section-decline">
          <div className="actions">
            {declineReasons.map(reason => (
              <div
                className="reason"
                key={reason.id}
                onClick={() =>
                  submitTicketDeclineResponse(ticket.id, reason.id)
                }
              >
                <span className="text">{reason.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="feedback-message">
        Thank you <br /> for your feedback!
      </div>
    </div>
  )
})

export default MatchedTicket
