import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { Route, Switch } from "react-router-dom"
import { TransitionGroup, Transition } from "react-transition-group"

import classNames from "classnames"

import BodyContainer from "components/BodyContainer"

import Edit from "./EditTicket"
import Opened from "./OpenedTickets"
import Solved from "./SolvedTickets"

import NavLink from "components/NavLink"

const NeedHelp = observer(({ history }) => {
  // const screenName = "Dashboard"

  const { routerStore, ownTicketStore } = useStore()

  const { location, currentPath, lastParamFromURL } = routerStore
  const {
    openedOwnTicketsCount,
    fetchOwnTickets,
    newTicket,
    resetTicketForm,
  } = ownTicketStore

  const [activeTab, setActiveTab] = useState("edit")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setActiveTab(lastParamFromURL), [currentPath])

  useEffect(() => {
    fetchOwnTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEditTicketState = () => {
    if (!newTicket.id) {
      history.push({
        pathname: "/dashboard",
        state: {
          navDirection: "back",
          animAxis: "vertical",
          animContainer: "screen",
        },
      })
    } else {
      resetTicketForm()
      history.push({
        pathname: "/need_help/opened",
        state: {
          navDirection: "forward",
          animAxis: "horizontal",
          animContainer: "body",
        },
      })
    }
  }

  return (
    <div className="need-help container-need-help">
      <div className="header">
        <div className="close-link link-text-two">
          {newTicket.id && activeTab === "edit" ? (
            <div onClick={() => handleEditTicketState()}>leave</div>
          ) : (
            <div onClick={() => handleEditTicketState()}>close</div>
          )}
        </div>
        <div className="navigation">
          {newTicket.id && activeTab === "edit" ? (
            <>
              <NavLink
                className={classNames({
                  "link-edit": true,
                  tab: true,
                  active: activeTab === "edit" || activeTab === "need_help",
                })}
                pathname={"/need_help/edit"}
                navDirection={"forward"}
                animAxis={"horizontal"}
                animContainer={"body"}
              >
                Edit your Ticket
              </NavLink>
            </>
          ) : (
            <>
              <NavLink
                className={classNames({
                  "link-edit": true,
                  tab: true,
                  active: activeTab === "edit" || activeTab === "need_help",
                })}
                pathname={"/need_help/edit"}
                navDirection={"forward"}
                animAxis={"horizontal"}
                animContainer={"body"}
              >
                <span className="opacity-handle">I need help</span>
              </NavLink>
              <NavLink
                className={classNames({
                  "link-opened": true,
                  tab: true,
                  active: activeTab === "opened",
                })}
                pathname={"/need_help/opened"}
                navDirection={"forward"}
                animAxis={"horizontal"}
                animContainer={"body"}
              >
                {openedOwnTicketsCount > 0 && (
                  <div className="notification-open-ticket">
                    <span>{openedOwnTicketsCount}</span>
                  </div>
                )}
                <span className="opacity-handle">Open</span>
              </NavLink>
              <NavLink
                className={classNames({
                  "link-solved": true,
                  tab: true,
                  active: activeTab === "solved",
                })}
                pathname={"/need_help/solved"}
                navDirection={"forward"}
                animAxis={"horizontal"}
                animContainer={"body"}
              >
                <span className="opacity-handle">Solved</span>
              </NavLink>
            </>
          )}
        </div>
      </div>
      <TransitionGroup
        className={classNames({
          "transition-group": true,
          "edit-ticket-tg": activeTab === "edit" || activeTab === "need_help",
        })}
      >
        <Transition
          key={location.key}
          appear={true}
          timeout={{
            appear: 0,
            enter: 0,
            exit: 600,
          }}
        >
          {state => (
            <Switch location={location}>
              <Route
                path={"/need_help/solved"}
                render={props => (
                  <BodyContainer transitionState={state}>
                    <Solved {...props} />
                  </BodyContainer>
                )}
              />
              <Route
                path={"/need_help/opened"}
                render={props => (
                  <BodyContainer transitionState={state}>
                    <Opened {...props} />
                  </BodyContainer>
                )}
              />
              <Route
                path={"/need_help"}
                render={props => (
                  <BodyContainer transitionState={state}>
                    <Edit {...props} />
                  </BodyContainer>
                )}
              />
            </Switch>
          )}
        </Transition>
      </TransitionGroup>
    </div>
  )
})

export default React.memo(NeedHelp)
