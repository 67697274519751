import React from "react"
import { useStore } from "../data/store/RootStore"
import { observer } from "mobx-react-lite"

import Badge from "components/Badge"

const BadgesGrid = observer(() => {
  const { userStore } = useStore()
  const { badges } = userStore

  const width = 76

  return (
    <>
      <div className="badges-grid-profile">
        <div className="column-4">
          {badges
            .filter((_badge, i) => i === 9)
            .map(badge => (
              <Badge
                key={badge.action}
                id={badge.action}
                level={badge.level}
                width={width}
              />
            ))}
        </div>
        <div className="column-3">
          {badges
            .filter((_badge, i) => i > 5 && i <= 8)
            .map(badge => (
              <Badge
                key={badge.action}
                id={badge.action}
                level={badge.level}
                width={width}
              />
            ))}
        </div>
        <div className="column-2">
          {badges
            .filter((_badge, i) => i > 2 && i <= 5)
            .map(badge => (
              <Badge
                key={badge.action}
                id={badge.action}
                level={badge.level}
                width={width}
              />
            ))}
        </div>
        <div className="column-1">
          {badges
            .filter((_badge, i) => i <= 2)
            .map(badge => (
              <Badge
                key={badge.action}
                id={badge.action}
                level={badge.level}
                width={width}
              />
            ))}
        </div>
      </div>
    </>
  )
})

export default React.memo(BadgesGrid)
