import Splash from "./Splash"
import Intro from "./Intro"
import Registration from "./Registration"
import Login from "./Login"
import CodeConfirmation from "./CodeConfirmation"
import ConfirmationOk from "./ConfirmationOk"
import Support from "./Support"
import Help from "./Help"
import HelpMore from "./HelpMore"
import AddBasicInfo from "./AddBasicInfo"
import AddSkills from "./AddSkills"
import ShareSkills from "./ShareSkills"
import AddJobTitle from "./AddJobTitle"
import Final from "./Final"

import HandleInvite from "./HandleInvite"

const onboardRoutes = [
  { Component: Final, path: "/onboard/final" },
  { Component: AddJobTitle, path: "/onboard/add_job_title" },
  { Component: ShareSkills, path: "/onboard/share_skills" },
  { Component: AddSkills, path: "/onboard/add_skills" },
  { Component: AddBasicInfo, path: "/onboard/add_basic_info" },
  { Component: HelpMore, path: "/onboard/help_more/:step" },
  { Component: Help, path: "/onboard/help/:step" },
  { Component: ConfirmationOk, path: "/onboard/confirmation_ok" },
  { Component: CodeConfirmation, path: "/onboard/code_confirmation/:resend?" },
  { Component: Support, path: "/onboard/support" },
  { Component: Login, path: "/onboard/login" },
  { Component: Registration, path: "/onboard/registration" },
  { Component: Intro, path: "/onboard/intro/:step" },
  { Component: Splash, exact: true, path: "/onboard" },
  { Component: HandleInvite, path: "/invitation" },
]

export default onboardRoutes
