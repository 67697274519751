import React, { useEffect } from "react"
import { useStore } from "data/store/RootStore"

import queryString from "query-string"

const HandleInvite = ({ history, location }) => {
  const { authStore } = useStore()
  const { setCircleInviteToken } = authStore

  useEffect(() => {
    const values = queryString.parse(location.search)
    const inviteToken = values.invite_token
    setCircleInviteToken(inviteToken)
    localStorage.setItem("circle_invite_token", inviteToken)
    history.push({
      pathname: "/onboard",
      state: { navDirection: "forward", animAxis: "horizontal" },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  return (
    <div className="handle-invite container-onboard">
      <div className="body">
        {/* <div className="heading-onboard-one">handle invite</div> */}
      </div>
    </div>
  )
}

export default React.memo(HandleInvite)
