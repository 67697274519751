import React, { useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import classNames from "classnames"

import NavLink from "components/NavLink"

import { ReactComponent as HiddenIcon } from "static/svgs/icon-hidden.svg"
import { ReactComponent as VisibleIcon } from "static/svgs/icon-visible.svg"

import { ReactComponent as PlusIcon } from "static/svgs/icon-plus.svg"
import { ReactComponent as TrashIcon } from "static/svgs/icon-trash.svg"
import { ReactComponent as CrossIcon } from "static/svgs/icon-cross.svg"
import { ReactComponent as StopIcon } from "static/svgs/icon-stop.svg"

const MySkills = observer(props => {
  // const screenName = "MySkills"

  const { userStore } = useStore()
  const { skills, updateUserSkills, deleteUserSkills } = userStore

  const [deleteView, setDeleteView] = useState(false)
  const [deleteSkillsList, setDeleteSkillsList] = useState(
    skills.map(skill => ({ ...skill, delete: false }))
  )

  const toggleSkillVisibility = id => {
    updateUserSkills(
      skills
        .filter(skill => skill.id === id)
        .map(skill => ({
          ...skill,
          is_visible: skill.id === id ? !skill.is_visible : skill.is_visible,
        }))
    )
  }

  const toggleSkillDelete = id => {
    setDeleteSkillsList(
      deleteSkillsList.map(skill => ({
        ...skill,
        delete: skill.id === id ? !skill.delete : skill.delete,
      }))
    )
  }

  const handleSkillDelete = () => {
    deleteUserSkills(
      deleteSkillsList
        .filter(skill => skill.delete === true)
        .map(({ id }) => ({ id }))
    )
    setDeleteView(false)
  }

  const isAnySetForDeletion = () =>
    deleteSkillsList.filter(skill => skill.delete).length

  return (
    <>
      <div className="my-skills scrollable">
        {deleteView ? (
          <div className="uppercase-label-bold">all my skills</div>
        ) : (
          <div className="uppercase-label-bold">all my skills</div>
        )}
        <div className="tags-list">
          {deleteView
            ? deleteSkillsList.map(skill => (
                <div
                  key={skill.id}
                  className="tags-list-row"
                  onClick={() => toggleSkillDelete(skill.id)}
                >
                  <div className="icon-container">
                    {skill.delete ? (
                      <StopIcon className="icon icon-stop" />
                    ) : (
                      <StopIcon className="icon icon-stop inactive" />
                    )}
                  </div>
                  <span
                    className={classNames({
                      "tag-regular": skill.is_confirmed,
                      "tag-unconfirmed": !skill.is_confirmed,
                      "tag-delete": skill.delete,
                    })}
                  >
                    {skill.name}
                  </span>
                </div>
              ))
            : skills.map(skill => (
                <div
                  key={skill.id}
                  className="tags-list-row"
                  onClick={() => toggleSkillVisibility(skill.id)}
                >
                  <div className="icon-container">
                    {skill.is_visible ? (
                      <VisibleIcon className="icon icon-visible" />
                    ) : (
                      <HiddenIcon className="icon icon-hidden" />
                    )}
                  </div>
                  <span
                    className={classNames({
                      "tag-regular": skill.is_confirmed,
                      "tag-unconfirmed": !skill.is_confirmed,
                      hidden: !skill.is_visible,
                    })}
                  >
                    <span>{skill.name}</span>
                  </span>
                </div>
              ))}
        </div>
      </div>
      <div className="my-skills-footer">
        {deleteView ? (
          <div
            className={classNames({
              "action-item": true,
              "delete-confirm": true,
              disabled: !isAnySetForDeletion(),
            })}
            onClick={isAnySetForDeletion() ? handleSkillDelete : () => {}}
          >
            <div
              className={classNames({
                "icon-container": true,
                disabled: !isAnySetForDeletion(),
                danger: isAnySetForDeletion(),
              })}
            >
              <TrashIcon
                className={classNames({
                  icon: true,
                  "icon-trash": true,
                  disabled: !isAnySetForDeletion(),
                  danger: isAnySetForDeletion(),
                })}
              />
            </div>

            <div
              className={classNames({
                "action-label": true,
                disabled: !isAnySetForDeletion(),
              })}
            >
              delete skills
            </div>
          </div>
        ) : (
          <div
            className="action-item delete"
            onClick={() => setDeleteView(true)}
          >
            <div className="icon-container">
              <TrashIcon className="icon icon-trash" />
            </div>
            <div className="action-label">delete skills</div>
          </div>
        )}

        {deleteView ? (
          <div
            className="action-item delete-cancel"
            onClick={() => setDeleteView(false)}
          >
            <div className="action-label">cancel deleting</div>
            <div className="icon-container">
              <CrossIcon className="icon icon-cross" />
            </div>
          </div>
        ) : (
          <NavLink
            navDirection={"forward"}
            pathname={"/dashboard/add_skills"}
            animAxis={"vertical"}
          >
            <div className="action-item add">
              <div className="action-label">add new skills</div>
              <div className="icon-container">
                <PlusIcon className="icon icon-plus" />
              </div>
            </div>
          </NavLink>
        )}
      </div>
    </>
  )
})

export default React.memo(MySkills)
