import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"
import { fileUpload } from "data/api"

import NavLink from "components/NavLink"
import BadgesGrid from "components/BadgesGrid"

import { ReactComponent as LogoutIcon } from "static/svgs/icon-logout.svg"
import { ReactComponent as EditIcon } from "static/svgs/icon-edit.svg"
import { ReactComponent as IconAddImage } from "static/svgs/icon-add-image.svg"
import { ReactComponent as IconBug } from "static/svgs/icon-bug.svg"

import avatarPlaceholderImage from "static/images/avatar-placeholder.png"
// import headerBadgesImage from "static/images/badges-profile.png"

const Profile = observer(props => {
  // const screenName = "Profile"

  const [file, setFile] = useState(null)
  const { userStore, authStore } = useStore()

  const { logout } = authStore
  const {
    firstName,
    lastName,
    avatarPreviewUrl,
    setAvatarPreviewUrl,
  } = userStore

  const handleLogout = () => {
    logout()
    props.history.push({
      pathname: "/onboard",
      state: {
        navDirection: "forward",
        animAxis: "vertical",
        animContainer: "screen",
      },
    })
  }

  const handleImageChange = e => {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      setFile(file)
      setAvatarPreviewUrl(reader.result)
    }
    reader.readAsDataURL(file)
  }

  useEffect(() => {
    const upload = async () => await fileUpload(file)
    file && upload()
  }, [file])

  return (
    <div className="profile container-onboard scrollable">
      <div className="header">
        <div className="top">
          <div className="screen-title heading-four">My profile</div>
          <div className="close-button link-text-two">
            <NavLink
              pathname={"/dashboard"}
              navDirection={"back"}
              animAxis={"vertical"}
              animContainer={"screen"}
            >
              close
            </NavLink>
          </div>
        </div>
        <div className="bottom">
          <div className="user-info">
            <form>
              <input
                className="input-field-file"
                type="file"
                id="avatar"
                value=""
                onChange={e => handleImageChange(e)}
              />
            </form>
            <label htmlFor="avatar" className="avatar-wrapper">
              <div className="avatar-image-container">
                {avatarPreviewUrl ? (
                  <img className="avatar-image" src={avatarPreviewUrl} alt="" />
                ) : (
                  <img
                    className="avatar-image"
                    src={avatarPlaceholderImage}
                    alt=""
                  />
                )}
              </div>
              <IconAddImage className="upload-avatar-icon" />
            </label>
            <NavLink
              pathname={"/profile/edit"}
              navDirection={"forward"}
              animAxis={"horizontal"}
              animContainer={"screen"}
            >
              <div className="name">
                <EditIcon className="pen-icon" />
                <div className="first-name">{firstName}</div>
                <div className="last-name">{lastName}</div>
              </div>
            </NavLink>
          </div>
          <div className="link-text-two log-out" onClick={handleLogout}>
            logout <LogoutIcon className="icon icon-visible" />
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="body">
        <div className="text">
          Your collection of badges is pretty impressive.
        </div>
        <div className="user-badges">
          <NavLink
            pathname={"/profile/badges"}
            navDirection={"forward"}
            animAxis={"vertical"}
            className={"badges-container"}
          >
            <BadgesGrid wait={600} location={"profile"} />
          </NavLink>
        </div>
        <div className="text">Check how you can get them even MORE!</div>
      </div>
      <div className="divider"></div>
      <div className="footer">
        <div className="version">version: 1.0</div>
        <NavLink
          className={"bug-link"}
          pathname={"/profile/report"}
          navDirection={"forward"}
          animAxis={"vertical"}
        >
          <div className="link-text-two">report issue</div>
          <IconBug className="bug-icon" />
        </NavLink>
      </div>
    </div>
  )
})

export default React.memo(Profile)
