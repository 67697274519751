import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { orderBy } from "lodash"

import MatchedTicket from "components/MatchedTicket"

const SolvedTickets = observer(({ history }) => {
  const status_opt = ["accepted", "solved"]
  const { matchedTicketStore } = useStore()
  const { matchedTickets } = matchedTicketStore

  // const [activeTicketID, setActiveTicketID] = useState(null)
  const [orderedTickets, setOrderedTickets] = useState(matchedTickets)

  useEffect(() => {
    // console.log(orderedTickets)
  }, [orderedTickets])

  useEffect(() => {
    const myCompletedTickets = matchedTickets
    // .filter(({ status }) => status === "accepted")
    //   .filter(({ status }) => (status === "accepted" || status === "solved"))
      .filter(({ status }) => status_opt.includes(status))
      .filter(({ ticket }) => ticket.status === "done")

    // completedTickets[0].ticket.deadline (!!)
    setOrderedTickets(orderBy(myCompletedTickets, null, "asc"))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchedTickets])

  return (
    <div className="solved-tickets-assigned">
      <div className="my-tickets-list">
        {orderedTickets.map(ticketMatch => (
          <span
            className="activator"
            key={ticketMatch.ticket.id}
            // onClick={() => setActiveTicketID(ticketMatch.ticket.id)}
          >
            <MatchedTicket
              ticketMatch={ticketMatch}
              history={history}
              // isActive={ticketMatch.ticket.id === activeTicketID}
            />
          </span>
        ))}
      </div>
    </div>
  )
})

export default SolvedTickets
