import React from "react"
import { useStore } from "../data/store/RootStore"
import { observer } from "mobx-react-lite"

import classNames from "classnames"

const UpdateNotification = observer(() => {
  const { routerStore } = useStore()
  const { isUpdatePending, setIsUpdatePending } = routerStore

  const closeNotification = () => setIsUpdatePending(false)

  return (
    <div
      className={classNames({
        "app-update-notification": true,
        active: isUpdatePending,
      })}
    >
      <div className="modal-window">
        <div className="close-icon" onClick={closeNotification}>
          X
        </div>
        <span>
          Application update is available and will be applied when all
          application tabs are closed.
        </span>
      </div>
    </div>
  )
})

export default React.memo(UpdateNotification)
