import React from "react"
// import { useStore } from "../data/store/RootStore"
import { observer } from "mobx-react-lite"

import classNames from "classnames"

import loader from "static/images/loader-128.gif"

const LoadingScreen = observer(() => {
  // const rootStore  = useStore()
  // const { inProgress } = rootStore

  return (
    <div
      className={classNames({
        "loading-screen": true,
        active: false, // NOTE: Set back to `inProgress` to enable functionality
      })}
    >
      <img className="loading-spinner-cc" src={loader} alt="" />
    </div>
  )
})

export default React.memo(LoadingScreen)
