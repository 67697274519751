import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import NavLink from "components/NavLink"
import NavBar from "components/NavBar"

import bgOver600_1 from "static/images/onboarding/intro/skila-bg_img-01-1-nad_600px_width-0_1.png"

import bg414x300_1 from "static/images/onboarding/intro/skila-bg_img-01-1-pod_600px_width-0_1-@1x.png"
import bg414x300x2_1 from "static/images/onboarding/intro/skila-bg_img-01-1-pod_600px_width-0_1-@2x.png"
import bg414x300x3_1 from "static/images/onboarding/intro/skila-bg_img-01-1-pod_600px_width-0_1-@3x.png"

import bgOver600_2 from "static/images/onboarding/intro/skila-bg_img-01-2-nad_600px_width-0_1.png"

import bg414x300_2 from "static/images/onboarding/intro/skila-bg_img-01-2-pod_600px_width-0_1-@1x.png"
import bg414x300x2_2 from "static/images/onboarding/intro/skila-bg_img-01-2-pod_600px_width-0_1-@2x.png"
import bg414x300x3_2 from "static/images/onboarding/intro/skila-bg_img-01-2-pod_600px_width-0_1-@3x.png"

import bgOver600_3 from "static/images/onboarding/intro/skila-bg_img-01-3-nad_600px_width-0_1.png"

import bg414x300_3 from "static/images/onboarding/intro/skila-bg_img-01-3-pod_600px_width-0_1-@1x.png"
import bg414x300x2_3 from "static/images/onboarding/intro/skila-bg_img-01-3-pod_600px_width-0_1-@2x.png"
import bg414x300x3_3 from "static/images/onboarding/intro/skila-bg_img-01-3-pod_600px_width-0_1-@3x.png"

import bgOver600_4 from "static/images/onboarding/intro/skila-bg_img-01-4-nad_600px_width-0_1.png"

import bg414x300_4 from "static/images/onboarding/intro/skila-bg_img-01-4-pod_600px_width-0_1-@1x.png"
import bg414x300x2_4 from "static/images/onboarding/intro/skila-bg_img-01-4-pod_600px_width-0_1-@2x.png"
import bg414x300x3_4 from "static/images/onboarding/intro/skila-bg_img-01-4-pod_600px_width-0_1-@3x.png"



const steps = {
  1: {
    header: "Make your work life easier.",
    content:
      "Skila is a collaborative workspace, where you can use other people’s experiences to improve your specific work assignment. Tap into other people’s experiences to get your work done better and faster.",
    adjPaths: {
      forward: {
        pathname: "/onboard/intro/2",
        animAxis: "horizontal",
      },
    },
  },
  2: {
    header: "Sharing skills makes sense.",
    content:
      "Reciprocate! Share your experience by helping others improve their work. The more you share, the more recognition you get.",
    adjPaths: {
      back: {
        pathname: "/onboard/intro/1",
        animAxis: "horizontal",
      },
      forward: {
        pathname: "/onboard/intro/3",
        animAxis: "horizontal",
      },
    },
  },
  3: {
    header: "Why Skila?",
    content: (
      <ul>
        <li>Get help from the experts without interrupting your work process</li>
        <li>
          Spend less time solving the work assignments on your own and learn from the real sources – other professionals from your organization
        </li>
        <li>
          Build your competence profile by sharing your expertize with others and receiving verified reviews
        </li>
        <li>
          Enhance your professional growth by working with top talents in your field
        </li>
      </ul>
    ),
    adjPaths: {
      back: {
        pathname: "/onboard/intro/2",
        animAxis: "horizontal",
      },
      forward: {
        pathname: "/onboard/intro/4",
        animAxis: "horizontal",
      },
    },
  },
  4: {
    header:
      "The knowledge and experience, shared effortlessly. From people to people.",
    content:
      "Stuck in a job assignment? Ask Skila any work related question and connect with the right people in your organization. Easy peasy.",
    adjPaths: {
      back: {
        pathname: "/onboard/intro/3",
        animAxis: "horizontal",
      },
      forward: {
        pathname: "/onboard/registration",
        animAxis: "horizontal",
      },
    },
  },
}

const bgImages = {
  1: {
      nonRetina: {
          600: bgOver600_1,
          414: bg414x300_1,
          320: bg414x300_1,
      },
      retinaX2: {
          600: bgOver600_1,
          414: bg414x300x2_1,
          320: bg414x300x2_1,
      },
      retinaX3: {
          600: bgOver600_1,
          414: bg414x300x3_1,
          320: bg414x300x3_1,
      },
  },
  2: {
    nonRetina: {
        600: bgOver600_2,
        414: bg414x300_2,
        320: bg414x300_2,
    },
    retinaX2: {
        600: bgOver600_2,
        414: bg414x300x2_2,
        320: bg414x300x2_2,
    },
    retinaX3: {
        600: bgOver600_2,
        414: bg414x300x3_2,
        320: bg414x300x3_2,
    },
  },
  3: {
    nonRetina: {
        600: bgOver600_3,
        414: bg414x300_3,
        320: bg414x300_3,
    },
    retinaX2: {
        600: bgOver600_3,
        414: bg414x300x2_3,
        320: bg414x300x2_3,
    },
    retinaX3: {
        600: bgOver600_3,
        414: bg414x300x3_3,
        320: bg414x300x3_3,
    },
  },
  4: {
    nonRetina: {
        600: bgOver600_4,
        414: bg414x300_4,
        320: bg414x300_4,
    },
    retinaX2: {
        600: bgOver600_4,
        414: bg414x300x2_4,
        320: bg414x300x2_4,
    },
    retinaX3: {
        600: bgOver600_4,
        414: bg414x300x3_4,
        320: bg414x300x3_4,
    },
  }
}

const Intro = observer(props => {
  const { userStore } = useStore()
  const { retina, screenWidth } = userStore

  const stepKey = Number(props.match.params.step)
  const step = stepKey ? steps[stepKey] : steps[1]

  const [bgImage, setBgImage] = useState(null)

  useEffect(() => {
    let width = 320
    let dpi = "retinaX2"
    if (screenWidth > 320) width = 414
    if (screenWidth > 600) width = 600
    if (!retina) dpi = "nonRetina"
    setBgImage(bgImages[stepKey][dpi][width])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth, retina])

  return (
    <div className="intro container-onboard">
      <div className="header">
        {stepKey !== 4 && (
          <div className="nav-link-intro link-text-two">
            <NavLink
              navDirection={"forward"}
              pathname={"/onboard/registration"}
              animAxis={"horizontal"}
              animContainer={"screen"}
            >
              close
            </NavLink>
          </div>
        )}
        <img src={bgImage} className="intro-bg-image" alt="" />
        <NavBar back={step.adjPaths.back} forward={step.adjPaths.forward} />
      </div>
      <div className="body body-intro">
        <div className="heading heading-two">{step.header}</div>
        <div className="paragraph paragraph-two">{step.content}</div>
        {parseInt(stepKey) === 4 && (
          <div className="cta-primary">
            <NavLink
              navDirection={"forward"}
              pathname={step.adjPaths.forward.pathname}
              animAxis={step.adjPaths.forward.animAxis}
            >
              Let's go
            </NavLink>
          </div>
        )}
      </div>
    </div>
  )
})

export default React.memo(Intro)
