import axios from "axios"
import config from "config"

const { protocol, host, port, path } = config.api

const baseURL =
  host === "localhost"
    ? `${protocol}://${host}:${port}/${path}/`
    : `${protocol}://${host}/${path}/`

export const API = axios.create({ baseURL })

// Add an interceptor to our API instance that attaches the
// access token as a header to every request if the token is present
API.interceptors.request.use(config => {
  const jwt = localStorage.getItem("jwt_access")
  const jwtWithoutQuotes = jwt ? jwt.replace(/^"(.*)"$/, "$1") : ""

  return {
    ...config,
    headers: !jwt
      ? config.headers
      : {
          ...config.headers,
          Authorization: `Bearer ${jwtWithoutQuotes}`,
        },
  }
})

// ****************************
//  Global API endpoints
// ****************************

export const searchSkills = string => API.get(`/skills/?search=${string}`)

export const searchJobTitles = string =>
  API.get(`/job-titles/?search=${string}`)

export const fetchRelatedSkills = skillID => API.get(`/skills/${skillID}/`)

export const createSupportTicket = (number, inviteToken) =>
  API.post(`/support/`, { phone_number: number, invite_token: inviteToken })

export const createIssueReport = issue =>
  API.post(`/feedback/`, { text: issue })

// ****************************
//  User related API endpoints
// ****************************

export const fetchAuthCode = (phone_number, invite_token) => {
  let requestData = invite_token
    ? { phone_number, invite_token }
    : { phone_number }
  return API.post(`/send-code/`, requestData)
}

export const login = (phone_number, code, invite_token) => {
  let requestData = invite_token
    ? { phone_number, code, invite_token }
    : { phone_number, code }
  return API.post(`/login/`, requestData)
}

export const refreshExpiredJwt = refresh =>
  API.post(`/token/refresh/`, { refresh })

export const fileUpload = file => {
  const formData = new FormData()
  formData.append("avatar", file)
  return API.put(`/me/avatar/`, formData, {
    headers: { "content-type": "multipart/form-data" },
  })
}

export const fetchUser = () => API.get(`/me/`)

export const updateUser = (firstName, lastName) =>
  API.put(`/me/`, {
    first_name: firstName,
    last_name: lastName,
  })

export const updateUsersPrimaryCircle = circleID =>
  API.put(`/me/`, { primary_circle_id: circleID })

export const fetchUserSkills = () => API.get(`/me/skills/`)

export const updateUserSkills = skills => API.put(`/me/skills/`, skills)

export const deleteUserSkills = skills =>
  API.delete(`/me/skills/`, { data: skills })

// *****************************
//  Circle related API endpoints
// *****************************

export const fetchCircleProfile = circleID =>
  API.get(`/me/circles/${circleID}/`)

export const updateCircleProfile = (circleID, profile) =>
  API.put(`/me/circles/${circleID}/`, profile)

export const fetchCircleSkills = circleID =>
  API.get(`/me/circles/${circleID}/skills/`)

export const updateCircleSkills = (skills, circleID) =>
  API.put(`/me/circles/${circleID}/skills/`, skills)

export const createCircleJobTitle = (jobTitle, jobTitleID, circleID) =>
  API.post(`/me/circles/${circleID}/jobs/`, {
    name: jobTitle,
    id: jobTitleID,
  })

export const updateCircleJobTitle = (job, circleID) =>
  API.put(`/me/circles/${circleID}/jobs/${job.id}/`, {
    is_visible: job.is_visible,
    is_finished: job.is_finished,
  })

// *****************************
//  Ticket related API endpoints
// *****************************

export const createTicket = (title, description, deadline, skills, circleID) =>
  API.post(`/me/tickets/`, {
    title: title,
    short_description: description,
    deadline: deadline,
    skills: skills,
    circle_id: circleID,
  })

export const updateTicket = (
  ticketID,
  title,
  description,
  deadline,
  skills,
  circleID
) =>
  API.put(`/me/tickets/${ticketID}/`, {
    title: title,
    short_description: description,
    deadline: deadline,
    skills: skills,
    circle_id: circleID,
  })

export const fetchOwnTickets = () => API.get(`/me/tickets/`)

export const fetchTicketConnections = ticket_id =>
  API.get(`/me/tickets/${ticket_id}/connections/`)

// [ open, paused, deleted, done ]
export const updateTicketStatus = (ticketID, status) =>
  API.put(`/me/tickets/${ticketID}/status/`, { status })

export const fetchMatchedTickets = () => API.get(`/me/assign-tickets/`)

export const fetchDeclineReasons = () => API.get(`/decline-reasons/`)

// accept || decline
export const postMatchedTicketResponse = (
  ticketID,
  status,
  decline_reason_id,
  communication
) => {
  let requestData = decline_reason_id
    ? { status, decline_reason_id }
    : { status, communication }
  return API.put(`/me/assign-tickets/${ticketID}/status/`, requestData)
}

export const postEvaluationConnectionSkills = (
  ticketID,
  circleUserID,
  skillScores
) => {
  return API.put(
    `/me/tickets/${ticketID}/connections/${circleUserID}/`,
    skillScores
  )
}

export const postEvaluationConnectionRating = (
  ticketID,
  circleUserID,
  rating
) => API.put(`/me/tickets/${ticketID}/connections/${circleUserID}/rating/`, { score: rating })


export const postAssignedTicketEvaluation = (
  ticketID,
  time_spend,
  difficulty
) => API.put(`/me/assign-tickets/${ticketID}/`, { time_spend, difficulty })

// *****************************
//  TEST related API endpoints
// *****************************

// export const testConnectionStatus = ticket_id =>
// API.get(`/me/tickets/${ticket_id}/connections/`)
