import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from '@sentry/browser'

import * as serviceWorker from "./serviceWorker"

import App from "./App"

Sentry.init({dsn: "https://edf8f885da624d47b54adda5579038c0@sentry.io/1528390"});

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// serviceWorker.unregister()
serviceWorker.register()
