import React, { useEffect } from "react"
// import React from "react"

// import NavLink from "components/NavLink"
import { useStore } from "data/store/RootStore"
import { createSupportTicket } from "data/api"

const Support = ({ history }) => {
  // const screenName = "Support"

  const { authStore } = useStore()
  const { phoneNumber, circleInviteToken } = authStore

  const postSupportTicket = async () => {
    const number = phoneNumber ? phoneNumber : ""
    const token = circleInviteToken ? circleInviteToken : "login_issue"
    await createSupportTicket(number, token)
  }

  useEffect(() => {
    postSupportTicket()
    setTimeout(() => {
      history.push({
        pathname: "/onboard/",
        state: {
          navDirection: "forward",
          animAxis: "vertical",
          animContainer: "screen",
        },
      })
    }, 15000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="support container-onboard">
      <div className="body">
        <div className="heading heading-three">Support</div>
        <div className="paragraph paragraph-three">
          We've received your message, sent from{" "}
          <span className="highlight">+{phoneNumber}</span>. And we're on it.
          You will soon receive further instructions on your email.
        </div>
        <div className="paragraph paragraph-three">Skilfully yours,</div>
        <div className="paragraph paragraph-three">Skila team.</div>
      </div>
    </div>
  )
}

export default React.memo(Support)
