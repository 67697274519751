import React, { useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import NavLink from "components/NavLink"

const EditProfile = observer(props => {
  // const screenName = "Profile"

  const { userStore } = useStore()
  const { updateUser, firstName: first, lastName: last } = userStore

  const [firstName, setFirstName] = useState(first)
  const [lastName, setLastName] = useState(last)

  const handleSubmitForm = async e => {
    e.preventDefault()
    await updateUser(firstName, lastName)
    props.history.push({
      pathname: "/profile",
      state: {
        navDirection: "forward",
        animAxis: "horizontal",
        animContainer: "screen",
      },
    })
  }

  return (
    <div className="edit-profile container-onboard">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            navDirection={"back"}
            pathname={"/profile"}
            animAxis={"horizontal"}
          >
            cancel
          </NavLink>
        </div>
      </div>
      <div className="body">
        <form onSubmit={handleSubmitForm}>
          <input
            className="input-field"
            type="text"
            value={firstName}
            placeholder="First name"
            onChange={e => setFirstName(e.target.value)}
          />
          <input
            className="input-field"
            type="text"
            value={lastName}
            placeholder="Last name"
            onChange={e => setLastName(e.target.value)}
          />
          <input
            className="cta cta-secondary-light"
            type="submit"
            value="Update"
          />
        </form>
      </div>
    </div>
  )
})

export default React.memo(EditProfile)
