import React, { useState, useRef, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { debounce } from "lodash"

import { searchSkills, fetchRelatedSkills } from "data/api"

import { useStore } from "data/store/RootStore"

import classNames from "classnames"

import NavLink from "components/NavLink"

const AddSkills = observer(({ history }) => {
  // const screenName = "AddSkills"

  const { userStore } = useStore()
  const { skills, fetchUserSkills, updateUserSkills } = userStore

  const [matchingSkills, setMatchingSkills] = useState([])
  const [recommendedSkills, setRecommendedSkills] = useState([])

  const [selectedSkills, setSelectedSkills] = useState([])

  const scrollableContainerRef = useRef(null)
  const searchInputRef = useRef(null)

  const handleSubmitForm = async () => {
    await updateUserSkills(selectedSkills)
    history.push({
      pathname: "/dashboard/edit_skills",
      state: {
        navDirection: "back",
        animAxis: "vertical",
        animContainer: "screen",
      },
    })
  }

  useEffect(() => {
    fetchUserSkills()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSkillSearchUpdate = debounce(async searchString => {
    // const searchInput = searchInputRef.current
    if (searchString.length >= 3) {
      try {
        // Remove focus from search input so the mobile on-screen keyboard
        // goes away and we can actually see the search results
        // searchString.length > 2 && searchInput.blur()
        const res = await searchSkills(searchString)
        setMatchingSkills(res.data)
      } catch (error) {
        console.error(error)
      }
    } else {
      setMatchingSkills([])
    }
  }, 300)

  // NOTE: header shrinks when user scrolls (down or in general)?
  const handleScroll = e => {
    // console.log(e)
  }

  const addSkill = async id => {
    // const scrollableContainer = scrollableContainerRef.current
    const skill = [...matchingSkills, ...recommendedSkills].filter(
      skill => skill.id === id
    )[0]
    const isDuplicate = selectedSkills.filter(skill => skill.id === id)[0]
    if (!isDuplicate && skill) {
      setSelectedSkills([...selectedSkills, { ...skill, isVisible: true }])
      setMatchingSkills(matchingSkills.filter(skill => skill.id !== id))
    }
    const relatedRes = await fetchRelatedSkills(id)
    setRecommendedSkills(relatedRes.data)
    // scrollableContainer.scrollTo(0, scrollableContainer.scrollHeight)
  }

  const removeSkill = id => {
    const remainingSkills = selectedSkills.filter(skill => skill.id !== id)
    setSelectedSkills([...remainingSkills])
  }

  return (
    <div className="add-skills-dashboard container-onboard">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            navDirection={"forward"}
            pathname={"/dashboard/edit_skills"}
            animAxis={"vertical"}
            animContainer={"screen"}
          >
            cancel
          </NavLink>
        </div>
        <div className="heading heading-four">Add new skills</div>
        <div className="section-search-input">
          <input
            className="input-field"
            type="text"
            placeholder=""
            onChange={e => handleSkillSearchUpdate(e.target.value)}
            ref={searchInputRef}
          />
        </div>
      </div>
      <div
        className="body scrollable"
        ref={scrollableContainerRef}
        onScroll={e => handleScroll(e)}
      >
        <div className="section-search-results">
          <div className="uppercase-label-bold field-label">common skills</div>
          <div className="tags-container">
            {matchingSkills.map(skill => {
              let isSelected = Object.values(selectedSkills).filter(
                ({ id }) => id === skill.id
              )[0]

              let isOwn = skills.filter(({ id }) => id === skill.id)[0]
              const unselectable = isSelected || isOwn

              return (
                <span
                  key={skill.id}
                  className={unselectable ? `tag-active` : `tag-regular`}
                  onClick={() => (unselectable ? () => {} : addSkill(skill.id))}
                >
                  {skill.name}
                </span>
              )
            })}
          </div>
        </div>
        {recommendedSkills.length !== 0 && (
          <div className="section-search-recommended">
            <form onSubmit={handleSubmitForm}>
              <div className="uppercase-label-bold field-label">
                related skills
              </div>
              <div className="tags-container">
                {recommendedSkills.map(skill => {
                  let isSelected = Object.values(selectedSkills).filter(
                    ({ id }) => id === skill.id
                  )[0]

                  let isOwn = skills.filter(({ id }) => id === skill.id)[0]
                  const unselectable = isSelected || isOwn

                  return (
                    <span
                      key={skill.id}
                      className={unselectable ? `tag-active` : `tag-regular`}
                      onClick={() =>
                        unselectable ? () => {} : addSkill(skill.id)
                      }
                    >
                      {skill.name}
                    </span>
                  )
                })}
              </div>
            </form>
          </div>
        )}
        <div className="section-search-selected">
          <form onSubmit={handleSubmitForm}>
            <div className="uppercase-label-bold field-label">added skills</div>
            <div className="tags-container">
              {selectedSkills.map(skill => {
                return (
                  <span
                    key={skill.id}
                    className="tag-secondary"
                    onClick={() => removeSkill(skill.id)}
                  >
                    {skill.name}
                  </span>
                )
              })}
            </div>
          </form>
        </div>
        <div className="footer">
          <div
            className={classNames({
              cta: true,
              "cta-secondary-dark": true,
              disabled: !selectedSkills.length,
            })}
            onClick={selectedSkills.length ? handleSubmitForm : () => {}}
          >
            Add
          </div>
        </div>
      </div>
    </div>
  )
})

export default React.memo(AddSkills)
