import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { Route, Switch } from "react-router-dom"
import { TransitionGroup, Transition } from "react-transition-group"

import classNames from "classnames"

import BodyContainer from "components/BodyContainer"
import UserRating from "./UserRating"

import NavLink from "components/NavLink"

import { ReactComponent as StarIcon } from "static/svgs/icon-star.svg"
import { ReactComponent as CrossIcon } from "static/svgs/icon-cross.svg"
import { ReactComponent as CheckIcon } from "static/svgs/icon-check.svg"

const EvaluateUser = observer(({ history, match }) => {
  // const screenName = "EvaluateUser"
  const ticketID = Number(match.params.ticket_id)

  const { routerStore, ownTicketStore } = useStore()
  const { location, lastParamFromURL: activeConnectionID } = routerStore
  const {
    myTickets,
    evaluatingConnectionIDs,
    connectionsSkillScore,
    setConnectionsSkillScore,
    evaluateConnectionSkills,
    connectionsHelpRating,
    setConnectionsHelpRating,
    evaluateConnectionHelp,
    isUsersFirstEvaluation,
  } = ownTicketStore

  const [modalActive, setModalActive] = useState(isUsersFirstEvaluation)

  const [ticket, setTicket] = useState({ connections: [], skills: [] })

  useEffect(() => {
    setTicket(myTickets.filter(({ id }) => id === ticketID)[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTickets])

  const updateSkillScore = connectionID => (skillID, score) => {
    setConnectionsSkillScore({
      ...connectionsSkillScore,
      [connectionID]: {
        ...connectionsSkillScore[connectionID],
        skills: {
          ...connectionsSkillScore[connectionID].skills,
          [skillID]: {
            ...connectionsSkillScore[connectionID].skills[skillID],
            score: score,
          },
        },
      },
    })
  }

  const updateHelpScore = connectionID => score => {
    setConnectionsHelpRating({
      ...connectionsHelpRating,
      [connectionID]: score,
    })
  }

  const handleSubmitForm = async () => {
    // eslint-disable-next-line
    Object.entries(connectionsHelpRating).map(([connectionId, rating]) => {
      evaluateConnectionHelp(ticketID, connectionId, rating);
    })

    // eslint-disable-next-line
    Object.values(connectionsSkillScore).map(connection => {
      let skills = Object.values(connection.skills)
        .filter(({ score }) => score !== "disable")
        .map(skill => ({
          skill_id: Number(skill.id),
          score: Number(skill.score),
        }))
      evaluateConnectionSkills(ticketID, connection.id, skills)
    })

    history.push({
      pathname: "/need_help/evaluate/thank_you",
      state: {
        navDirection: "forward",
        animAxis: "horizontal",
        animContainer: "screen",
      },
    })
  }

  const isFormValid = () => {
    const allScores = Object.values(connectionsSkillScore)
      .filter(
        connection =>
          evaluatingConnectionIDs.filter(id => id === connection.id)[0]
      )
      .reduce(
        (scores, connection) => [
          ...scores,
          ...Object.values(connection.skills).map(skill => skill.score),
        ],
        []
      )

    const connectionsCount = Object.values(connectionsSkillScore).length
    const numberOfRatedConnections = Object.values(connectionsHelpRating).length
    const allRated = connectionsCount === numberOfRatedConnections

    return !allScores.includes(0) && allRated;
  }

  const handleSlide = e => {
    const tab = e.currentTarget
    tab.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" })
  }

  return (
    <div className="evaluate-user container-onboard">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            pathname={`/need_help/evaluate/${ticketID}`}
            navDirection={"back"}
            animAxis={"vertical"}
            animContainer={"screen"}
          >
            close
          </NavLink>
        </div>
        <div className="connections-list-header">
          {ticket &&
            ticket.connections
              .filter(connection =>
                evaluatingConnectionIDs.includes(connection.id)
              )
              .map(connection => (
                <div key={connection.id} onClick={handleSlide}>
                  <NavLink
                    pathname={`/need_help/evaluate/${ticketID}/connections/${
                      connection.id
                    }`}
                    navDirection={"forward"}
                    animAxis={"horizontal"}
                    animContainer={"body"}
                  >
                    <div
                      className={classNames({
                        connection: true,
                        active: connection.id === Number(activeConnectionID),
                      })}
                    >
                      <div className="avatar-wrapper">
                        <div className="avatar-image-container">
                          <img
                            className="avatar-image"
                            src={connection.avatar}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="info-container">
                        <div className="name">{connection.name}</div>
                        <div className="circle">@ {connection.circle}</div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))}
        </div>
        <div className="current-connection">
          {evaluatingConnectionIDs.indexOf(Number(activeConnectionID)) + 1} /{" "}
          {evaluatingConnectionIDs.length}
        </div>
      </div>
      <TransitionGroup className="transition-group">
        <Transition
          key={activeConnectionID}
          appear={true}
          timeout={{
            appear: 0,
            enter: 0,
            exit: 600,
          }}
        >
          {state => (
            <Switch location={location}>
              {evaluatingConnectionIDs.map(connectionID => (
                <Route
                  path={`/need_help/evaluate/${ticketID}/connections/${connectionID}`}
                  key={connectionID}
                  render={() => (
                    <BodyContainer transitionState={state}>
                      <UserRating
                        ticketID={ticket.id}
                        connection={connectionsSkillScore[connectionID]}
                        connectionID={connectionID}
                        updateSkillScore={updateSkillScore(connectionID)}
                        updateHelpRating={updateHelpScore(connectionID)}
                        helpRating={connectionsHelpRating[connectionID]}                        
                      />
                    </BodyContainer>
                  )}
                />
              ))}
            </Switch>
          )}
        </Transition>
      </TransitionGroup>
      <div className="footer">
        <div
          className={classNames({
            "cta-secondary-dark": true,
            disabled: !isFormValid(),
          })}
          onClick={isFormValid() ? handleSubmitForm : () => {}}
        >
          Done
        </div>
      </div>

      {modalActive && (
        <div className="modal-container">
          <div className="modal-close" onClick={() => setModalActive(false)}>
            ok, let's start
          </div>
          <div className="modal-wrapper">
            <div className="evaluation-tips">
              <div className=" uppercase-label-bold field-label">
                what do the rating mean?
              </div>
              {/* ----------------------- */}
              <div className="grade-text">Not yet rated</div>
              <div className="grade">
                {Array.apply(null, Array(5)).map((nada, i) => (
                  <StarIcon
                    key={i}
                    className={classNames({
                      "icon-star": true,
                      fill: false,
                      fade: false,
                    })}
                  />
                ))}
              </div>
              {/* ----------------------- */}
              <div className="grade-text">
                Familiar but no hands on experience.
              </div>
              <div className="grade">
                {Array.apply(null, Array(5)).map((nada, i) => (
                  <StarIcon
                    key={i}
                    className={classNames({
                      "icon-star": true,
                      fill: i < 1,
                      fade: i > 0,
                    })}
                  />
                ))}
              </div>
              {/* ----------------------- */}
              <div className="grade-text">Little hands on experience.</div>
              <div className="grade">
                {Array.apply(null, Array(5)).map((nada, i) => (
                  <StarIcon
                    key={i}
                    className={classNames({
                      "icon-star": true,
                      fill: i < 2,
                      fade: i > 1,
                    })}
                  />
                ))}
              </div>
              {/* ----------------------- */}
              <div className="grade-text">Good hands on experience.</div>
              <div className="grade">
                {Array.apply(null, Array(5)).map((nada, i) => (
                  <StarIcon
                    key={i}
                    className={classNames({
                      "icon-star": true,
                      fill: i < 3,
                      fade: i > 2,
                    })}
                  />
                ))}
              </div>
              {/* ----------------------- */}
              <div className="grade-text">Extensive hands on experience.</div>
              <div className="grade">
                {Array.apply(null, Array(5)).map((nada, i) => (
                  <StarIcon
                    key={i}
                    className={classNames({
                      "icon-star": true,
                      fill: i < 4,
                      fade: i > 3,
                    })}
                  />
                ))}
              </div>
              {/* ----------------------- */}
              <div className="grade-text">
                In depth understanding and experience.
              </div>
              <div className="grade">
                {Array.apply(null, Array(5)).map((nada, i) => (
                  <StarIcon
                    key={i}
                    className={classNames({
                      "icon-star": true,
                      fill: i < 5,
                      fade: false,
                    })}
                  />
                ))}
              </div>
              {/* ----------------------- */}
              <div className="actions">
                <div className="action">
                  <div className="icon-container">
                    <CrossIcon />
                  </div>
                  <span>I will not evaluate this skill.</span>
                </div>
                <div className="action">
                  <div className="icon-container">
                    <CheckIcon />
                  </div>
                  <span>Enable</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
})

export default React.memo(EvaluateUser)
