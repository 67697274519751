import React from "react"
import { useStore } from "../data/store/RootStore"
import { observer } from "mobx-react-lite"

const ScreenContainer = observer(props => {
  const { transitionState, children } = props
  const { routerStore } = useStore()
  const { navDirection, animAxis, animContainer } = routerStore

  // useEffect(() => {
  //   console.log(transitionState)
  // }, [transitionState])

  const animClassName =
    animContainer === "screen"
      ? `${transitionState}-${navDirection}-${animAxis}
  ${navDirection}-${animAxis}`
      : ""

  return (
    <div
      className={`screen-transition-container
        ${animClassName}`}
    >
      {children}
    </div>
  )
})

export default React.memo(ScreenContainer)
