import React, { useEffect, useState } from "react"
import { useStore } from "../data/store/RootStore"
import { observer } from "mobx-react-lite"

import Badge from "components/Badge"

const BadgesGridHeader = observer(({ wait, location }) => {
  const { routerStore, userStore } = useStore()
  const { history } = routerStore
  const { badges } = userStore

  const [activeBadges, setActiveBadges] = useState([])

  const width = 31

  useEffect(() => {
    setActiveBadges(badges.filter(badge => badge.level > 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badges])

  const handleRedirect = e => {
    e.preventDefault()
    e.stopPropagation()
    history.push({
      pathname: "/profile/badges",
      state: {
        navDirection: "forward",
        animAxis: "vertical",
        animContainer: "screen",
      },
    })
  }

  return (
    <div
      className="badges-grid-header"
      onClick={e => {
        handleRedirect(e)
      }}
    >
      <div className="column-4">
        {activeBadges
          .filter((_badge, i) => i === 9)
          .map(badge => (
            <Badge
              key={badge.action}
              id={badge.action}
              level={badge.level}
              width={width}
            />
          ))}
      </div>
      <div className="column-3">
        {activeBadges
          .filter((_badge, i) => i > 5 && i <= 8)
          .map(badge => (
            <Badge
              key={badge.action}
              id={badge.action}
              level={badge.level}
              width={width}
            />
          ))}
      </div>
      <div className="column-2">
        {activeBadges
          .filter((_badge, i) => i > 2 && i <= 5)
          .map(badge => (
            <Badge
              key={badge.action}
              id={badge.action}
              level={badge.level}
              width={width}
            />
          ))}
      </div>
      <div className="column-1">
        {activeBadges
          .filter((_badge, i) => i <= 2)
          .map(badge => (
            <Badge
              key={badge.action}
              id={badge.action}
              level={badge.level}
              width={width}
            />
          ))}
      </div>
    </div>
  )
})

export default React.memo(BadgesGridHeader)
