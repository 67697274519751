import React, { useEffect } from "react"

import { useStore } from "../data/store/RootStore"
import { observer } from "mobx-react-lite"

import "normalize.css" // TODO: move to 7-1

const Layout = observer(props => {
  const { userStore } = useStore()
  const { setScreenWidth } = userStore

  useEffect(() => {
    window.addEventListener("resize", setScreenWidth)
    return () => window.removeEventListener("resize", setScreenWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className={"Layout"}>{props.children}</div>
})

export default Layout
