import React, { useState, useEffect } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import NavLink from "components/NavLink"

import { ReactComponent as HiddenIcon } from "static/svgs/icon-hidden.svg"
import { ReactComponent as VisibleIcon } from "static/svgs/icon-visible.svg"

const ShareSkills = observer(({ history }) => {
  // const screenName = "ShareSkills"

  const { userStore } = useStore()
  const {
    circleProfiles,
    onboardCircleID,
    skills,
    fetchUserSkills,
    updateCircleSkills,
  } = userStore

  const [circle, setCircle] = useState({ name: "", logo: "" })
  const [circleSkills, setCircleSkills] = useState(skills)

  useEffect(() => {
    onboardCircleID && setCircle(circleProfiles[onboardCircleID].circle)
    circleProfiles[1] && setCircle(circleProfiles[1].circle)
  }, [circleProfiles, onboardCircleID])

  useEffect(() => {
    const fetch = async () => {
      const skills = await fetchUserSkills()
      setCircleSkills(skills)
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmitForm = onboardCircleID => async () => {
    await updateCircleSkills(circleSkills, onboardCircleID)
    history.push({
      pathname: "/onboard/add_job_title",
      state: {
        navDirection: "forward",
        animAxis: "horizontal",
        animContainer: "screen",
      },
    })
  }

  const toggleCircleSkillVisibility = id => {
    setCircleSkills(
      circleSkills.map(skill => ({
        ...skill,
        is_visible: skill.id === id ? !skill.is_visible : skill.is_visible,
      }))
    )
  }

  return (
    <div className="share-skills container-onboard">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            pathname={"/onboard/add_skills"}
            navDirection={"back"}
            animAxis={"horizontal"}
            animContainer={"screen"}
          >
            back
          </NavLink>
        </div>
        <div className="steps-container">
          <div className="step-marker" />
          <div className="step-marker" />
          <div className="step-marker active" />
          <div className="step-marker" />
        </div>
        <div className="heading heading-four">
          Choose the skills you would like to share with your organization.
        </div>
      </div>
      <div className="body scrollable">
        <div className="circle-section">
          <div className="logo-container single">
            <img src={circle.logo} alt="" />
          </div>
          <div className="paragraph paragraph-two circle-name">
            You've been connected with {circle.name} through the magiclink.
          </div>
        </div>
        <div className="field-label uppercase-label-bold">
          show or hide skills
        </div>
        <div className="tags-list">
          {circleSkills.map((skill, i) => (
            <div
              key={i}
              className="tags-list-row"
              onClick={() => toggleCircleSkillVisibility(skill.id)}
            >
              <div className="icon-container">
                {skill.is_visible ? (
                  <VisibleIcon className="icon icon-visible" />
                ) : (
                  <HiddenIcon className="icon icon-hidden" />
                )}
              </div>
              <span
                className={skill.is_visible ? "tag-regular" : "tag-secondary"}
              >
                <span>{skill.name}</span>
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="footer">
        <div
          className="cta-primary"
          onClick={handleSubmitForm(onboardCircleID)}
        >
          Next
        </div>
      </div>
    </div>
  )
})

export default React.memo(ShareSkills)
