import React from "react"

import NavLink from "components/NavLink"

import woohooImage from "static/images/woohoo.png"

const ConfirmationOk = props => {
  // const screenName = "ConfirmationOK"

  return (
    <div className="confirmation-ok container-onboard">
      <div className="header">
        <div className="heading heading-three">Woo hoo!</div>
        <div className="paragraph paragraph-two">
          Take me down to the Skila city.
        </div>
      </div>
      <div className="body">
        <img src={woohooImage} alt="circle" className="woohoo-image" />
      </div>
      <div className="footer">
        <div className="cta-primary">
          <NavLink
            navDirection={"forward"}
            pathname={"/onboard/help/1"}
            animAxis={"horizontal"}
            animContainer={"screen"}
          >
            Let's go
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ConfirmationOk)
