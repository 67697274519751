import React, { useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import classNames from "classnames"

const AddBasicInfo = observer(({ history }) => {
  // const screenName = "AddBasicInfo"

  const { userStore } = useStore()
  const { firstName: first, lastName: last, updateUser } = userStore

  const [firstName, setFirstName] = useState(first)
  const [lastName, setLastName] = useState(last)

  const [inputValid, setInputValid] = useState({
    firstName: firstName ? true : false,
    lastName: lastName ? true : false,
  })

  const handleSubmitForm = async e => {
    e.preventDefault()
    await updateUser(firstName, lastName)
    history.push({
      pathname: "/onboard/add_skills",
      state: {
        navDirection: "forward",
        animAxis: "horizontal",
        animContainer: "screen",
      },
    })
  }

  const removePlaceholder = e => (e.target.placeholder = "")

  return (
    <div className="add-basic-info container-onboard">
      <div className="header">
        <div className="steps-container">
          <div className="step-marker active" />
          <div className="step-marker" />
          <div className="step-marker" />
          <div className="step-marker" />
        </div>
        <div className="heading heading-four">Hello, there!</div>
      </div>
      <div className="body">
        <div className="paragraph paragraph-two">
          Please enter your full name so that others can identify you.
        </div>
        <form id="add-basic-info-form" onSubmit={handleSubmitForm}>
          <input
            className="input-field"
            type="text"
            value={firstName}
            placeholder="First name"
            onChange={e => {
              setFirstName(e.target.value)
              e.target.value.length > 0
                ? setInputValid({ ...inputValid, firstName: true })
                : setInputValid({ ...inputValid, firstName: false })
            }}
            onFocus={removePlaceholder}
            required
          />
          <input
            className="input-field"
            type="text"
            value={lastName}
            placeholder="Last name"
            onChange={e => {
              setLastName(e.target.value)
              e.target.value.length > 0
                ? setInputValid({ ...inputValid, lastName: true })
                : setInputValid({ ...inputValid, lastName: false })
            }}
            onFocus={removePlaceholder}
            required
          />
        </form>
      </div>
      <div className="footer">
        <input
          className={classNames({
            "cta-primary": true,
            disabled: !inputValid.firstName || !inputValid.lastName,
          })}
          type="submit"
          value="Next"
          form="add-basic-info-form"
        />
      </div>
    </div>
  )
})

export default React.memo(AddBasicInfo)
