import React, { useRef } from "react"

function getClientPosition(e) {
  var touches = e.touches

  if (touches && touches.length) {
    var finger = touches[0]
    return { x: finger.clientX }
  }
  return { x: e.clientX }
}

const RangeSlider = props => {
  const x = props.x
  const xmin = 0
  const xmax = 100
  const xstep = 1
  const onChange = props.onChange
  const displayValue = props.displayValue

  var container = useRef(null)
  var handle = useRef(null)

  var start = useRef({})
  var offset = useRef({})

  function getPosition() {
    var left = ((x - xmin) / (xmax - xmin)) * 100
    if (left > 100) left = 100
    if (left < 0) left = 0
    left += "%"
    return {
      top: "0%",
      left: left,
    }
  }

  function change(_ref2) {
    var left = _ref2.left
    if (!onChange) return
    var _container$current$ge = container.current.getBoundingClientRect()
    var width = _container$current$ge.width
    var dx = 0
    if (left < 0) left = 0
    if (left > width) left = width
    dx = (left / width) * (xmax - xmin)
    var x = (dx !== 0 ? parseInt(dx / xstep, 10) * xstep : 0) + xmin
    onChange({ x: x })
  }

  function handleMouseDown(e) {
    // e.preventDefault()
    var dom = handle.current
    var clientPos = getClientPosition(e)
    start.current = { x: dom.offsetLeft }
    offset.current = { x: clientPos.x }
    document.addEventListener("mousemove", handleDrag)
    document.addEventListener("mouseup", handleDragEnd)
    document.addEventListener("touchmove", handleDrag, {
      passive: false,
    })
    document.addEventListener("touchend", handleDragEnd)
    document.addEventListener("touchcancel", handleDragEnd)
  }

  function getPos(e) {
    var clientPos = getClientPosition(e)
    var left = clientPos.x + start.current.x - offset.current.x
    return { left: left }
  }

  function handleDrag(e) {
    // e.preventDefault()
    change(getPos(e))
  }

  function handleDragEnd(e) {
    // e.preventDefault()
    document.removeEventListener("mousemove", handleDrag)
    document.removeEventListener("mouseup", handleDragEnd)
    document.removeEventListener("touchmove", handleDrag, { passive: false })
    document.removeEventListener("touchend", handleDragEnd)
    document.removeEventListener("touchcancel", handleDragEnd)
  }

  function handleClick(e) {
    var clientPos = getClientPosition(e)
    var rect = container.current.getBoundingClientRect()
    change({ left: clientPos.x - rect.left })
  }

  var pos = getPosition()

  return (
    <div className={"slide-container"} ref={container} onClick={handleClick}>
      <div className="range" />
      <div
        className="handle"
        ref={handle}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        style={pos}
        onClick={e => {
          e.stopPropagation()
          e.nativeEvent.stopImmediatePropagation()
        }}
      >
        <span>{displayValue}</span>
      </div>
    </div>
  )
}

export default RangeSlider
