import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { orderBy } from "lodash"

import classNames from "classnames"
import Swiper from "react-id-swiper"

import MatchedTicket from "components/MatchedTicket"

const OpenedTickets = observer(({ history }) => {
  const { matchedTicketStore } = useStore()

  const { openedTickets, createMatchedTicketResponse } = matchedTicketStore

  const deadlineOrder = () => ticket => ticket.ticket.deadline
  const acceptedConnectionCount = () => ticket =>
    ticket.ticket.connections.filter(({ status }) => status === "accepted")
      .length

  const [orderFunction, setOrderFunction] = useState(deadlineOrder)
  const [order, setOrder] = useState("deadline")

  const [orderedTickets, setOrderedTickets] = useState(openedTickets)
  const [pausedTickets, setPausedTickets] = useState(openedTickets)
  const [deletedTickets, setDeletedTickets] = useState(openedTickets)

  const [stackPausedTickets, setStackPausedTickets] = useState(true)
  const [stackDeletedTickets, setStackDeletedTickets] = useState(true)

  useEffect(() => {
    const activeTickets = openedTickets.filter(
      ({ ticket }) =>
        ticket.status !== "done" &&
        ticket.status !== "deleted" &&
        ticket.status !== "paused"
    )

    setOrderedTickets(orderBy(activeTickets, orderFunction, "asc"))

    setPausedTickets(
      orderBy(
        openedTickets.filter(ticket => ticket.ticket.status === "paused"),
        deadlineOrder,
        "asc"
      )
    )
    setDeletedTickets(
      orderBy(
        openedTickets.filter(ticket => ticket.ticket.status === "deleted"),
        deadlineOrder,
        "asc"
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedTickets, orderFunction])

  const handleSwipe = async ticketID => {
    await createMatchedTicketResponse(ticketID, "deleted", 0, [])
  }

  return (
    <div className="opened-tickets-assigned">
      <div className="sort">
        <div
          className={classNames({
            "deadline-people": true,
            [`sort-by-${order}`]: true,
          })}
        >
          <div
            className="deadline-text"
            onClick={() => {
              setOrderFunction(deadlineOrder)
              setOrder("deadline")
            }}
          >
            deadline
          </div>
          <div className="toggle-btn-container">
            <div className="toggle-btn" />
          </div>
          <div
            className="people-text"
            onClick={() => {
              setOrderFunction(acceptedConnectionCount)
              setOrder("people")
            }}
          >
            people
          </div>
        </div>
      </div>

      <div className="my-tickets-list">
        {orderedTickets.map(ticketMatch => (
          <span className="activator" key={ticketMatch.ticket.id}>
            <MatchedTicket ticketMatch={ticketMatch} />
          </span>
        ))}

        <div
          className={classNames({
            "paused-tickets-container": true,
            stacked: stackPausedTickets,
          })}
        >
          {stackPausedTickets && pausedTickets.length ? (
            <span
              className="activator"
              onClick={() => setStackPausedTickets(false)}
            >
              <MatchedTicket ticketMatch={pausedTickets[0]} />
            </span>
          ) : (
            pausedTickets.map(ticketMatch => (
              <span className="activator" key={ticketMatch.ticket.id}>
                <MatchedTicket ticketMatch={ticketMatch} />
              </span>
            ))
          )}
        </div>

        <div
          className={classNames({
            "deleted-tickets-container": true,
            stacked: stackDeletedTickets && deletedTickets.length > 0,
          })}
          onClick={() => setStackDeletedTickets(false)}
        >
          {stackDeletedTickets && deletedTickets.length ? (
            <MatchedTicket ticketMatch={deletedTickets[0]} />
          ) : (
            deletedTickets.map(ticketMatch => (
              <Swiper
                key={ticketMatch.ticket.id}
                getSwiper={swiper => {
                  if (swiper !== null) {
                    swiper.on("transitionEnd", () =>
                      handleSwipe(swiper, ticketMatch.ticket.id)
                    )
                  }
                }}
              >
                <div>
                  <MatchedTicket ticketMatch={ticketMatch} />
                </div>
                <div />
              </Swiper>
            ))
          )}
        </div>
        <div className="placeholder" />
      </div>
    </div>
  )
})

export default OpenedTickets
