import React, { useState, useEffect, useRef } from "react"

import { debounce } from "lodash"

import { observer } from "mobx-react-lite"
import { searchJobTitles } from "data/api"
import { useStore } from "data/store/RootStore"

import classNames from "classnames"

const AddJobTitle = observer(({ history }) => {
  // const screenName = "AddJobTitle"

  const { userStore } = useStore()
  const { circleProfiles, onboardCircleID, createCircleJobTitle } = userStore

  const [searchString, setSearchString] = useState("")
  const [matchingTitles, setMatchingTitles] = useState([])
  const [selectedTitle, setSelectedTitle] = useState(null)

  const [circle, setCircle] = useState({ name: "", logo: "" })

  const customJobTitle = useRef(null)

  useEffect(() => {
    onboardCircleID && setCircle(circleProfiles[onboardCircleID].circle)
  }, [circleProfiles, onboardCircleID])

  // DEBUG
  // useEffect(() => {
  //   console.log(circleProfiles)
  //   circleProfiles[1] && setCircle(circleProfiles[1].circle)
  // }, [circleProfiles])

  const handleSubmitForm = circleID => async e => {
    await createCircleJobTitle(selectedTitle.name, selectedTitle.id, circleID)
    history.push({
      pathname: "/onboard/final",
      state: {
        navDirection: "forward",
        animAxis: "horizontal",
        animContainer: "screen",
      },
    })
  }

  const handleJobTitleSearchChange = debounce(async searchString => {
    setSearchString(searchString)
    setSelectedTitle(null)
    if (searchString.length >= 3) {
      customJobTitle.current.classList.remove("tag-active")
      const searchResponse = await searchJobTitles(searchString)
      setMatchingTitles(searchResponse.data)
    } else {
      setMatchingTitles([])
    }
  }, 300)

  const selectTitle = (id, name, e) => {
    // Clear any other active/selected tag/job-title
    customJobTitle.current.classList.remove("tag-active")
    setMatchingTitles(
      matchingTitles.map(title => ({
        ...title,
        selected: title.id === id ? true : false,
      }))
    )
    // Set active/selected tag/job-title
    if (id) {
      setSelectedTitle(matchingTitles.filter(title => title.id === id)[0])
    } else {
      setSelectedTitle({ name })
      customJobTitle.current.classList.add("tag-active")
    }
  }

  const removePlaceholder = e => (e.target.placeholder = "")

  return (
    <div className="add-job-title container-onboard">
      <div className="header">
        <div className="steps-container">
          <div className="step-marker" />
          <div className="step-marker" />
          <div className="step-marker" />
          <div className="step-marker active" />
        </div>
        <div className="heading heading-four">
          What is your job title at the current organization?
        </div>
      </div>
      <div className="body scrollable">
        <div className="circle-section">
          <div className="logo-container single">
            <img src={circle.logo} alt="" />
          </div>
          <div className="paragraph paragraph-two circle-name">
            Your job title at {circle.name}.
          </div>
        </div>
        <div className="section-search-input">
          <input
            className="input-field"
            type="text"
            placeholder="My Job Title"
            onChange={e => handleJobTitleSearchChange(e.target.value)}
            onFocus={removePlaceholder}
            required
          />
        </div>
        <div className="section-search-results">
          <div className="uppercase-label-bold field-label">
            search results:
          </div>
          <div className="tags-container">
            {matchingTitles.map((title, i) => (
              <span
                key={i}
                className={`tag-regular ${title.selected && "tag-active"}`}
                onClick={e => selectTitle(title.id, title.name, e)}
              >
                {title.name}
              </span>
            ))}
          </div>
        </div>
        {searchString.length >= 3 && (
          <div className="section-search-selected">
            <div className="uppercase-label-bold field-label">
              The search did not match my title. <br /> Add my title anyway.
            </div>
            <div className="tags-container">
              <span
                className="tag-regular"
                onClick={e => selectTitle(null, searchString, e)}
                ref={customJobTitle}
              >
                {searchString}
              </span>
            </div>
          </div>
        )}
        <div
          className={classNames({
            "cta-primary": true,
            disabled: !selectedTitle,
          })}
          onClick={handleSubmitForm(onboardCircleID)}
        >
          Next
        </div>
      </div>
    </div>
  )
})

export default AddJobTitle
