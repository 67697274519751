import React, { useEffect, useState, useRef } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import { Route, Switch } from "react-router-dom"
import { TransitionGroup, Transition } from "react-transition-group"

import classNames from "classnames"

import BodyContainer from "components/BodyContainer"

import Skills from "./Skills"
import JobTitles from "./JobTitles"
import Connections from "./Connections"

import NavLink from "components/NavLink"
import BadgesGridHeader from "components/BadgesGridHeader"

import avatarPlaceholderImage from "static/images/avatar-placeholder.png"

const Dashboard = observer(props => {
  // const screenName = "Dashboard"

  const {
    routerStore,
    userStore,
    ownTicketStore,
    matchedTicketStore,
  } = useStore()

  // NOTE: router.location is used for transition group's switch prop
  const { location, currentPath } = routerStore

  const { firstName, lastName, avatarPreviewUrl, fetchUserSkills } = userStore
  const { fetchOwnTickets } = ownTicketStore
  const { fetchMatchedTickets } = matchedTicketStore

  const [activeTab, setActiveTab] = useState("dashboard")

  const [headerOpened, setHeaderOpened] = useState(true)
  const headerRef = useRef(null)

  useEffect(() => {
    const fetch = () => {
      fetchUserSkills()
      fetchOwnTickets()
      fetchMatchedTickets()
    }
    fetch()
  }, [fetchMatchedTickets, fetchOwnTickets, fetchUserSkills])

  useEffect(() => {
    if (typeof currentPath === "string") {
      const tab = currentPath.split(`/`).pop()
      setActiveTab(tab)
    }
  }, [currentPath])

  const toggleHeader = e => {
    e.preventDefault()
    const elHeight = e.currentTarget.scrollHeight
    const minHeight = window.innerHeight - 80
    const pixlesFromTop = e.currentTarget.scrollTop
    if (elHeight > minHeight) {
      pixlesFromTop === 0 ? setHeaderOpened(true) : setHeaderOpened(false)
    }
  }

  return (
    <div className="dashboard container-onboard">
      <div
        className={classNames({
          header: true,
          opened: headerOpened,
          closed: !headerOpened,
        })}
        ref={headerRef}
      >
        <div className="top">
          <NavLink
            pathname={"/profile"}
            navDirection={"forward"}
            animAxis={"vertical"}
          >
            <div className="user-info">
              <div className="avatar-wrapper">
                <div className="avatar-image-container">
                  <img
                    className="avatar-image"
                    src={
                      avatarPreviewUrl
                        ? avatarPreviewUrl
                        : avatarPlaceholderImage
                    }
                    alt=""
                  />
                </div>
              </div>

              <div className="name">
                <div className="first-name">{firstName}</div>
                <div className="last-name">{lastName}</div>
              </div>
            </div>
          </NavLink>

          <div className="user-badges">
            <BadgesGridHeader />
          </div>
        </div>
        <div className="bottom">
          <div className="navigation">
            <NavLink
              className={classNames({
                "link-skills": true,
                tab: true,
                active: activeTab === "dashboard",
              })}
              pathname={"/dashboard"}
              navDirection={"forward"}
              animAxis={"horizontal"}
              animContainer={"body"}
            >
              My Skills
            </NavLink>
            <NavLink
              className={classNames({
                "link-job-titles": true,
                tab: true,
                active: activeTab === "job_titles",
              })}
              pathname={"/dashboard/job_titles"}
              navDirection={"forward"}
              animAxis={"horizontal"}
              animContainer={"body"}
            >
              Job Titles
            </NavLink>
            <NavLink
              className={classNames({
                "link-connections": true,
                tab: true,
                active: activeTab === "connections",
              })}
              pathname={"/dashboard/connections"}
              navDirection={"forward"}
              animAxis={"horizontal"}
              animContainer={"body"}
            >
              Connections
            </NavLink>
          </div>
        </div>
      </div>

      <TransitionGroup
        className={classNames({
          "transition-group": true,
          opened: headerOpened,
          closed: !headerOpened,
        })}
      >
        <Transition
          key={location.key}
          appear={true}
          timeout={{
            appear: 0,
            enter: 0,
            exit: 600,
          }}
        >
          {state => (
            <Switch location={location}>
              <Route
                path={"/dashboard/connections"}
                render={props => (
                  <BodyContainer transitionState={state}>
                    <Connections {...props} toggleHeader={toggleHeader} />
                  </BodyContainer>
                )}
              />
              <Route
                path={"/dashboard/job_titles"}
                render={props => (
                  <BodyContainer transitionState={state}>
                    <JobTitles {...props} toggleHeader={toggleHeader} />
                  </BodyContainer>
                )}
              />
              <Route
                path={"/dashboard"}
                render={props => (
                  <BodyContainer transitionState={state}>
                    <Skills {...props} toggleHeader={toggleHeader} />
                  </BodyContainer>
                )}
              />
            </Switch>
          )}
        </Transition>
      </TransitionGroup>
    </div>
  )
})

export default React.memo(Dashboard)
