import React, { useState, useEffect } from "react"

import { debounce } from "lodash"

import { observer } from "mobx-react-lite"
import { searchJobTitles } from "data/api"
import { useStore } from "data/store/RootStore"

import { format } from "date-fns"

import classNames from "classnames"

import { ReactComponent as FinishIcon } from "static/svgs/icon-finish-double.svg"
import { ReactComponent as RecycleIcon } from "static/svgs/icon-recycle.svg"

import NavLink from "components/NavLink"

const AddJobTitle = observer(({ history }) => {
  // const screenName = "AddJobTitle"

  const { userStore } = useStore()
  const {
    circleProfiles,
    jobs,
    createCircleJobTitle,
    updateCircleJobTitle,
  } = userStore

  // Search related state
  const [searchString, setSearchString] = useState("")
  const [matchingTitles, setMatchingTitles] = useState([])
  const [selectedTitle, setSelectedTitle] = useState({})

  // Add job title UI flow state
  const [selectedCircle, setSelectedCircle] = useState(null)

  // Is member of exactly one circle?
  // Set that circle as default for adding new job titles to it.
  useEffect(() => {
    if (Object.keys(circleProfiles).length === 1) {
      const defaultCircleKeyID = Object.keys(circleProfiles)[0]
      setSelectedCircle(defaultCircleKeyID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circleProfiles])

  const handleJobTitleSearchChange = debounce(async searchString => {
    setSearchString(searchString)
    if (searchString.length > 0) {
      const searchResponse = await searchJobTitles(searchString)
      setMatchingTitles(searchResponse.data)
    } else {
      setMatchingTitles([])
    }
  }, 300)

  const addNewJobTitle = async circleID => {
    await createCircleJobTitle(
      selectedTitle.name,
      selectedTitle.id,
      selectedCircle
    )
    setMatchingTitles([])
    history.push({
      pathname: "/dashboard/job_titles",
      state: {
        navDirection: "back",
        animAxis: "vertical",
        animContainer: "screen",
      },
    })
  }

  const selectTitle = async (id, name, e) => {
    setMatchingTitles(
      matchingTitles.map(title => ({
        ...title,
        selected: title.id === id ? true : false,
      }))
    )
    if (id) {
      setSelectedTitle(matchingTitles.filter(title => title.id === id)[0])
    } else {
      setSelectedTitle({ name })
    }
  }

  const toggleIsFinished = async job => {
    await updateCircleJobTitle(
      { ...job, is_finished: !job.is_finished },
      job.circle.id
    )
  }

  const dateToYear = date => format(date, "YYYY")

  return (
    <div className="add-job-titles container-onboard">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            pathname={"/dashboard/edit_job_titles"}
            navDirection={"back"}
            animAxis={"vertical"}
          >
            back
          </NavLink>
        </div>
        <div className="heading heading-four">Add a new Job title</div>
      </div>
      <div className="body scrollable">
        <div className="uppercase-label-bold">select a company</div>
        <div className="circle-tabs">
          {Object.values(circleProfiles).map(profile => (
            <div
              className={classNames({
                tab: true,
                "input-field-connection": true,
                active: parseInt(selectedCircle) === profile.circle.id,
              })}
              key={profile.circle.id}
              onClick={() => setSelectedCircle(profile.circle.id)}
            >
              <div className="name">{profile.circle.name}</div>
              <div className="logo">
                <img src={profile.circle.logo} alt="" />
              </div>
            </div>
          ))}
        </div>
        {selectedCircle && (
          <>
            <div className="uppercase-label-bold">my new job title</div>
            <div className="section-search-input">
              <input
                className="input-field"
                type="text"
                placeholder="Job title"
                onChange={e => handleJobTitleSearchChange(e.target.value)}
                required
              />
            </div>
          </>
        )}
        {selectedCircle && (
          <div className="section-search-results">
            <div className="uppercase-label-bold field-label">
              search results:
            </div>
            <div className="tags-container">
              {matchingTitles.map((title, i) => (
                <span
                  key={title.id}
                  className={title.selected ? "tag-active" : "tag-regular"}
                  onClick={e => selectTitle(title.id, title.name, e)}
                >
                  {title.name}
                </span>
              ))}
            </div>
          </div>
        )}

        {searchString.length > 0 && (
          <div className="section-search-selected">
            <div className="uppercase-label-bold field-label">
              The search did not match my title. <br /> Add my title anyway.
            </div>
            <div className="tags-container">
              <span
                className={`tag-regular ${selectedTitle.name === searchString &&
                  "tag-active"}`}
                onClick={e => selectTitle(null, searchString, e)}
              >
                {searchString}
              </span>
            </div>
          </div>
        )}

        {selectedTitle.name && (
          <div className="cta-secondary-dark" onClick={addNewJobTitle}>
            Add
          </div>
        )}

        <div className="jobs">
          <div className="uppercase-label-bold">current job titles</div>
          {jobs.map(job => (
            <div
              className="job"
              key={job.id}
              onClick={() => {
                toggleIsFinished(job)
              }}
            >
              <div className="finish-toggle">
                {job.is_finished ? <RecycleIcon /> : <FinishIcon />}
              </div>
              <div className="logo-container-small">
                <img src={job.circle.logo} alt="" className="logo" />
              </div>
              <div
                className={classNames({
                  "tag-job-title": true,
                  finished: job.is_finished,
                })}
              >
                <div className="title">{job.name}</div>
                <div className="year">{dateToYear(job.date_created)}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
})

export default AddJobTitle
