import NeedHelp from "./NeedHelp"
import Connect from "./Connect"
import Evaluate from "./Evaluate"
import EvaluateUser from "./EvaluateUser"
import AddSkills from "./AddSkills"
import ThankYou from "./ThankYou"

const needHelpRoutes = [
  { Component: ThankYou, path: "/need_help/evaluate/thank_you" },
  {
    Component: EvaluateUser,
    path: "/need_help/evaluate/:ticket_id/connections",
  },
  { Component: Evaluate, path: "/need_help/evaluate/:ticket_id" },
  { Component: Connect, path: "/need_help/connect/:ticket_id" },
  { Component: AddSkills, path: "/need_help/add_skills" },
  { Component: NeedHelp, path: "/need_help" },
]

export default needHelpRoutes
