import { observable, decorate, action, flow, computed } from "mobx"
import * as api from "data/api"

// import { orderBy } from "lodash"

export default class MatchedTicketStore {
  /**
   * Observables 👁
   */
  inProgress = false
  errors = []
  matchedTickets = []
  declineReasons = []

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  init = async () => {
    const token = localStorage.getItem("jwt_access")
    if (token) {
      try {
        this.fetchMatchedTickets()
        const declineReasonsResponse = await api.fetchDeclineReasons()
        this.declineReasons = declineReasonsResponse.data
      } catch (err) {
        this.errors = err
      }
    }
  }

  /**
   * Actions 🚀 NOTE: @action.bound
   */
  fetchMatchedTickets = flow(function*() {
    try {
      this.inProgress = true
      const fetchTicketsResponse = yield api.fetchMatchedTickets()
      this.matchedTickets = fetchTicketsResponse.data
    } catch (err) {
      this.errors = err
    } finally {
      this.inProgress = false
    }
  })

  createMatchedTicketResponse = flow(function*(
    ticketID,
    status,
    declineReasonID,
    communicationChannel
  ) {
    try {
      this.inProgress = true
      yield api.postMatchedTicketResponse(
        ticketID,
        status,
        declineReasonID,
        communicationChannel
      )
      yield this.fetchMatchedTickets()
    } catch (err) {
      this.errors = err
    } finally {
      this.inProgress = false
    }
  })

  evaluateAssignedTicket = flow(function*(ticketID, time_spend, difficulty) {
    try {
      this.inProgress = true
      yield api.postAssignedTicketEvaluation(ticketID, time_spend, difficulty)
      yield this.fetchMatchedTickets()
    } catch (err) {
      this.errors = err
    } finally {
      this.inProgress = false
    }
  })

  fetchDeclineReasons = flow(function*() {
    try {
      this.inProgress = true
      const declineReasonsResponse = yield api.fetchDeclineReasons()
      this.declineReasons = declineReasonsResponse.data
    } catch (err) {
      this.errors = err
    } finally {
      this.inProgress = false
    }
  })

  reset = () => {
    this.inProgress = false
    this.errors = []
    this.matchedTickets = []
  }

  /**
   * Computed
   */
  get openedTickets() {
    return this.matchedTickets
      .filter(({ is_evaluated }) => !is_evaluated)
      .filter(({ status }) => status === "accepted" || status === "assigned")
    // .filter(({ ticket }) => ticket.status !== "paused")
  }

  get openedMatchedTicketsCount() {
    return this.openedTickets
      .filter(({ status }) => status === "assigned")
      .filter(({ ticket }) => ticket.status !== "paused")
      .filter(({ ticket }) => ticket.status !== "done")
      .filter(({ ticket }) => ticket.status !== "deleted").length
  }

  get ticketsPendingEvaluation() {
    return this.matchedTickets.filter(
      ({ is_evaluated, status }) => is_evaluated && status === "accepted"
    )
  }

  get solvedTickets() {
    return this.matchedTickets.filter(({ status }) => status === "solved")
  }
}

decorate(MatchedTicketStore, {
  inProgress: observable,
  errors: observable,
  matchedTickets: observable,
  declineReasons: observable,

  fetchMatchedTickets: action.bound,
  createMatchedTicketResponse: action.bound,
  evaluateAssignedTicket: action.bound,
  fetchDeclineReasons: action.bound,

  reset: action.bound,

  openedTickets: computed,
  openedMatchedTicketsCount: computed,
  ticketsPendingEvaluation: computed,
  solvedTickets: computed,
})
