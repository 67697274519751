import { observable, decorate, action, flow } from "mobx"
import * as api from "data/api"

import localStorage from "mobx-localstorage"
import jwtDecode from "jwt-decode"

import { debounce } from "lodash"

export default class AuthStore {
  /**
   * Observables 👁
   */
  inProgress = false
  errors = []
  loggedIn = false
  phoneNumber = null
  circleInviteToken = null
  authCode = null // users input, might not be necessary
  tmpAuthCode = null // NOTE: just for mockup, we get this from sms in production

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  init = async () => {
    this.userStore = this.rootStore.userStore
    this.routerStore = this.rootStore.routerStore

    const localStorageInviteToken = localStorage.getItem("circle_invite_token")
    if (localStorageInviteToken) {
      this.setCircleInviteToken(localStorageInviteToken)
    }
  }

  isTokenExpired = () => {
    const accessJWT = localStorage.getItem("jwt_access")
    const decodedAccessJWT = accessJWT && jwtDecode(accessJWT)
    const currentTime = new Date().getTime() / 1000
    return decodedAccessJWT && currentTime > decodedAccessJWT.exp
  }

  /**
   * Actions 🚀
   */
  setPhoneNumber = num => (this.phoneNumber = num)

  setAuthCode = code => (this.authCode = code)

  setCircleInviteToken = token => (this.circleInviteToken = token)

  setInProgress = bool => (this.inProgress = bool)

  fetchAuthCode = debounce(
    flow(function*() {
      try {
        this.inProgress = true
        const authCodeResponse = yield api.fetchAuthCode(
          this.phoneNumber,
          this.circleInviteToken
        )
        this.tmpAuthCode = authCodeResponse.data.code
        return authCodeResponse.data
      } catch (err) {
        console.log(err)
      } finally {
        this.inProgress = false
      }
    }),
    3000,
    {
      leading: true,
    }
  )

  login = flow(function*() {
    try {
      this.inProgress = true
      const loginResponse = yield api.login(
        this.phoneNumber,
        this.authCode,
        this.circleInviteToken
      )
      const { user, invite_circle_id, jwt_token } = loginResponse.data

      // Set persistent app auth state
      localStorage.setItem("jwt_access", jwt_token.access)
      localStorage.setItem("jwt_refresh", jwt_token.refresh)
      localStorage.removeItem("circle_invite_token")

      // Update user
      this.userStore.setUser(user)
      invite_circle_id && this.userStore.setOnboardCircleID(invite_circle_id)
      return loginResponse.data
    } catch (err) {
      this.errors = err.response.data.errors
      return err.response.data
    } finally {
      this.inProgress = false
    }
  })

  refreshExpiredJwt = flow(function*() {
    try {
      this.inProgress = true
      const refreshJWT = localStorage.getItem("jwt_refresh")
      // const oldJWT = localStorage.getItem("jwt_access")

      const tokenRefreshResponse = yield api.refreshExpiredJwt(refreshJWT)
      const { access } = tokenRefreshResponse.data

      // and set the new access token
      localStorage.setItem("jwt_access", access)

      yield this.userStore.fetchUser()
    } catch (err) {
      console.log(err)
    } finally {
      this.inProgress = false
    }
  })

  reset = () => {
    this.inProgress = false
    this.errors = []
    this.loggedIn = false
    this.phoneNumber = null
    this.circleInviteToken = null
    this.authCode = null // users input, might not be necessary
    this.tmpAuthCode = null // NOTE: just for mockup, we get this from sms in production
  }

  logout() {
    this.rootStore.resetStores()
    localStorage.clear()
  }
}

decorate(AuthStore, {
  phoneNumber: observable,
  authCode: observable,
  tmpAuthCode: observable,

  inProgress: observable,
  errors: observable,

  fetchAuthCode: action.bound,
  login: action.bound,
  refreshExpiredJwt: action.bound,
  isTokenExpired: action.bound,

  setPhoneNumber: action.bound,
  setAuthCode: action.bound,
  setInProgress: action.bound,
  setCircleInviteToken: action.bound,
  reset: action.bound,
  logout: action.bound,
})
