import React, { useEffect, useState } from "react"

import { observer } from "mobx-react-lite"

import classNames from "classnames"

import { ReactComponent as StarIcon } from "static/svgs/icon-star.svg"
import { ReactComponent as HeartIcon } from "static/svgs/icon-heart.svg"
import { ReactComponent as CrossIcon } from "static/svgs/icon-cross.svg"
import { ReactComponent as CheckIcon } from "static/svgs/icon-check.svg"

const UserRating = observer(({ connection = {}, updateSkillScore, updateHelpRating, helpRating }) => {
  const [skills, setSkills] = useState([])

  useEffect(() => {
    connection.skills && setSkills(connection.skills)
  }, [connection])

  return (
    <div className="skill-rating-list">
      <div
        className={classNames({skill: true})}
      >
        <div className="skill-title">How useful was the help you received?</div>
        <div className="rating" style={{borderBottom: "2px solid #e1e2e6"}}>
          {Array.apply(null, Array(5)).map((nada, i) => {
            return (
              <HeartIcon
                key={i}
                className={classNames({
                  heart: true,
                  fill: helpRating >= i + 1,
                  fade: helpRating > 0 && helpRating < i + 1,
                })}
                onClick={() => {
                  updateHelpRating(i + 1)
                }}
              />
            )
          })}
        </div>
      </div>      
      {Object.values(skills).map(skill => {
        return (
          <div
            key={skill.id}
            className={classNames({
              skill: true,
              disabled: skill.score === "disable",
            })}
          >
            <div className="skill-title">{skill.name}</div>
            <div className="rating">
              {Array.apply(null, Array(5)).map((nada, i) => {
                return (
                  <StarIcon
                    key={i}
                    className={classNames({
                      star: true,
                      fill: skill.score >= i + 1,
                      fade: skill.score > 0 && skill.score < i + 1,
                    })}
                    onClick={() => updateSkillScore(skill.id, i + 1)}
                  />
                )
              })}
              <div
                className="disable-skill"
                onClick={() => {
                  updateSkillScore(
                    skill.id,
                    skill.score === "disable" ? 0 : "disable"
                  )
                }}
              >
                <CrossIcon />
                <CheckIcon />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
})

export default React.memo(UserRating)
