import React, { useState } from "react"

import { observer } from "mobx-react-lite"
import { useStore } from "data/store/RootStore"

import NavLink from "components/NavLink"
import BadgesGrid from "components/BadgesGrid"
import Badge from "components/Badge"

const Badges = observer(() => {
  // const screenName = "Badges"

  const { routerStore, userStore } = useStore()
  const { prevPath } = routerStore
  const { badges } = userStore

  const [modalActive, setModalActive] = useState(false)
  const [modalBadge, setModalBadge] = useState({})

  const handleBadgePopup = e => {
    const badgeID = e.target.id
    if (badgeID) {
      setModalActive(true)
      const activeBadge = badges.filter(badge => badge.action === badgeID)[0]
      setModalBadge(activeBadge)
    }
  }

  return (
    <div className="badges container-onboard">
      <div className="header">
        <div className="close-link link-text-two">
          <NavLink
            pathname={prevPath}
            navDirection={"back"}
            animAxis={"horizontal"}
          >
            close
          </NavLink>
        </div>
        <div className="heading heading-onboard-four">My Achievements</div>
      </div>
      <div className="body">
        <div
          className="badges-container"
          onClick={e => {
            handleBadgePopup(e)
          }}
        >
          <BadgesGrid wait={600} location={"profile"} />
        </div>
      </div>

      {modalActive && (
        <div
          className="modal-container scrollable"
          onClick={() => {
            setModalActive(false)
          }}
        >
          <div className="modal-wrapper">
            {modalBadge.level === 0 ? (
              <div>
                <div className="uppercase-label-bold field-label">
                  want to get more badges? here are some tips and tricks.
                </div>
                <div className="badge-row single">
                  <Badge
                    key={modalBadge.action}
                    id={modalBadge.action}
                    // level={modalBadge.level}
                    level={4}
                    width={52}
                  />
                  <div className="title">{modalBadge.name}</div>
                </div>
                <div className="paragraph">{modalBadge.description}</div>
              </div>
            ) : (
              <div>
                <div className="uppercase-label-bold field-label">
                  my badges:
                </div>
                {badges
                  .filter(({ level }) => level > 0)
                  .map(badge => (
                    <div key={badge.action} className="badge-row">
                      <Badge id={badge.action} level={badge.level} width={52} />
                      <div className="title">{badge.name}</div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
})

export default React.memo(Badges)
