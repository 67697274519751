/**
 * This is the base config, we produce other configs by modifying
 * this one.
 */
const base = {
  api: {
    protocol: "https",
    host: null,
    port: 443,
    path: "api"
  },
}

// Local development
const local = {
  ...base,
  api: {
    ...base.api,
    protocol: "http",
    host: "localhost",
    port: "8000",
  },
}

// Development bucket
const dev = {
  ...base,
  api: {
    ...base.api,
    host: "cc.dagency.dev",
  },
}

const stg = {
  ...base,
  api: {
    ...base.api,
    host: "web.stage.cc.dagency.dev",
  },
}

const prd = {
  ...base,
  api: {
    ...base.api,
    host: "app.skila.io",
  },
}

const configs = {
  local,
  dev,
  stg,
  prd
}

// Use the REACT_APP_ENV environment variable to get which environment we're
// using. Note that this is different than NODE_ENV, because NODE_ENV can only
// be production or development.
export default configs[process.env.REACT_APP_ENV] || configs.local
